@charset "UTF-8";
/* ==========================================================================

値の定義

========================================================================== */
/* ==========================================================================

importファイル

========================================================================== */
/* ==========================================================================

Webフォントの定義

========================================================================== */
@font-face {
  font-family: "BebasNeueRegular";
  src: url("/css/font/BebasNeue Regular.woff2") format("woff2"), url("/css/font/BebasNeue Regular.woff") format("woff"), url("/css/font/BebasNeue Regular.otf") format("opentype");
  font-style: normal;
  font-weight: 400;
  font-display: swap; }

@font-face {
  font-family: "BebasNeueBold";
  src: url("/css/font/BebasNeue Bold.woff2") format("woff2"), url("/css/font/BebasNeue Bold.woff") format("woff"), url("/css/font/BebasNeue Bold.otf") format("opentype");
  font-style: normal;
  font-weight: 400;
  font-display: swap; }

@font-face {
  font-family: 'NemoyLight';
  font-style: normal;
  font-weight: 400;
  src: url("/css/font/nemoy_light-webfont.eot");
  /* IE9 Compat Modes */
  src: local("☺"), url("/css/font/nemoy_light-webfont.eot?#iefix") format("embedded-opentype"), url("/css/font/nemoy_light-webfont.woff") format("woff"), url("/css/font/nemoy_light-webfont.ttf") format("truetype"), url("/css/font/nemoy_light-webfont.svg#NemoyLight") format("svg");
  /* Legacy iOS */
  font-display: swap; }

@font-face {
  font-family: 'Caesar Dressing';
  font-style: normal;
  font-weight: 400;
  src: url("/css/font/caesar-dressing-v7-latin-regular.eot");
  /* IE9 Compat Modes */
  src: local("Caesar Dressing"), local("CaesarDressing-Regular"), url("/css/font/caesar-dressing-v7-latin-regular.eot?#iefix") format("embedded-opentype"), url("/css/font/caesar-dressing-v7-latin-regular.woff2") format("woff2"), url("/css/font/caesar-dressing-v7-latin-regular.woff") format("woff"), url("/css/font/caesar-dressing-v7-latin-regular.ttf") format("truetype"), url("/css/font/caesar-dressing-v7-latin-regular.svg#CaesarDressing") format("svg");
  /* Legacy iOS */
  font-display: swap; }

@font-face {
  font-family: 'Zilla Slab Highlight';
  font-style: normal;
  font-weight: 400;
  src: url("/css/font/zilla-slab-highlight-v6-latin-regular.eot");
  /* IE9 Compat Modes */
  src: local("Zilla Slab Highlight"), local("ZillaSlabHighlight-Regular"), url("/css/font/zilla-slab-highlight-v6-latin-regular.eot?#iefix") format("embedded-opentype"), url("/css/font/zilla-slab-highlight-v6-latin-regular.woff2") format("woff2"), url("/css/font/zilla-slab-highlight-v6-latin-regular.woff") format("woff"), url("/css/font/zilla-slab-highlight-v6-latin-regular.ttf") format("truetype"), url("/css/font/zilla-slab-highlight-v6-latin-regular.svg#ZillaSlabHighlight") format("svg");
  /* Legacy iOS */
  font-display: swap; }

@font-face {
  font-family: 'Alegreya SC';
  font-style: normal;
  font-weight: 400;
  src: url("/css/font/alegreya-sc-v10-latin-regular.eot");
  /* IE9 Compat Modes */
  src: local("Alegreya SC Regular"), local("AlegreyaSC-Regular"), url("/css/font/alegreya-sc-v10-latin-regular.eot?#iefix") format("embedded-opentype"), url("/css/font/alegreya-sc-v10-latin-regular.woff2") format("woff2"), url("/css/font/alegreya-sc-v10-latin-regular.woff") format("woff"), url("/css/font/alegreya-sc-v10-latin-regular.ttf") format("truetype"), url("/css/font/alegreya-sc-v10-latin-regular.svg#AlegreyaSC") format("svg");
  /* Legacy iOS */
  font-display: swap; }

@font-face {
  font-family: 'Josefin Slab';
  font-style: normal;
  font-weight: 400;
  src: url("/css/font/josefin-slab-v9-latin-regular.eot");
  /* IE9 Compat Modes */
  src: local("Josefin Slab Regular"), local("JosefinSlab-Regular"), url("/css/font/josefin-slab-v9-latin-regular.eot?#iefix") format("embedded-opentype"), url("/css/font/josefin-slab-v9-latin-regular.woff2") format("woff2"), url("/css/font/josefin-slab-v9-latin-regular.woff") format("woff"), url("/css/font/josefin-slab-v9-latin-regular.ttf") format("truetype"), url("/css/font/josefin-slab-v9-latin-regular.svg#JosefinSlab") format("svg");
  /* Legacy iOS */
  font-display: swap; }

@font-face {
  font-family: 'Tangerine';
  font-style: normal;
  font-weight: 400;
  src: url("/css/font/tangerine-v10-latin-regular.eot");
  /* IE9 Compat Modes */
  src: local("Tangerine Regular"), local("Tangerine-Regular"), url("/css/font/tangerine-v10-latin-regular.eot?#iefix") format("embedded-opentype"), url("/css/font/tangerine-v10-latin-regular.woff2") format("woff2"), url("/css/font/tangerine-v10-latin-regular.woff") format("woff"), url("/css/font/tangerine-v10-latin-regular.ttf") format("truetype"), url("/css/font/tangerine-v10-latin-regular.svg#Tangerine") format("svg");
  /* Legacy iOS */
  font-display: swap; }

@font-face {
  font-family: 'Bungee Shade';
  font-style: normal;
  font-weight: 400;
  src: url("/css/font/bungee-shade-v4-latin-regular.eot");
  /* IE9 Compat Modes */
  src: local("Bungee Shade"), local("BungeeShade-Regular"), url("/css/font/bungee-shade-v4-latin-regular.eot?#iefix") format("embedded-opentype"), url("/css/font/bungee-shade-v4-latin-regular.woff2") format("woff2"), url("/css/font/bungee-shade-v4-latin-regular.woff") format("woff"), url("/css/font/bungee-shade-v4-latin-regular.ttf") format("truetype"), url("/css/font/bungee-shade-v4-latin-regular.svg#BungeeShade") format("svg");
  /* Legacy iOS */
  font-display: swap; }

@font-face {
  font-family: 'Akronim';
  font-style: normal;
  font-weight: 400;
  src: url("/css/font/akronim-v8-latin-regular.eot");
  /* IE9 Compat Modes */
  src: local("Akronim Regular"), local("Akronim-Regular"), url("/css/font/akronim-v8-latin-regular.eot?#iefix") format("embedded-opentype"), url("/css/font/akronim-v8-latin-regular.woff2") format("woff2"), url("/css/font/akronim-v8-latin-regular.woff") format("woff"), url("/css/font/akronim-v8-latin-regular.ttf") format("truetype"), url("/css/font/akronim-v8-latin-regular.svg#Akronim") format("svg");
  /* Legacy iOS */
  font-display: swap; }

@font-face {
  font-family: 'Fredericka the Great';
  font-style: normal;
  font-weight: 400;
  src: url("/css/font/fredericka-the-great-v7-latin-regular.eot");
  /* IE9 Compat Modes */
  src: local("Fredericka the Great"), local("FrederickatheGreat"), url("/css/font/fredericka-the-great-v7-latin-regular.eot?#iefix") format("embedded-opentype"), url("/css/font/fredericka-the-great-v7-latin-regular.woff2") format("woff2"), url("/css/font/fredericka-the-great-v7-latin-regular.woff") format("woff"), url("/css/font/fredericka-the-great-v7-latin-regular.ttf") format("truetype"), url("/css/font/fredericka-the-great-v7-latin-regular.svg#FrederickatheGreat") format("svg");
  /* Legacy iOS */
  font-display: swap; }

@font-face {
  font-family: 'Codystar';
  font-style: normal;
  font-weight: 400;
  src: url("/css/font/codystar-v6-latin-regular.eot");
  /* IE9 Compat Modes */
  src: local("Codystar"), url("/css/font/codystar-v6-latin-regular.eot?#iefix") format("embedded-opentype"), url("/css/font/codystar-v6-latin-regular.woff2") format("woff2"), url("/css/font/codystar-v6-latin-regular.woff") format("woff"), url("/css/font/codystar-v6-latin-regular.ttf") format("truetype"), url("/css/font/codystar-v6-latin-regular.svg#Codystar") format("svg");
  /* Legacy iOS */
  font-display: swap; }

@font-face {
  font-family: 'Monoton';
  font-style: normal;
  font-weight: 400;
  src: url("/css/font/monoton-v8-latin-regular.eot");
  /* IE9 Compat Modes */
  src: local("Monoton"), local("Monoton-Regular"), url("/css/font/monoton-v8-latin-regular.eot?#iefix") format("embedded-opentype"), url("/css/font/monoton-v8-latin-regular.woff2") format("woff2"), url("/css/font/monoton-v8-latin-regular.woff") format("woff"), url("/css/font/monoton-v8-latin-regular.ttf") format("truetype"), url("/css/font/monoton-v8-latin-regular.svg#Monoton") format("svg");
  /* Legacy iOS */
  font-display: swap; }

@font-face {
  font-family: 'Gaegu';
  font-style: normal;
  font-weight: 400;
  src: url("/css/font/gaegu-v5-latin-regular.eot");
  /* IE9 Compat Modes */
  src: local("Gaegu Regular"), local("Gaegu-Regular"), url("/css/font/gaegu-v5-latin-regular.eot?#iefix") format("embedded-opentype"), url("/css/font/gaegu-v5-latin-regular.woff2") format("woff2"), url("/css/font/gaegu-v5-latin-regular.woff") format("woff"), url("/css/font/gaegu-v5-latin-regular.ttf") format("truetype"), url("/css/font/gaegu-v5-latin-regular.svg#Gaegu") format("svg");
  /* Legacy iOS */
  font-display: swap; }

/* btnBace使用例：@extend %btnBace;
-------------------------------------------------------------*/
.footer .inner .topFooter .infoBox .btnBox, .index section.worksArea .inner .ttl .btnBox, .index section.worksArea .inner .btnBox, .index section.aboutUsArea .inner .btnBox, .index section.serviceArea .inner .btnBox, .index section.memberArea .inner .btnBox, .index .recruitArea .photoBox .inner .right .btnBox, .index .recruitArea .underExBox .btnBox, .aboutUs section.googleMapArea .btnBox, .service section.serviceArea .inner .btnBox, .contact section.contactArea .inner .btnBox, .other section.mainArea .inner .btnBox {
  margin: 0 auto;
  width: 280px; }
  @media screen and (max-width: 767px) {
    .footer .inner .topFooter .infoBox .btnBox, .index section.worksArea .inner .ttl .btnBox, .index section.worksArea .inner .btnBox, .index section.aboutUsArea .inner .btnBox, .index section.serviceArea .inner .btnBox, .index section.memberArea .inner .btnBox, .index .recruitArea .photoBox .inner .right .btnBox, .index .recruitArea .underExBox .btnBox, .aboutUs section.googleMapArea .btnBox, .service section.serviceArea .inner .btnBox, .contact section.contactArea .inner .btnBox, .other section.mainArea .inner .btnBox {
      width: 100%; } }
  .footer .inner .topFooter .infoBox .btnBox a, .index section.worksArea .inner .ttl .btnBox a, .index section.worksArea .inner .btnBox a, .index section.aboutUsArea .inner .btnBox a, .index section.serviceArea .inner .btnBox a, .index section.memberArea .inner .btnBox a, .index .recruitArea .photoBox .inner .right .btnBox a, .index .recruitArea .underExBox .btnBox a, .aboutUs section.googleMapArea .btnBox a, .service section.serviceArea .inner .btnBox a, .contact section.contactArea .inner .btnBox a, .other section.mainArea .inner .btnBox a,
  .footer .inner .topFooter .infoBox .btnBox button,
  .index section.worksArea .inner .ttl .btnBox button,
  .index section.worksArea .inner .btnBox button,
  .index section.aboutUsArea .inner .btnBox button,
  .index section.serviceArea .inner .btnBox button,
  .index section.memberArea .inner .btnBox button,
  .index .recruitArea .photoBox .inner .right .btnBox button,
  .index .recruitArea .underExBox .btnBox button,
  .aboutUs section.googleMapArea .btnBox button,
  .service section.serviceArea .inner .btnBox button,
  .contact section.contactArea .inner .btnBox button,
  .other section.mainArea .inner .btnBox button,
  .footer .inner .topFooter .infoBox .btnBox input,
  .index section.worksArea .inner .ttl .btnBox input,
  .index section.worksArea .inner .btnBox input,
  .index section.aboutUsArea .inner .btnBox input,
  .index section.serviceArea .inner .btnBox input,
  .index section.memberArea .inner .btnBox input,
  .index .recruitArea .photoBox .inner .right .btnBox input,
  .index .recruitArea .underExBox .btnBox input,
  .aboutUs section.googleMapArea .btnBox input,
  .service section.serviceArea .inner .btnBox input,
  .contact section.contactArea .inner .btnBox input,
  .other section.mainArea .inner .btnBox input {
    letter-spacing: .05em;
    width: 100%;
    line-height: 57px;
    height: 57px;
    display: block;
    text-align: center;
    overflow: visible;
    position: relative;
    color: #525863;
    font-size: 1.6rem;
    font-weight: 700;
    box-sizing: border-box;
    background: #fff;
    transition: all .5s ease;
    box-shadow: 0 0 0 2px #525863 inset; }
    @media screen and (max-width: 767px) {
      .footer .inner .topFooter .infoBox .btnBox a, .index section.worksArea .inner .ttl .btnBox a, .index section.worksArea .inner .btnBox a, .index section.aboutUsArea .inner .btnBox a, .index section.serviceArea .inner .btnBox a, .index section.memberArea .inner .btnBox a, .index .recruitArea .photoBox .inner .right .btnBox a, .index .recruitArea .underExBox .btnBox a, .aboutUs section.googleMapArea .btnBox a, .service section.serviceArea .inner .btnBox a, .contact section.contactArea .inner .btnBox a, .other section.mainArea .inner .btnBox a,
      .footer .inner .topFooter .infoBox .btnBox button,
      .index section.worksArea .inner .ttl .btnBox button,
      .index section.worksArea .inner .btnBox button,
      .index section.aboutUsArea .inner .btnBox button,
      .index section.serviceArea .inner .btnBox button,
      .index section.memberArea .inner .btnBox button,
      .index .recruitArea .photoBox .inner .right .btnBox button,
      .index .recruitArea .underExBox .btnBox button,
      .aboutUs section.googleMapArea .btnBox button,
      .service section.serviceArea .inner .btnBox button,
      .contact section.contactArea .inner .btnBox button,
      .other section.mainArea .inner .btnBox button,
      .footer .inner .topFooter .infoBox .btnBox input,
      .index section.worksArea .inner .ttl .btnBox input,
      .index section.worksArea .inner .btnBox input,
      .index section.aboutUsArea .inner .btnBox input,
      .index section.serviceArea .inner .btnBox input,
      .index section.memberArea .inner .btnBox input,
      .index .recruitArea .photoBox .inner .right .btnBox input,
      .index .recruitArea .underExBox .btnBox input,
      .aboutUs section.googleMapArea .btnBox input,
      .service section.serviceArea .inner .btnBox input,
      .contact section.contactArea .inner .btnBox input,
      .other section.mainArea .inner .btnBox input {
        margin: 0 auto;
        width: 280px;
        max-width: 280px;
        box-shadow: 0 0 0 1px #525863 inset; } }
    .footer .inner .topFooter .infoBox .btnBox a.modan:hover, .index section.worksArea .inner .btnBox a.modan:hover, .index section.aboutUsArea .inner .btnBox a.modan:hover, .index section.serviceArea .inner .btnBox a.modan:hover, .index section.memberArea .inner .btnBox a.modan:hover, .index .recruitArea .photoBox .inner .right .btnBox a.modan:hover, .index .recruitArea .underExBox .btnBox a.modan:hover, .aboutUs section.googleMapArea .btnBox a.modan:hover, .service section.serviceArea .inner .btnBox a.modan:hover, .contact section.contactArea .inner .btnBox a.modan:hover, .other section.mainArea .inner .btnBox a.modan:hover,
    .footer .inner .topFooter .infoBox .btnBox button.modan:hover,
    .index section.worksArea .inner .btnBox button.modan:hover,
    .index section.aboutUsArea .inner .btnBox button.modan:hover,
    .index section.serviceArea .inner .btnBox button.modan:hover,
    .index section.memberArea .inner .btnBox button.modan:hover,
    .index .recruitArea .photoBox .inner .right .btnBox button.modan:hover,
    .index .recruitArea .underExBox .btnBox button.modan:hover,
    .aboutUs section.googleMapArea .btnBox button.modan:hover,
    .service section.serviceArea .inner .btnBox button.modan:hover,
    .contact section.contactArea .inner .btnBox button.modan:hover,
    .other section.mainArea .inner .btnBox button.modan:hover,
    .footer .inner .topFooter .infoBox .btnBox input.modan:hover,
    .index section.worksArea .inner .btnBox input.modan:hover,
    .index section.aboutUsArea .inner .btnBox input.modan:hover,
    .index section.serviceArea .inner .btnBox input.modan:hover,
    .index section.memberArea .inner .btnBox input.modan:hover,
    .index .recruitArea .photoBox .inner .right .btnBox input.modan:hover,
    .index .recruitArea .underExBox .btnBox input.modan:hover,
    .aboutUs section.googleMapArea .btnBox input.modan:hover,
    .service section.serviceArea .inner .btnBox input.modan:hover,
    .contact section.contactArea .inner .btnBox input.modan:hover,
    .other section.mainArea .inner .btnBox input.modan:hover {
      cursor: pointer;
      box-shadow: 0 0 0 2px #a5d404 inset; }
      @media screen and (max-width: 767px) {
        .footer .inner .topFooter .infoBox .btnBox a.modan:hover, .index section.worksArea .inner .btnBox a.modan:hover, .index section.aboutUsArea .inner .btnBox a.modan:hover, .index section.serviceArea .inner .btnBox a.modan:hover, .index section.memberArea .inner .btnBox a.modan:hover, .index .recruitArea .photoBox .inner .right .btnBox a.modan:hover, .index .recruitArea .underExBox .btnBox a.modan:hover, .aboutUs section.googleMapArea .btnBox a.modan:hover, .service section.serviceArea .inner .btnBox a.modan:hover, .contact section.contactArea .inner .btnBox a.modan:hover, .other section.mainArea .inner .btnBox a.modan:hover,
        .footer .inner .topFooter .infoBox .btnBox button.modan:hover,
        .index section.worksArea .inner .btnBox button.modan:hover,
        .index section.aboutUsArea .inner .btnBox button.modan:hover,
        .index section.serviceArea .inner .btnBox button.modan:hover,
        .index section.memberArea .inner .btnBox button.modan:hover,
        .index .recruitArea .photoBox .inner .right .btnBox button.modan:hover,
        .index .recruitArea .underExBox .btnBox button.modan:hover,
        .aboutUs section.googleMapArea .btnBox button.modan:hover,
        .service section.serviceArea .inner .btnBox button.modan:hover,
        .contact section.contactArea .inner .btnBox button.modan:hover,
        .other section.mainArea .inner .btnBox button.modan:hover,
        .footer .inner .topFooter .infoBox .btnBox input.modan:hover,
        .index section.worksArea .inner .btnBox input.modan:hover,
        .index section.aboutUsArea .inner .btnBox input.modan:hover,
        .index section.serviceArea .inner .btnBox input.modan:hover,
        .index section.memberArea .inner .btnBox input.modan:hover,
        .index .recruitArea .photoBox .inner .right .btnBox input.modan:hover,
        .index .recruitArea .underExBox .btnBox input.modan:hover,
        .aboutUs section.googleMapArea .btnBox input.modan:hover,
        .service section.serviceArea .inner .btnBox input.modan:hover,
        .contact section.contactArea .inner .btnBox input.modan:hover,
        .other section.mainArea .inner .btnBox input.modan:hover {
          box-shadow: 0 0 0 1px #a5d404 inset; } }
    .footer .inner .topFooter .infoBox .btnBox a:after, .index section.worksArea .inner .ttl .btnBox a:after, .index section.worksArea .inner .btnBox a:after, .index section.aboutUsArea .inner .btnBox a:after, .index section.serviceArea .inner .btnBox a:after, .index section.memberArea .inner .btnBox a:after, .index .recruitArea .photoBox .inner .right .btnBox a:after, .index .recruitArea .underExBox .btnBox a:after, .aboutUs section.googleMapArea .btnBox a:after, .service section.serviceArea .inner .btnBox a:after, .contact section.contactArea .inner .btnBox a:after, .other section.mainArea .inner .btnBox a:after,
    .footer .inner .topFooter .infoBox .btnBox button:after,
    .index section.worksArea .inner .ttl .btnBox button:after,
    .index section.worksArea .inner .btnBox button:after,
    .index section.aboutUsArea .inner .btnBox button:after,
    .index section.serviceArea .inner .btnBox button:after,
    .index section.memberArea .inner .btnBox button:after,
    .index .recruitArea .photoBox .inner .right .btnBox button:after,
    .index .recruitArea .underExBox .btnBox button:after,
    .aboutUs section.googleMapArea .btnBox button:after,
    .service section.serviceArea .inner .btnBox button:after,
    .contact section.contactArea .inner .btnBox button:after,
    .other section.mainArea .inner .btnBox button:after,
    .footer .inner .topFooter .infoBox .btnBox input:after,
    .index section.worksArea .inner .ttl .btnBox input:after,
    .index section.worksArea .inner .btnBox input:after,
    .index section.aboutUsArea .inner .btnBox input:after,
    .index section.serviceArea .inner .btnBox input:after,
    .index section.memberArea .inner .btnBox input:after,
    .index .recruitArea .photoBox .inner .right .btnBox input:after,
    .index .recruitArea .underExBox .btnBox input:after,
    .aboutUs section.googleMapArea .btnBox input:after,
    .service section.serviceArea .inner .btnBox input:after,
    .contact section.contactArea .inner .btnBox input:after,
    .other section.mainArea .inner .btnBox input:after {
      position: absolute;
      content: '';
      margin: auto 0; }
    .footer .inner .topFooter .infoBox .btnBox a.short:after, .index section.worksArea .inner .btnBox a.short:after, .index section.aboutUsArea .inner .btnBox a.short:after, .index section.serviceArea .inner .btnBox a.short:after, .index section.memberArea .inner .btnBox a.short:after, .index .recruitArea .photoBox .inner .right .btnBox a.short:after, .index .recruitArea .underExBox .btnBox a.short:after, .aboutUs section.googleMapArea .btnBox a.short:after, .service section.serviceArea .inner .btnBox a.short:after, .contact section.contactArea .inner .btnBox a.short:after, .other section.mainArea .inner .btnBox a.short:after,
    .footer .inner .topFooter .infoBox .btnBox button.short:after,
    .index section.worksArea .inner .btnBox button.short:after,
    .index section.aboutUsArea .inner .btnBox button.short:after,
    .index section.serviceArea .inner .btnBox button.short:after,
    .index section.memberArea .inner .btnBox button.short:after,
    .index .recruitArea .photoBox .inner .right .btnBox button.short:after,
    .index .recruitArea .underExBox .btnBox button.short:after,
    .aboutUs section.googleMapArea .btnBox button.short:after,
    .service section.serviceArea .inner .btnBox button.short:after,
    .contact section.contactArea .inner .btnBox button.short:after,
    .other section.mainArea .inner .btnBox button.short:after,
    .footer .inner .topFooter .infoBox .btnBox input.short:after,
    .index section.worksArea .inner .btnBox input.short:after,
    .index section.aboutUsArea .inner .btnBox input.short:after,
    .index section.serviceArea .inner .btnBox input.short:after,
    .index section.memberArea .inner .btnBox input.short:after,
    .index .recruitArea .photoBox .inner .right .btnBox input.short:after,
    .index .recruitArea .underExBox .btnBox input.short:after,
    .aboutUs section.googleMapArea .btnBox input.short:after,
    .service section.serviceArea .inner .btnBox input.short:after,
    .contact section.contactArea .inner .btnBox input.short:after,
    .other section.mainArea .inner .btnBox input.short:after {
      position: absolute;
      content: '';
      margin: auto 0;
      top: 23px;
      background: url(/images/common/icon_short_btn_arrow.png) no-repeat;
      background-size: 36px 7px;
      width: 36px;
      height: 7px;
      right: -11px;
      transition: all .5s ease; }
    .footer .inner .topFooter .infoBox .btnBox a.short:hover:after, .index section.worksArea .inner .btnBox a.short:hover:after, .index section.aboutUsArea .inner .btnBox a.short:hover:after, .index section.serviceArea .inner .btnBox a.short:hover:after, .index section.memberArea .inner .btnBox a.short:hover:after, .index .recruitArea .photoBox .inner .right .btnBox a.short:hover:after, .index .recruitArea .underExBox .btnBox a.short:hover:after, .aboutUs section.googleMapArea .btnBox a.short:hover:after, .service section.serviceArea .inner .btnBox a.short:hover:after, .contact section.contactArea .inner .btnBox a.short:hover:after, .other section.mainArea .inner .btnBox a.short:hover:after,
    .footer .inner .topFooter .infoBox .btnBox button.short:hover:after,
    .index section.worksArea .inner .btnBox button.short:hover:after,
    .index section.aboutUsArea .inner .btnBox button.short:hover:after,
    .index section.serviceArea .inner .btnBox button.short:hover:after,
    .index section.memberArea .inner .btnBox button.short:hover:after,
    .index .recruitArea .photoBox .inner .right .btnBox button.short:hover:after,
    .index .recruitArea .underExBox .btnBox button.short:hover:after,
    .aboutUs section.googleMapArea .btnBox button.short:hover:after,
    .service section.serviceArea .inner .btnBox button.short:hover:after,
    .contact section.contactArea .inner .btnBox button.short:hover:after,
    .other section.mainArea .inner .btnBox button.short:hover:after,
    .footer .inner .topFooter .infoBox .btnBox input.short:hover:after,
    .index section.worksArea .inner .btnBox input.short:hover:after,
    .index section.aboutUsArea .inner .btnBox input.short:hover:after,
    .index section.serviceArea .inner .btnBox input.short:hover:after,
    .index section.memberArea .inner .btnBox input.short:hover:after,
    .index .recruitArea .photoBox .inner .right .btnBox input.short:hover:after,
    .index .recruitArea .underExBox .btnBox input.short:hover:after,
    .aboutUs section.googleMapArea .btnBox input.short:hover:after,
    .service section.serviceArea .inner .btnBox input.short:hover:after,
    .contact section.contactArea .inner .btnBox input.short:hover:after,
    .other section.mainArea .inner .btnBox input.short:hover:after {
      right: -30px;
      transition-timing-function: cubic-bezier(0.52, 1.64, 0.37, 0.66); }
    .footer .inner .topFooter .infoBox .btnBox a.long:after, .index section.worksArea .inner .btnBox a.long:after, .index section.aboutUsArea .inner .btnBox a.long:after, .index section.serviceArea .inner .btnBox a.long:after, .index section.memberArea .inner .btnBox a.long:after, .index .recruitArea .photoBox .inner .right .btnBox a.long:after, .index .recruitArea .underExBox .btnBox a.long:after, .aboutUs section.googleMapArea .btnBox a.long:after, .service section.serviceArea .inner .btnBox a.long:after, .contact section.contactArea .inner .btnBox a.long:after, .other section.mainArea .inner .btnBox a.long:after,
    .footer .inner .topFooter .infoBox .btnBox button.long:after,
    .index section.worksArea .inner .btnBox button.long:after,
    .index section.aboutUsArea .inner .btnBox button.long:after,
    .index section.serviceArea .inner .btnBox button.long:after,
    .index section.memberArea .inner .btnBox button.long:after,
    .index .recruitArea .photoBox .inner .right .btnBox button.long:after,
    .index .recruitArea .underExBox .btnBox button.long:after,
    .aboutUs section.googleMapArea .btnBox button.long:after,
    .service section.serviceArea .inner .btnBox button.long:after,
    .contact section.contactArea .inner .btnBox button.long:after,
    .other section.mainArea .inner .btnBox button.long:after,
    .footer .inner .topFooter .infoBox .btnBox input.long:after,
    .index section.worksArea .inner .btnBox input.long:after,
    .index section.aboutUsArea .inner .btnBox input.long:after,
    .index section.serviceArea .inner .btnBox input.long:after,
    .index section.memberArea .inner .btnBox input.long:after,
    .index .recruitArea .photoBox .inner .right .btnBox input.long:after,
    .index .recruitArea .underExBox .btnBox input.long:after,
    .aboutUs section.googleMapArea .btnBox input.long:after,
    .service section.serviceArea .inner .btnBox input.long:after,
    .contact section.contactArea .inner .btnBox input.long:after,
    .other section.mainArea .inner .btnBox input.long:after {
      position: absolute;
      content: '';
      margin: auto 0;
      top: 23px;
      background: url(/images/common/icon_long_btn_arrow.png) no-repeat;
      background-size: 58px 7px;
      width: 58px;
      height: 7px;
      right: -11px;
      transition: all .5s ease; }
    .footer .inner .topFooter .infoBox .btnBox a.long:hover:after, .index section.worksArea .inner .btnBox a.long:hover:after, .index section.aboutUsArea .inner .btnBox a.long:hover:after, .index section.serviceArea .inner .btnBox a.long:hover:after, .index section.memberArea .inner .btnBox a.long:hover:after, .index .recruitArea .photoBox .inner .right .btnBox a.long:hover:after, .index .recruitArea .underExBox .btnBox a.long:hover:after, .aboutUs section.googleMapArea .btnBox a.long:hover:after, .service section.serviceArea .inner .btnBox a.long:hover:after, .contact section.contactArea .inner .btnBox a.long:hover:after, .other section.mainArea .inner .btnBox a.long:hover:after,
    .footer .inner .topFooter .infoBox .btnBox button.long:hover:after,
    .index section.worksArea .inner .btnBox button.long:hover:after,
    .index section.aboutUsArea .inner .btnBox button.long:hover:after,
    .index section.serviceArea .inner .btnBox button.long:hover:after,
    .index section.memberArea .inner .btnBox button.long:hover:after,
    .index .recruitArea .photoBox .inner .right .btnBox button.long:hover:after,
    .index .recruitArea .underExBox .btnBox button.long:hover:after,
    .aboutUs section.googleMapArea .btnBox button.long:hover:after,
    .service section.serviceArea .inner .btnBox button.long:hover:after,
    .contact section.contactArea .inner .btnBox button.long:hover:after,
    .other section.mainArea .inner .btnBox button.long:hover:after,
    .footer .inner .topFooter .infoBox .btnBox input.long:hover:after,
    .index section.worksArea .inner .btnBox input.long:hover:after,
    .index section.aboutUsArea .inner .btnBox input.long:hover:after,
    .index section.serviceArea .inner .btnBox input.long:hover:after,
    .index section.memberArea .inner .btnBox input.long:hover:after,
    .index .recruitArea .photoBox .inner .right .btnBox input.long:hover:after,
    .index .recruitArea .underExBox .btnBox input.long:hover:after,
    .aboutUs section.googleMapArea .btnBox input.long:hover:after,
    .service section.serviceArea .inner .btnBox input.long:hover:after,
    .contact section.contactArea .inner .btnBox input.long:hover:after,
    .other section.mainArea .inner .btnBox input.long:hover:after {
      right: -30px;
      transition-timing-function: cubic-bezier(0.52, 1.64, 0.37, 0.66); }
    .footer .inner .topFooter .infoBox .btnBox a.backBtn:hover, .index section.worksArea .inner .btnBox a.backBtn:hover, .index section.aboutUsArea .inner .btnBox a.backBtn:hover, .index section.serviceArea .inner .btnBox a.backBtn:hover, .index section.memberArea .inner .btnBox a.backBtn:hover, .index .recruitArea .photoBox .inner .right .btnBox a.backBtn:hover, .index .recruitArea .underExBox .btnBox a.backBtn:hover, .aboutUs section.googleMapArea .btnBox a.backBtn:hover, .service section.serviceArea .inner .btnBox a.backBtn:hover, .contact section.contactArea .inner .btnBox a.backBtn:hover, .other section.mainArea .inner .btnBox a.backBtn:hover,
    .footer .inner .topFooter .infoBox .btnBox button.backBtn:hover,
    .index section.worksArea .inner .btnBox button.backBtn:hover,
    .index section.aboutUsArea .inner .btnBox button.backBtn:hover,
    .index section.serviceArea .inner .btnBox button.backBtn:hover,
    .index section.memberArea .inner .btnBox button.backBtn:hover,
    .index .recruitArea .photoBox .inner .right .btnBox button.backBtn:hover,
    .index .recruitArea .underExBox .btnBox button.backBtn:hover,
    .aboutUs section.googleMapArea .btnBox button.backBtn:hover,
    .service section.serviceArea .inner .btnBox button.backBtn:hover,
    .contact section.contactArea .inner .btnBox button.backBtn:hover,
    .other section.mainArea .inner .btnBox button.backBtn:hover,
    .footer .inner .topFooter .infoBox .btnBox input.backBtn:hover,
    .index section.worksArea .inner .btnBox input.backBtn:hover,
    .index section.aboutUsArea .inner .btnBox input.backBtn:hover,
    .index section.serviceArea .inner .btnBox input.backBtn:hover,
    .index section.memberArea .inner .btnBox input.backBtn:hover,
    .index .recruitArea .photoBox .inner .right .btnBox input.backBtn:hover,
    .index .recruitArea .underExBox .btnBox input.backBtn:hover,
    .aboutUs section.googleMapArea .btnBox input.backBtn:hover,
    .service section.serviceArea .inner .btnBox input.backBtn:hover,
    .contact section.contactArea .inner .btnBox input.backBtn:hover,
    .other section.mainArea .inner .btnBox input.backBtn:hover {
      box-shadow: 0 0 0 2px #525863 inset !important; }
      @media screen and (max-width: 767px) {
        .footer .inner .topFooter .infoBox .btnBox a.backBtn:hover, .index section.worksArea .inner .btnBox a.backBtn:hover, .index section.aboutUsArea .inner .btnBox a.backBtn:hover, .index section.serviceArea .inner .btnBox a.backBtn:hover, .index section.memberArea .inner .btnBox a.backBtn:hover, .index .recruitArea .photoBox .inner .right .btnBox a.backBtn:hover, .index .recruitArea .underExBox .btnBox a.backBtn:hover, .aboutUs section.googleMapArea .btnBox a.backBtn:hover, .service section.serviceArea .inner .btnBox a.backBtn:hover, .contact section.contactArea .inner .btnBox a.backBtn:hover, .other section.mainArea .inner .btnBox a.backBtn:hover,
        .footer .inner .topFooter .infoBox .btnBox button.backBtn:hover,
        .index section.worksArea .inner .btnBox button.backBtn:hover,
        .index section.aboutUsArea .inner .btnBox button.backBtn:hover,
        .index section.serviceArea .inner .btnBox button.backBtn:hover,
        .index section.memberArea .inner .btnBox button.backBtn:hover,
        .index .recruitArea .photoBox .inner .right .btnBox button.backBtn:hover,
        .index .recruitArea .underExBox .btnBox button.backBtn:hover,
        .aboutUs section.googleMapArea .btnBox button.backBtn:hover,
        .service section.serviceArea .inner .btnBox button.backBtn:hover,
        .contact section.contactArea .inner .btnBox button.backBtn:hover,
        .other section.mainArea .inner .btnBox button.backBtn:hover,
        .footer .inner .topFooter .infoBox .btnBox input.backBtn:hover,
        .index section.worksArea .inner .btnBox input.backBtn:hover,
        .index section.aboutUsArea .inner .btnBox input.backBtn:hover,
        .index section.serviceArea .inner .btnBox input.backBtn:hover,
        .index section.memberArea .inner .btnBox input.backBtn:hover,
        .index .recruitArea .photoBox .inner .right .btnBox input.backBtn:hover,
        .index .recruitArea .underExBox .btnBox input.backBtn:hover,
        .aboutUs section.googleMapArea .btnBox input.backBtn:hover,
        .service section.serviceArea .inner .btnBox input.backBtn:hover,
        .contact section.contactArea .inner .btnBox input.backBtn:hover,
        .other section.mainArea .inner .btnBox input.backBtn:hover {
          box-shadow: 0 0 0 1px #525863 inset !important; } }
    .footer .inner .topFooter .infoBox .btnBox a.backBtn:before, .index section.worksArea .inner .btnBox a.backBtn:before, .index section.aboutUsArea .inner .btnBox a.backBtn:before, .index section.serviceArea .inner .btnBox a.backBtn:before, .index section.memberArea .inner .btnBox a.backBtn:before, .index .recruitArea .photoBox .inner .right .btnBox a.backBtn:before, .index .recruitArea .underExBox .btnBox a.backBtn:before, .aboutUs section.googleMapArea .btnBox a.backBtn:before, .service section.serviceArea .inner .btnBox a.backBtn:before, .contact section.contactArea .inner .btnBox a.backBtn:before, .other section.mainArea .inner .btnBox a.backBtn:before,
    .footer .inner .topFooter .infoBox .btnBox button.backBtn:before,
    .index section.worksArea .inner .btnBox button.backBtn:before,
    .index section.aboutUsArea .inner .btnBox button.backBtn:before,
    .index section.serviceArea .inner .btnBox button.backBtn:before,
    .index section.memberArea .inner .btnBox button.backBtn:before,
    .index .recruitArea .photoBox .inner .right .btnBox button.backBtn:before,
    .index .recruitArea .underExBox .btnBox button.backBtn:before,
    .aboutUs section.googleMapArea .btnBox button.backBtn:before,
    .service section.serviceArea .inner .btnBox button.backBtn:before,
    .contact section.contactArea .inner .btnBox button.backBtn:before,
    .other section.mainArea .inner .btnBox button.backBtn:before,
    .footer .inner .topFooter .infoBox .btnBox input.backBtn:before,
    .index section.worksArea .inner .btnBox input.backBtn:before,
    .index section.aboutUsArea .inner .btnBox input.backBtn:before,
    .index section.serviceArea .inner .btnBox input.backBtn:before,
    .index section.memberArea .inner .btnBox input.backBtn:before,
    .index .recruitArea .photoBox .inner .right .btnBox input.backBtn:before,
    .index .recruitArea .underExBox .btnBox input.backBtn:before,
    .aboutUs section.googleMapArea .btnBox input.backBtn:before,
    .service section.serviceArea .inner .btnBox input.backBtn:before,
    .contact section.contactArea .inner .btnBox input.backBtn:before,
    .other section.mainArea .inner .btnBox input.backBtn:before {
      background: #525863; }
    .footer .inner .topFooter .infoBox .btnBox a.backBtn:after, .index section.worksArea .inner .btnBox a.backBtn:after, .index section.aboutUsArea .inner .btnBox a.backBtn:after, .index section.serviceArea .inner .btnBox a.backBtn:after, .index section.memberArea .inner .btnBox a.backBtn:after, .index .recruitArea .photoBox .inner .right .btnBox a.backBtn:after, .index .recruitArea .underExBox .btnBox a.backBtn:after, .aboutUs section.googleMapArea .btnBox a.backBtn:after, .service section.serviceArea .inner .btnBox a.backBtn:after, .contact section.contactArea .inner .btnBox a.backBtn:after, .other section.mainArea .inner .btnBox a.backBtn:after,
    .footer .inner .topFooter .infoBox .btnBox button.backBtn:after,
    .index section.worksArea .inner .btnBox button.backBtn:after,
    .index section.aboutUsArea .inner .btnBox button.backBtn:after,
    .index section.serviceArea .inner .btnBox button.backBtn:after,
    .index section.memberArea .inner .btnBox button.backBtn:after,
    .index .recruitArea .photoBox .inner .right .btnBox button.backBtn:after,
    .index .recruitArea .underExBox .btnBox button.backBtn:after,
    .aboutUs section.googleMapArea .btnBox button.backBtn:after,
    .service section.serviceArea .inner .btnBox button.backBtn:after,
    .contact section.contactArea .inner .btnBox button.backBtn:after,
    .other section.mainArea .inner .btnBox button.backBtn:after,
    .footer .inner .topFooter .infoBox .btnBox input.backBtn:after,
    .index section.worksArea .inner .btnBox input.backBtn:after,
    .index section.aboutUsArea .inner .btnBox input.backBtn:after,
    .index section.serviceArea .inner .btnBox input.backBtn:after,
    .index section.memberArea .inner .btnBox input.backBtn:after,
    .index .recruitArea .photoBox .inner .right .btnBox input.backBtn:after,
    .index .recruitArea .underExBox .btnBox input.backBtn:after,
    .aboutUs section.googleMapArea .btnBox input.backBtn:after,
    .service section.serviceArea .inner .btnBox input.backBtn:after,
    .contact section.contactArea .inner .btnBox input.backBtn:after,
    .other section.mainArea .inner .btnBox input.backBtn:after {
      background: url(/images/common/icon_long_btn_arrow_g_l.png) no-repeat;
      background-size: 58px 7px;
      width: 58px;
      height: 7px;
      left: -11px; }
    .footer .inner .topFooter .infoBox .btnBox a.backBtn:hover:before, .index section.worksArea .inner .btnBox a.backBtn:hover:before, .index section.aboutUsArea .inner .btnBox a.backBtn:hover:before, .index section.serviceArea .inner .btnBox a.backBtn:hover:before, .index section.memberArea .inner .btnBox a.backBtn:hover:before, .index .recruitArea .photoBox .inner .right .btnBox a.backBtn:hover:before, .index .recruitArea .underExBox .btnBox a.backBtn:hover:before, .aboutUs section.googleMapArea .btnBox a.backBtn:hover:before, .service section.serviceArea .inner .btnBox a.backBtn:hover:before, .contact section.contactArea .inner .btnBox a.backBtn:hover:before, .other section.mainArea .inner .btnBox a.backBtn:hover:before,
    .footer .inner .topFooter .infoBox .btnBox button.backBtn:hover:before,
    .index section.worksArea .inner .btnBox button.backBtn:hover:before,
    .index section.aboutUsArea .inner .btnBox button.backBtn:hover:before,
    .index section.serviceArea .inner .btnBox button.backBtn:hover:before,
    .index section.memberArea .inner .btnBox button.backBtn:hover:before,
    .index .recruitArea .photoBox .inner .right .btnBox button.backBtn:hover:before,
    .index .recruitArea .underExBox .btnBox button.backBtn:hover:before,
    .aboutUs section.googleMapArea .btnBox button.backBtn:hover:before,
    .service section.serviceArea .inner .btnBox button.backBtn:hover:before,
    .contact section.contactArea .inner .btnBox button.backBtn:hover:before,
    .other section.mainArea .inner .btnBox button.backBtn:hover:before,
    .footer .inner .topFooter .infoBox .btnBox input.backBtn:hover:before,
    .index section.worksArea .inner .btnBox input.backBtn:hover:before,
    .index section.aboutUsArea .inner .btnBox input.backBtn:hover:before,
    .index section.serviceArea .inner .btnBox input.backBtn:hover:before,
    .index section.memberArea .inner .btnBox input.backBtn:hover:before,
    .index .recruitArea .photoBox .inner .right .btnBox input.backBtn:hover:before,
    .index .recruitArea .underExBox .btnBox input.backBtn:hover:before,
    .aboutUs section.googleMapArea .btnBox input.backBtn:hover:before,
    .service section.serviceArea .inner .btnBox input.backBtn:hover:before,
    .contact section.contactArea .inner .btnBox input.backBtn:hover:before,
    .other section.mainArea .inner .btnBox input.backBtn:hover:before {
      background: #525863; }
    .footer .inner .topFooter .infoBox .btnBox a.backBtn:hover:after, .index section.worksArea .inner .btnBox a.backBtn:hover:after, .index section.aboutUsArea .inner .btnBox a.backBtn:hover:after, .index section.serviceArea .inner .btnBox a.backBtn:hover:after, .index section.memberArea .inner .btnBox a.backBtn:hover:after, .index .recruitArea .photoBox .inner .right .btnBox a.backBtn:hover:after, .index .recruitArea .underExBox .btnBox a.backBtn:hover:after, .aboutUs section.googleMapArea .btnBox a.backBtn:hover:after, .service section.serviceArea .inner .btnBox a.backBtn:hover:after, .contact section.contactArea .inner .btnBox a.backBtn:hover:after, .other section.mainArea .inner .btnBox a.backBtn:hover:after,
    .footer .inner .topFooter .infoBox .btnBox button.backBtn:hover:after,
    .index section.worksArea .inner .btnBox button.backBtn:hover:after,
    .index section.aboutUsArea .inner .btnBox button.backBtn:hover:after,
    .index section.serviceArea .inner .btnBox button.backBtn:hover:after,
    .index section.memberArea .inner .btnBox button.backBtn:hover:after,
    .index .recruitArea .photoBox .inner .right .btnBox button.backBtn:hover:after,
    .index .recruitArea .underExBox .btnBox button.backBtn:hover:after,
    .aboutUs section.googleMapArea .btnBox button.backBtn:hover:after,
    .service section.serviceArea .inner .btnBox button.backBtn:hover:after,
    .contact section.contactArea .inner .btnBox button.backBtn:hover:after,
    .other section.mainArea .inner .btnBox button.backBtn:hover:after,
    .footer .inner .topFooter .infoBox .btnBox input.backBtn:hover:after,
    .index section.worksArea .inner .btnBox input.backBtn:hover:after,
    .index section.aboutUsArea .inner .btnBox input.backBtn:hover:after,
    .index section.serviceArea .inner .btnBox input.backBtn:hover:after,
    .index section.memberArea .inner .btnBox input.backBtn:hover:after,
    .index .recruitArea .photoBox .inner .right .btnBox input.backBtn:hover:after,
    .index .recruitArea .underExBox .btnBox input.backBtn:hover:after,
    .aboutUs section.googleMapArea .btnBox input.backBtn:hover:after,
    .service section.serviceArea .inner .btnBox input.backBtn:hover:after,
    .contact section.contactArea .inner .btnBox input.backBtn:hover:after,
    .other section.mainArea .inner .btnBox input.backBtn:hover:after {
      left: -30px;
      transition-timing-function: cubic-bezier(0.52, 1.64, 0.37, 0.66); }
    @media screen and (max-width: 767px) {
      .footer .inner .topFooter .infoBox .btnBox a.backBtn.spNotHover:hover:after, .index section.worksArea .inner .btnBox a.backBtn.spNotHover:hover:after, .index section.aboutUsArea .inner .btnBox a.backBtn.spNotHover:hover:after, .index section.serviceArea .inner .btnBox a.backBtn.spNotHover:hover:after, .index section.memberArea .inner .btnBox a.backBtn.spNotHover:hover:after, .index .recruitArea .photoBox .inner .right .btnBox a.backBtn.spNotHover:hover:after, .index .recruitArea .underExBox .btnBox a.backBtn.spNotHover:hover:after, .aboutUs section.googleMapArea .btnBox a.backBtn.spNotHover:hover:after, .service section.serviceArea .inner .btnBox a.backBtn.spNotHover:hover:after, .contact section.contactArea .inner .btnBox a.backBtn.spNotHover:hover:after, .other section.mainArea .inner .btnBox a.backBtn.spNotHover:hover:after,
      .footer .inner .topFooter .infoBox .btnBox button.backBtn.spNotHover:hover:after,
      .index section.worksArea .inner .btnBox button.backBtn.spNotHover:hover:after,
      .index section.aboutUsArea .inner .btnBox button.backBtn.spNotHover:hover:after,
      .index section.serviceArea .inner .btnBox button.backBtn.spNotHover:hover:after,
      .index section.memberArea .inner .btnBox button.backBtn.spNotHover:hover:after,
      .index .recruitArea .photoBox .inner .right .btnBox button.backBtn.spNotHover:hover:after,
      .index .recruitArea .underExBox .btnBox button.backBtn.spNotHover:hover:after,
      .aboutUs section.googleMapArea .btnBox button.backBtn.spNotHover:hover:after,
      .service section.serviceArea .inner .btnBox button.backBtn.spNotHover:hover:after,
      .contact section.contactArea .inner .btnBox button.backBtn.spNotHover:hover:after,
      .other section.mainArea .inner .btnBox button.backBtn.spNotHover:hover:after,
      .footer .inner .topFooter .infoBox .btnBox input.backBtn.spNotHover:hover:after,
      .index section.worksArea .inner .btnBox input.backBtn.spNotHover:hover:after,
      .index section.aboutUsArea .inner .btnBox input.backBtn.spNotHover:hover:after,
      .index section.serviceArea .inner .btnBox input.backBtn.spNotHover:hover:after,
      .index section.memberArea .inner .btnBox input.backBtn.spNotHover:hover:after,
      .index .recruitArea .photoBox .inner .right .btnBox input.backBtn.spNotHover:hover:after,
      .index .recruitArea .underExBox .btnBox input.backBtn.spNotHover:hover:after,
      .aboutUs section.googleMapArea .btnBox input.backBtn.spNotHover:hover:after,
      .service section.serviceArea .inner .btnBox input.backBtn.spNotHover:hover:after,
      .contact section.contactArea .inner .btnBox input.backBtn.spNotHover:hover:after,
      .other section.mainArea .inner .btnBox input.backBtn.spNotHover:hover:after {
        left: -11px; } }
    .footer .inner .topFooter .infoBox .btnBox a.download, .index section.worksArea .inner .btnBox a.download, .index section.aboutUsArea .inner .btnBox a.download, .index section.serviceArea .inner .btnBox a.download, .index section.memberArea .inner .btnBox a.download, .index .recruitArea .photoBox .inner .right .btnBox a.download, .index .recruitArea .underExBox .btnBox a.download, .aboutUs section.googleMapArea .btnBox a.download, .service section.serviceArea .inner .btnBox a.download, .contact section.contactArea .inner .btnBox a.download, .other section.mainArea .inner .btnBox a.download,
    .footer .inner .topFooter .infoBox .btnBox button.download,
    .index section.worksArea .inner .btnBox button.download,
    .index section.aboutUsArea .inner .btnBox button.download,
    .index section.serviceArea .inner .btnBox button.download,
    .index section.memberArea .inner .btnBox button.download,
    .index .recruitArea .photoBox .inner .right .btnBox button.download,
    .index .recruitArea .underExBox .btnBox button.download,
    .aboutUs section.googleMapArea .btnBox button.download,
    .service section.serviceArea .inner .btnBox button.download,
    .contact section.contactArea .inner .btnBox button.download,
    .other section.mainArea .inner .btnBox button.download,
    .footer .inner .topFooter .infoBox .btnBox input.download,
    .index section.worksArea .inner .btnBox input.download,
    .index section.aboutUsArea .inner .btnBox input.download,
    .index section.serviceArea .inner .btnBox input.download,
    .index section.memberArea .inner .btnBox input.download,
    .index .recruitArea .photoBox .inner .right .btnBox input.download,
    .index .recruitArea .underExBox .btnBox input.download,
    .aboutUs section.googleMapArea .btnBox input.download,
    .service section.serviceArea .inner .btnBox input.download,
    .contact section.contactArea .inner .btnBox input.download,
    .other section.mainArea .inner .btnBox input.download {
      position: relative; }
      .footer .inner .topFooter .infoBox .btnBox a.download:after, .index section.worksArea .inner .btnBox a.download:after, .index section.aboutUsArea .inner .btnBox a.download:after, .index section.serviceArea .inner .btnBox a.download:after, .index section.memberArea .inner .btnBox a.download:after, .index .recruitArea .photoBox .inner .right .btnBox a.download:after, .index .recruitArea .underExBox .btnBox a.download:after, .aboutUs section.googleMapArea .btnBox a.download:after, .service section.serviceArea .inner .btnBox a.download:after, .contact section.contactArea .inner .btnBox a.download:after, .other section.mainArea .inner .btnBox a.download:after,
      .footer .inner .topFooter .infoBox .btnBox button.download:after,
      .index section.worksArea .inner .btnBox button.download:after,
      .index section.aboutUsArea .inner .btnBox button.download:after,
      .index section.serviceArea .inner .btnBox button.download:after,
      .index section.memberArea .inner .btnBox button.download:after,
      .index .recruitArea .photoBox .inner .right .btnBox button.download:after,
      .index .recruitArea .underExBox .btnBox button.download:after,
      .aboutUs section.googleMapArea .btnBox button.download:after,
      .service section.serviceArea .inner .btnBox button.download:after,
      .contact section.contactArea .inner .btnBox button.download:after,
      .other section.mainArea .inner .btnBox button.download:after,
      .footer .inner .topFooter .infoBox .btnBox input.download:after,
      .index section.worksArea .inner .btnBox input.download:after,
      .index section.aboutUsArea .inner .btnBox input.download:after,
      .index section.serviceArea .inner .btnBox input.download:after,
      .index section.memberArea .inner .btnBox input.download:after,
      .index .recruitArea .photoBox .inner .right .btnBox input.download:after,
      .index .recruitArea .underExBox .btnBox input.download:after,
      .aboutUs section.googleMapArea .btnBox input.download:after,
      .service section.serviceArea .inner .btnBox input.download:after,
      .contact section.contactArea .inner .btnBox input.download:after,
      .other section.mainArea .inner .btnBox input.download:after {
        z-index: 2;
        content: '';
        position: absolute;
        margin: auto 0;
        left: 22px;
        top: 16px;
        width: 24px;
        height: 24px;
        background-size: 24px 24px;
        transition: all .5s ease;
        background: url(/images/common/icon_download.svg) no-repeat; }
    .footer .inner .topFooter .infoBox .btnBox a.download.modan:hover:after, .index section.worksArea .inner .btnBox a.download.modan:hover:after, .index section.aboutUsArea .inner .btnBox a.download.modan:hover:after, .index section.serviceArea .inner .btnBox a.download.modan:hover:after, .index section.memberArea .inner .btnBox a.download.modan:hover:after, .index .recruitArea .photoBox .inner .right .btnBox a.download.modan:hover:after, .index .recruitArea .underExBox .btnBox a.download.modan:hover:after, .aboutUs section.googleMapArea .btnBox a.download.modan:hover:after, .service section.serviceArea .inner .btnBox a.download.modan:hover:after, .contact section.contactArea .inner .btnBox a.download.modan:hover:after, .other section.mainArea .inner .btnBox a.download.modan:hover:after,
    .footer .inner .topFooter .infoBox .btnBox button.download.modan:hover:after,
    .index section.worksArea .inner .btnBox button.download.modan:hover:after,
    .index section.aboutUsArea .inner .btnBox button.download.modan:hover:after,
    .index section.serviceArea .inner .btnBox button.download.modan:hover:after,
    .index section.memberArea .inner .btnBox button.download.modan:hover:after,
    .index .recruitArea .photoBox .inner .right .btnBox button.download.modan:hover:after,
    .index .recruitArea .underExBox .btnBox button.download.modan:hover:after,
    .aboutUs section.googleMapArea .btnBox button.download.modan:hover:after,
    .service section.serviceArea .inner .btnBox button.download.modan:hover:after,
    .contact section.contactArea .inner .btnBox button.download.modan:hover:after,
    .other section.mainArea .inner .btnBox button.download.modan:hover:after,
    .footer .inner .topFooter .infoBox .btnBox input.download.modan:hover:after,
    .index section.worksArea .inner .btnBox input.download.modan:hover:after,
    .index section.aboutUsArea .inner .btnBox input.download.modan:hover:after,
    .index section.serviceArea .inner .btnBox input.download.modan:hover:after,
    .index section.memberArea .inner .btnBox input.download.modan:hover:after,
    .index .recruitArea .photoBox .inner .right .btnBox input.download.modan:hover:after,
    .index .recruitArea .underExBox .btnBox input.download.modan:hover:after,
    .aboutUs section.googleMapArea .btnBox input.download.modan:hover:after,
    .service section.serviceArea .inner .btnBox input.download.modan:hover:after,
    .contact section.contactArea .inner .btnBox input.download.modan:hover:after,
    .other section.mainArea .inner .btnBox input.download.modan:hover:after {
      width: 24px;
      height: 24px;
      background-size: 24px 24px;
      transition: all .5s ease;
      background: url(/images/common/icon_download_w.svg) no-repeat; }
    .footer .inner .topFooter .infoBox .btnBox a.spNotHover:before, .index section.worksArea .inner .btnBox a.spNotHover:before, .index section.aboutUsArea .inner .btnBox a.spNotHover:before, .index section.serviceArea .inner .btnBox a.spNotHover:before, .index section.memberArea .inner .btnBox a.spNotHover:before, .index .recruitArea .photoBox .inner .right .btnBox a.spNotHover:before, .index .recruitArea .underExBox .btnBox a.spNotHover:before, .aboutUs section.googleMapArea .btnBox a.spNotHover:before, .service section.serviceArea .inner .btnBox a.spNotHover:before, .contact section.contactArea .inner .btnBox a.spNotHover:before, .other section.mainArea .inner .btnBox a.spNotHover:before,
    .footer .inner .topFooter .infoBox .btnBox button.spNotHover:before,
    .index section.worksArea .inner .btnBox button.spNotHover:before,
    .index section.aboutUsArea .inner .btnBox button.spNotHover:before,
    .index section.serviceArea .inner .btnBox button.spNotHover:before,
    .index section.memberArea .inner .btnBox button.spNotHover:before,
    .index .recruitArea .photoBox .inner .right .btnBox button.spNotHover:before,
    .index .recruitArea .underExBox .btnBox button.spNotHover:before,
    .aboutUs section.googleMapArea .btnBox button.spNotHover:before,
    .service section.serviceArea .inner .btnBox button.spNotHover:before,
    .contact section.contactArea .inner .btnBox button.spNotHover:before,
    .other section.mainArea .inner .btnBox button.spNotHover:before,
    .footer .inner .topFooter .infoBox .btnBox input.spNotHover:before,
    .index section.worksArea .inner .btnBox input.spNotHover:before,
    .index section.aboutUsArea .inner .btnBox input.spNotHover:before,
    .index section.serviceArea .inner .btnBox input.spNotHover:before,
    .index section.memberArea .inner .btnBox input.spNotHover:before,
    .index .recruitArea .photoBox .inner .right .btnBox input.spNotHover:before,
    .index .recruitArea .underExBox .btnBox input.spNotHover:before,
    .aboutUs section.googleMapArea .btnBox input.spNotHover:before,
    .service section.serviceArea .inner .btnBox input.spNotHover:before,
    .contact section.contactArea .inner .btnBox input.spNotHover:before,
    .other section.mainArea .inner .btnBox input.spNotHover:before {
      display: none; }
    .footer .inner .topFooter .infoBox .btnBox a.spNotHover:hover, .index section.worksArea .inner .btnBox a.spNotHover:hover, .index section.aboutUsArea .inner .btnBox a.spNotHover:hover, .index section.serviceArea .inner .btnBox a.spNotHover:hover, .index section.memberArea .inner .btnBox a.spNotHover:hover, .index .recruitArea .photoBox .inner .right .btnBox a.spNotHover:hover, .index .recruitArea .underExBox .btnBox a.spNotHover:hover, .aboutUs section.googleMapArea .btnBox a.spNotHover:hover, .service section.serviceArea .inner .btnBox a.spNotHover:hover, .contact section.contactArea .inner .btnBox a.spNotHover:hover, .other section.mainArea .inner .btnBox a.spNotHover:hover,
    .footer .inner .topFooter .infoBox .btnBox button.spNotHover:hover,
    .index section.worksArea .inner .btnBox button.spNotHover:hover,
    .index section.aboutUsArea .inner .btnBox button.spNotHover:hover,
    .index section.serviceArea .inner .btnBox button.spNotHover:hover,
    .index section.memberArea .inner .btnBox button.spNotHover:hover,
    .index .recruitArea .photoBox .inner .right .btnBox button.spNotHover:hover,
    .index .recruitArea .underExBox .btnBox button.spNotHover:hover,
    .aboutUs section.googleMapArea .btnBox button.spNotHover:hover,
    .service section.serviceArea .inner .btnBox button.spNotHover:hover,
    .contact section.contactArea .inner .btnBox button.spNotHover:hover,
    .other section.mainArea .inner .btnBox button.spNotHover:hover,
    .footer .inner .topFooter .infoBox .btnBox input.spNotHover:hover,
    .index section.worksArea .inner .btnBox input.spNotHover:hover,
    .index section.aboutUsArea .inner .btnBox input.spNotHover:hover,
    .index section.serviceArea .inner .btnBox input.spNotHover:hover,
    .index section.memberArea .inner .btnBox input.spNotHover:hover,
    .index .recruitArea .photoBox .inner .right .btnBox input.spNotHover:hover,
    .index .recruitArea .underExBox .btnBox input.spNotHover:hover,
    .aboutUs section.googleMapArea .btnBox input.spNotHover:hover,
    .service section.serviceArea .inner .btnBox input.spNotHover:hover,
    .contact section.contactArea .inner .btnBox input.spNotHover:hover,
    .other section.mainArea .inner .btnBox input.spNotHover:hover {
      color: #525863 !important;
      box-shadow: 0 0 0 1px #525863 inset !important; }
    @media screen and (max-width: 767px) {
      .footer .inner .topFooter .infoBox .btnBox a.spNotHover:hover:after, .index section.worksArea .inner .btnBox a.spNotHover:hover:after, .index section.aboutUsArea .inner .btnBox a.spNotHover:hover:after, .index section.serviceArea .inner .btnBox a.spNotHover:hover:after, .index section.memberArea .inner .btnBox a.spNotHover:hover:after, .index .recruitArea .photoBox .inner .right .btnBox a.spNotHover:hover:after, .index .recruitArea .underExBox .btnBox a.spNotHover:hover:after, .aboutUs section.googleMapArea .btnBox a.spNotHover:hover:after, .service section.serviceArea .inner .btnBox a.spNotHover:hover:after, .contact section.contactArea .inner .btnBox a.spNotHover:hover:after, .other section.mainArea .inner .btnBox a.spNotHover:hover:after,
      .footer .inner .topFooter .infoBox .btnBox button.spNotHover:hover:after,
      .index section.worksArea .inner .btnBox button.spNotHover:hover:after,
      .index section.aboutUsArea .inner .btnBox button.spNotHover:hover:after,
      .index section.serviceArea .inner .btnBox button.spNotHover:hover:after,
      .index section.memberArea .inner .btnBox button.spNotHover:hover:after,
      .index .recruitArea .photoBox .inner .right .btnBox button.spNotHover:hover:after,
      .index .recruitArea .underExBox .btnBox button.spNotHover:hover:after,
      .aboutUs section.googleMapArea .btnBox button.spNotHover:hover:after,
      .service section.serviceArea .inner .btnBox button.spNotHover:hover:after,
      .contact section.contactArea .inner .btnBox button.spNotHover:hover:after,
      .other section.mainArea .inner .btnBox button.spNotHover:hover:after,
      .footer .inner .topFooter .infoBox .btnBox input.spNotHover:hover:after,
      .index section.worksArea .inner .btnBox input.spNotHover:hover:after,
      .index section.aboutUsArea .inner .btnBox input.spNotHover:hover:after,
      .index section.serviceArea .inner .btnBox input.spNotHover:hover:after,
      .index section.memberArea .inner .btnBox input.spNotHover:hover:after,
      .index .recruitArea .photoBox .inner .right .btnBox input.spNotHover:hover:after,
      .index .recruitArea .underExBox .btnBox input.spNotHover:hover:after,
      .aboutUs section.googleMapArea .btnBox input.spNotHover:hover:after,
      .service section.serviceArea .inner .btnBox input.spNotHover:hover:after,
      .contact section.contactArea .inner .btnBox input.spNotHover:hover:after,
      .other section.mainArea .inner .btnBox input.spNotHover:hover:after {
        right: -11px; } }

/* ホバーアニメーション
-------------------------------------------------------------*/
.btnRightToAnimation {
  transform: perspective(1px) translateZ(0);
  transition: color .5s; }
  .btnRightToAnimation:before {
    content: '';
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: #a5d404;
    transform: scaleX(0);
    transform-origin: 0 50%;
    transition: transform .5s ease; }
  .btnRightToAnimation:hover {
    color: #fff !important; }
  .btnRightToAnimation:hover:before {
    transform: scaleX(1);
    transition-timing-function: cubic-bezier(0.52, 1.64, 0.37, 0.66); }

.btnLeftToAnimation {
  transform: perspective(1px) translateZ(0);
  transition: color .5s; }
  .btnLeftToAnimation:before {
    content: '';
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: #a5d404;
    transform: scaleX(0);
    transform-origin: 100% 0;
    transition: transform .5s ease; }
  .btnLeftToAnimation:hover {
    color: #fff !important; }
  .btnLeftToAnimation:hover:before {
    transform: scaleX(1);
    transition-timing-function: cubic-bezier(0.52, 1.64, 0.37, 0.66); }

.btnOutVerticalAnimation {
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  position: relative;
  transition: color .3s; }
  .btnOutVerticalAnimation:before {
    content: '';
    position: absolute;
    z-index: -1;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: #a5d404;
    transform: scaleY(0);
    transform-origin: 50%;
    transition: transform .3s ease-out; }
  .btnOutVerticalAnimation:hover {
    color: #fff !important; }
  .btnOutVerticalAnimation:hover:before {
    transform: scaleY(1); }

/* =================================================================

リセット

================================================================= */
html,
body,
div,
span,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
abbr,
address,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
samp,
small,
strong,
sub,
sup,
var,
b,
i,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  font-size: 100%;
  font: inherit;
  background: transparent;
  vertical-align: baseline; }

html {
  text-size-adjust: 100%; }

body {
  *font: x-small;
  color: #525863;
  font-weight: 500; }
  @media screen and (max-width: 767px) {
    body {
      font-size: 100%; } }

html,
body {
  line-height: 1.6;
  -moz-osx-font-smoothing: grayscale; }

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
nav,
section {
  display: block; }

li {
  list-style: none; }

blockquote,
q {
  quotes: none; }

blockquote:before, blockquote:after {
  content: '';
  content: none; }

q:before, q:after {
  content: '';
  content: none; }

legend {
  display: table;
  max-width: 100%;
  padding: 0;
  white-space: normal; }

fieldset {
  margin: 0 2px;
  border: 1px solid #bfbfbf;
  padding: .35em .625em .75em; }

a {
  margin: 0;
  padding: 0;
  font-size: 100%;
  outline: none;
  background: transparent;
  text-decoration: none;
  vertical-align: baseline;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }
  a:link {
    color: #000;
    outline: none; }
  a:visited {
    color: #000;
    outline: none; }
  a:hover {
    text-decoration: none;
    outline: none; }
  a:focus {
    text-decoration: none;
    outline: none; }

*:focus {
  outline: none !important; }

ins {
  color: #000;
  background: #ff9;
  text-decoration: none; }

mark {
  color: #000;
  background-color: #ff9;
  font-style: italic;
  font-weight: bold; }

del {
  text-decoration: line-through; }

abbr[title],
dfn[title] {
  border-bottom: 1px dotted inherit;
  cursor: help; }

table {
  width: 100%;
  border-spacing: 0;
  border-collapse: collapse; }
  table th,
  table td {
    border-collapse: collapse;
    border-spacing: 0; }

caption,
th {
  text-align: left; }

hr {
  height: 1px;
  margin: 1em 0;
  padding: 0;
  border: 0;
  display: block;
  overflow: visible;
  border-top: 1px solid #bfbfbf; }

img {
  max-width: 100%;
  display: block;
  font-size: 0;
  line-height: 0;
  height: auto;
  vertical-align: bottom; }

input,
select {
  vertical-align: middle; }

button,
select {
  text-transform: none; }

select {
  -moz-appearance: none;
  text-indent: 0.01px;
  text-overflow: ''; }

select::-ms-expand {
  display: none; }

button {
  margin: 0;
  padding: 0;
  border: none;
  background: initial; }

input[type='button'],
input[type='submit'],
input[type='search'],
input[type='reset'],
button {
  -webkit-appearance: none; }

label {
  font-weight: normal; }

audio:not([controls]) {
  display: none;
  height: 0; }

input {
  padding: 0; }
  input::-ms-clear, input::-ms-reveal {
    visibility: hidden; }

[type='checkbox'],
[type='radio'] {
  padding: 0; }

::-webkit-inner-spin-button,
::-webkit-outer-spin-button {
  height: auto; }

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px; }

::-webkit-search-cancel-button,
::-webkit-search-decoration {
  -webkit-appearance: none; }

::-webkit-input-placeholder {
  color: #ccc;
  opacity: 1; }

:-moz-placeholder {
  color: #ccc; }

::-moz-placeholder {
  color: #ccc;
  opacity: 1; }

:-ms-input-placeholder {
  color: #ccc; }

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit; }

[aria-busy='true'] {
  cursor: progress; }

[aria-controls] {
  cursor: pointer; }

[aria-disabled] {
  cursor: default; }

a,
area,
button,
input,
label,
select,
textarea,
[tabindex] {
  margin: 0;
  padding: 0;
  -ms-touch-action: manipulation;
  touch-action: manipulation; }

input,
textarea,
select {
  font-family: inherit;
  font-size: inherit;
  font-weight: inherit;
  *font-size: 100%; }

[hidden][aria-hidden='false'] {
  clip: rect(0, 0, 0, 0);
  display: inherit;
  position: absolute; }

[hidden][aria-hidden='false']:focus {
  clip: auto; }

/* ==========================================================================

印刷時の定義

========================================================================== */
@media print {
  .noPrint {
    display: none !important; }
  body {
    width: 100%;
    font-size: 14pt;
    -webkit-print-color-adjust: exact; } }

/* =================================================================

基本設定

================================================================= */
html {
  font-size: 62.5%; }

body {
  font-size: 1.6rem;
  font-family: "Noto Sans Japanese", sans-serif; }

/* 要素の非表示
-------------------------------------------------------------*/
.continer,
.opacityHidden {
  opacity: 0; }

/* SVG画像調整
-------------------------------------------------------------*/
img[src$=".svg"],
object[src$=".svg"] {
  max-width: 100%;
  width: 100%;
  height: auto; }

/* ローディング表示
-------------------------------------------------------------*/
#loading {
  background: #fff;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10000;
  transition: ease .5s;
  display: none; }
  #loading.isDisplay {
    display: block; }
  #loading .loadTxt {
    width: 100%;
    display: block;
    text-align: center;
    position: absolute;
    top: calc(50% - 38px);
    left: 0; }
    #loading .loadTxt span {
      margin: 0 .3rem;
      position: relative;
      display: inline-block;
      font-size: 4.8rem;
      font-weight: bold;
      color: rgba(255, 255, 255, 0.1);
      font-family: "BebasNeueRegular", sans-serif; }
      #loading .loadTxt span::after {
        position: absolute;
        top: 0;
        left: 0;
        opacity: 0;
        content: attr(data-text);
        color: #a5d404;
        transform: scale(1.2);
        animation: loading 1.2s infinite; }
      #loading .loadTxt span:nth-child(2)::after {
        animation-delay: .1s; }
      #loading .loadTxt span:nth-child(3)::after {
        animation-delay: .2s; }
      #loading .loadTxt span:nth-child(4)::after {
        animation-delay: .3s; }
      #loading .loadTxt span:nth-child(5)::after {
        animation-delay: .4s; }
      #loading .loadTxt span:nth-child(6)::after {
        animation-delay: .5s; }
      #loading .loadTxt span:nth-child(7)::after {
        animation-delay: .6s; }

@keyframes loading {
  0%, 75%, 100% {
    transform: scale(1.2);
    opacity: 0; }
  25%, 50% {
    transform: scale(1);
    opacity: 1; } }

/* レスポンシブチェック
-------------------------------------------------------------*/
#responsibleCheck {
  width: 1px;
  height: 0; }
  @media screen and (min-width: 768px) and (max-width: 1200px) {
    #responsibleCheck {
      width: 1.5px; } }
  @media screen and (min-width: 426px) and (max-width: 767px) {
    #responsibleCheck {
      width: 3px; } }
  @media screen and (max-width: 767px) {
    #responsibleCheck {
      width: 2px; } }

/* PC/SPで要素を出しわけ
-------------------------------------------------------------*/
@media screen and (max-width: 767px) {
  .pcOnly {
    display: none; } }

.tabOnly {
  display: none; }
  @media screen and (min-width: 768px) and (max-width: 1200px) {
    .tabOnly {
      display: static; } }
  @media screen and (max-width: 767px) {
    .tabOnly {
      display: static; } }

.spOnly {
  display: none; }
  @media screen and (max-width: 767px) {
    .spOnly {
      display: static; } }

/* アニメーション関連
-------------------------------------------------------------*/
.animateTrigger {
  visibility: hidden; }

@keyframes show {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

.sa {
  opacity: 0;
  transition: all .5s ease; }

.sa.show {
  opacity: 1;
  transform: none; }

.saLr {
  transform: translate(-100px, 0); }

.saRr {
  transform: translate(100px, 0); }

.saUp {
  transform: translate(0, 100px); }

.saDown {
  transform: translate(0, -100px); }

.animeFadeIn {
  animation: 2s forwards animeFadeIn; }

@keyframes animeFadeIn {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

/* カーソル反転表示
-------------------------------------------------------------*/
::selection {
  background: #a5d404;
  color: #fff; }

::-moz-selection {
  background: #a5d404;
  color: #fff; }

/* ホバーで要素を拡大
-------------------------------------------------------------*/
.bigHover {
  transition: all .3s ease; }
  .bigHover:hover {
    transform: scale(1.1); }
    @media screen and (max-width: 767px) {
      .bigHover:hover {
        transform: scale(1); } }

/* フォーム関連
-------------------------------------------------------------*/
select {
  font-weight: normal; }

input,
select,
textarea {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }

/* inputスタイル
-------------------------------------------------------------*/
input[type='text'], input[type='email'], input[type='password'], input[type='tel'], input[type='search'], input[type='number'] {
  line-height: 46px;
  height: 46px;
  padding: 0 10px;
  box-sizing: border-box;
  -webkit-appearance: none;
  border: none;
  border-radius: 4px;
  background: #f0f0f0;
  transition: all .5s ease; }
  input[type='text']:-moz-placeholder, input[type='email']:-moz-placeholder, input[type='password']:-moz-placeholder, input[type='tel']:-moz-placeholder, input[type='search']:-moz-placeholder, input[type='number']:-moz-placeholder {
    color: #bfbfbf; }
  input[type='text']::-webkit-input-placeholder, input[type='email']::-webkit-input-placeholder, input[type='password']::-webkit-input-placeholder, input[type='tel']::-webkit-input-placeholder, input[type='search']::-webkit-input-placeholder, input[type='number']::-webkit-input-placeholder {
    color: #bfbfbf; }

input[type='text']:focus, input[type='email']:focus, input[type='password']:focus, input[type='tel']:focus, input[type='search']:focus, input[type='number']:focus {
  outline: 0;
  background: #4ea96d;
  color: #fff; }

input:placeholder-shown, input::-webkit-input-placeholder, input::-moz-placeholder, input:-moz-placeholder, input:-ms-input-placeholder {
  color: #fafafa;
  vertical-align: middle; }

/* textareaスタイル
-------------------------------------------------------------*/
textarea {
  width: 80%;
  min-height: 180px;
  max-height: 220px;
  display: block;
  padding: 14px 10px;
  overflow: auto;
  appearance: none;
  resize: vertical;
  word-break: keep-all;
  box-sizing: border-box;
  border: none;
  border-radius: 4px;
  background: #f0f0f0;
  transition: all .5s ease; }
  textarea:-moz-placeholder {
    color: #bfbfbf; }
  textarea::-webkit-input-placeholder {
    color: #bfbfbf; }
  textarea:focus {
    outline: 0;
    background: #6cb051;
    color: #fff; }
  @media screen and (max-width: 767px) {
    textarea {
      width: 100%;
      min-height: 150px;
      max-height: 200px; } }

/* リンクスタイル
-------------------------------------------------------------*/
a[href^="tel:"] {
  cursor: default; }

/* SNSリンクボタンエリア（基本）
-------------------------------------------------------------*/
.snsArea {
  text-align: center; }
  .snsArea .snsTtl {
    transform: rotate(90deg);
    transition: all .5s ease;
    font-size: 1.6rem;
    line-height: 1;
    letter-spacing: .1em;
    font-family: "BebasNeueRegular", sans-serif; }
  .snsArea ul li svg {
    fill: #525863; }
  .snsArea ul li.fbIcon {
    width: 21px;
    height: 21px; }
  .snsArea ul li.twIcon {
    width: 25px;
    height: 20px; }
  .snsArea ul li.plusIcon {
    width: 24px;
    height: 15px; }
  .snsArea ul li.hatenaIcon {
    width: 21px;
    height: 21px; }
  .snsArea ul li.lineIcon {
    width: 22px;
    height: 22px; }
  .snsArea ul li.instagramIcon {
    width: 21px;
    height: 21px; }

/* 下部SNSボタンエリア
-------------------------------------------------------------*/
.snsUnderArea {
  padding: 0 20px; }
  .snsUnderArea ul {
    height: auto;
    display: flex;
    justify-content: space-around; }
    .snsUnderArea ul li {
      width: 100%;
      margin-right: 3%;
      margin-bottom: 2px;
      border-radius: 4px; }
      .snsUnderArea ul li:last-of-type {
        margin-right: 0; }
      .snsUnderArea ul li.shareFacebook {
        background: #3b5998;
        box-shadow: 0 2px #2d4373; }
        .snsUnderArea ul li.shareFacebook:hover {
          background: #4c70ba; }
      .snsUnderArea ul li.shareTwitter {
        background: #55acee;
        box-shadow: 0 2px #2795e9; }
        .snsUnderArea ul li.shareTwitter:hover {
          background: #83c3f3; }
      .snsUnderArea ul li.shareGoogle {
        background: #dd4b39;
        box-shadow: 0 2px #c23321; }
        .snsUnderArea ul li.shareGoogle:hover {
          background: #e47365; }
      .snsUnderArea ul li.shareHatena {
        background: #2c6ebd;
        box-shadow: 0 2px #225694; }
        .snsUnderArea ul li.shareHatena:hover {
          background: #4888d4; }
      .snsUnderArea ul li.shareLine {
        background: #00C300;
        box-shadow: 0 2px #009d00; }
        .snsUnderArea ul li.shareLine:hover {
          background: #2bd92b; }
      .snsUnderArea ul li.shareInstagram {
        background: #f13d53;
        box-shadow: 0 2px #e6152f; }
        .snsUnderArea ul li.shareInstagram:hover {
          background: #f26f7f; }
      .snsUnderArea ul li a {
        height: 27px;
        min-height: 27px;
        color: #fff;
        width: 100%;
        display: inline-block;
        vertical-align: middle;
        font-size: 1.4rem;
        text-align: center;
        position: relative; }

/* テーブルリスト
-------------------------------------------------------------*/
table {
  width: 100%;
  margin: 0 auto 80px; }
  @media screen and (min-width: 768px) and (max-width: 1200px) {
    table {
      width: 100%; } }
  @media screen and (max-width: 767px) {
    table {
      width: 100%;
      margin: 0 auto 30px; } }
  @media screen and (max-width: 767px) {
    table tr {
      display: block;
      position: relative; } }
  table tr th {
    width: 25%;
    padding: 20px 0;
    font-size: 1.6rem;
    background: #fff;
    word-break: break-all;
    box-sizing: border-box; }
    @media screen and (max-width: 767px) {
      table tr th {
        width: 100%;
        display: block;
        border-bottom: none;
        padding-bottom: 4px; } }
  table tr td {
    padding: 20px 0;
    font-size: 1.6rem;
    vertical-align: middle;
    background: #fff;
    word-break: break-all;
    box-sizing: border-box; }
    @media screen and (max-width: 767px) {
      table tr td:first-of-type {
        padding: 0 0 7px; } }
    @media screen and (max-width: 767px) {
      table tr td {
        width: 100%;
        border: none;
        display: block;
        padding: 8px 0; } }

/* パン屑リスト
-------------------------------------------------------------*/
.breadcrumb {
  max-width: 1300px;
  margin: 0 auto;
  clear: both;
  padding: 0 20px; }
  .breadcrumb:after {
    content: "";
    display: table;
    clear: both; }
  @media screen and (max-width: 767px) {
    .breadcrumb {
      max-width: initial; } }
  @media screen and (max-width: 767px) {
    .breadcrumb {
      margin: 0 0 79px;
      max-width: initial; } }
  .breadcrumb ol {
    font-size: 1.2rem;
    word-break: break-all;
    padding: 0 0 15px;
    border-bottom: 1px solid #ddd; }
    .breadcrumb ol:after {
      content: "";
      display: table;
      clear: both; }
    @media screen and (max-width: 767px) {
      .breadcrumb ol {
        padding: 0 0 20px; } }
    .breadcrumb ol > li {
      display: inline-block;
      font-weight: 700;
      padding: 0 0 0 17px;
      margin: 0 7px 0 0;
      position: relative; }
      .breadcrumb ol > li:first-of-type {
        padding: 0; }
      .breadcrumb ol > li:last-of-type {
        margin: 0; }
      .breadcrumb ol > li + li:before {
        content: '';
        margin: 2px 0 0 0;
        position: absolute;
        left: 0;
        top: 0;
        background: url(/images/common/icon_arrow.svg) no-repeat;
        width: 6px;
        height: 14px;
        background-size: 6px 14px; }
    .breadcrumb ol a {
      font-weight: normal;
      color: #525863;
      transition: all .5s ease; }
      .breadcrumb ol a:hover {
        color: #a5d404; }
  @media screen and (max-width: 767px) {
    .breadcrumb.contactPage {
      margin: 0; } }
  .breadcrumb.contactPage ol {
    border-bottom: none; }

/* オーバーレイのスタイル
-------------------------------------------------------------*/
#modalOverlay {
  top: 0;
  left: 0;
  width: 100%;
  height: 120%;
  z-index: 1001;
  display: none;
  position: fixed;
  background-color: rgba(0, 0, 0, 0.8); }

#normalModalOverlay {
  top: 0;
  left: 0;
  width: 100%;
  height: 120%;
  z-index: 1001;
  display: none;
  position: fixed;
  background-color: rgba(0, 0, 0, 0.8); }

#clickOverlay {
  top: 0;
  left: 0;
  width: 100%;
  height: 120%;
  z-index: 100;
  display: none;
  position: fixed; }

/* グーグルマップのスタイル
-------------------------------------------------------------*/
.gmnoprint a,
.gmnoprint span {
  display: none; }

/* ==========================================================================

メインコンテンツベース

========================================================================== */
.continer {
  width: 100%;
  z-index: 100;
  background: #fff;
  position: relative;
  top: 0;
  left: 0;
  transform: translate3d(0, 0, 0);
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  overflow: hidden; }
  .continer:after {
    content: "";
    display: table;
    clear: both; }
  .continer.under {
    margin: 424px 0 0; }
    @media screen and (max-width: 767px) {
      .continer.under {
        margin: 260px 0 0; } }
  .continer .wrap {
    background: #fff; }
    .continer .wrap:after {
      content: "";
      display: table;
      clear: both; }
    .continer .wrap section.mainArea .inner {
      max-width: 1300px;
      margin: 0 auto;
      position: relative;
      padding: 0 20px 80px; }
      .continer .wrap section.mainArea .inner:after {
        content: "";
        display: table;
        clear: both; }
      @media screen and (max-width: 767px) {
        .continer .wrap section.mainArea .inner {
          max-width: initial; } }
      @media screen and (max-width: 767px) {
        .continer .wrap section.mainArea .inner {
          padding: 0 20px 40px; } }
    .continer .wrap .contentsBox {
      padding: 0 9.2307%; }
      .continer .wrap .contentsBox:after {
        content: "";
        display: table;
        clear: both; }
      @media screen and (max-width: 767px) {
        .continer .wrap .contentsBox {
          padding: 0; } }
    .continer .wrap .sectionTtl {
      text-align: center; }
      .continer .wrap .sectionTtl h2 {
        font-size: 2.8rem;
        position: relative;
        display: inline-block;
        letter-spacing: .15em;
        margin: 0 0 19px;
        font-weight: 600; }
        @media screen and (max-width: 767px) {
          .continer .wrap .sectionTtl h2 {
            font-size: 2.6rem; } }
        .continer .wrap .sectionTtl h2:before {
          content: '';
          display: block;
          position: absolute;
          background: #a5d404;
          height: 2px;
          width: 50px;
          bottom: -70px;
          top: 0;
          left: 0;
          right: 0;
          margin: auto; }
          @media screen and (max-width: 767px) {
            .continer .wrap .sectionTtl h2:before {
              height: 1px; } }
        .continer .wrap .sectionTtl h2 span {
          transition: opacity 1s 0.3s, transform 1s 0.3s cubic-bezier(0.215, 0.61, 0.355, 1); }
    .continer .wrap .mainReadTxt {
      padding: 70px 10%;
      line-height: 2.2;
      font-size: 1.6rem; }
      @media screen and (min-width: 768px) and (max-width: 1120px) {
        .continer .wrap .mainReadTxt {
          padding: 70px 0; } }
      @media screen and (max-width: 767px) {
        .continer .wrap .mainReadTxt {
          padding: 35px 0;
          line-height: 1.9; } }

/* ==========================================================================

メインビジュアル（下層は見出し扱い）

========================================================================== */
#topVisual {
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  top: 0;
  left: 0;
  z-index: 1;
  position: absolute;
  transform: translate3d(0, 0, 0);
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  overflow: hidden; }
  #topVisual h1 {
    z-index: 14;
    font-size: 1.8rem;
    margin: 195px 0 0;
    transition: all .5s ease; }
    @media screen and (max-width: 767px) {
      #topVisual h1 {
        font-size: 2.5vw;
        margin: 24.25% 0 0; } }
    #topVisual h1.landChange {
      margin: 17.25% 0 0; }
  #topVisual #bg {
    height: 50%;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 12;
    position: absolute;
    overflow: hidden;
    background: #a5d404; }
    #topVisual #bg:before {
      content: '';
      position: absolute;
      z-index: -1;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      transform-origin: 50%;
      transition-property: transform;
      transition-duration: .7s;
      transition-timing-function: cubic-bezier(0.68, -0.55, 0.265, 1.55);
      transform: scale(0, 0); }
    #topVisual #bg.scale1:before {
      transform: scale(1); }
    #topVisual #bg.scale0:before {
      transform: scale(0); }
    #topVisual #bg.scaleX1:before {
      transform: scaleX(1); }
    #topVisual #bg.scaleX0:before {
      transform: scaleX(0); }
    #topVisual #bg.scaleY1:before {
      transform: scaleY(1); }
    #topVisual #bg.scaleY0:before {
      transform: scaleY(0); }
    #topVisual #bg.bg1 {
      background: #a5d404; }
      #topVisual #bg.bg1:before {
        background: #00b2d8; }
    #topVisual #bg.bg2 {
      background: #e74291; }
      #topVisual #bg.bg2:before {
        background: #00b2d8; }
    #topVisual #bg.bg3 {
      background: #e74291; }
      #topVisual #bg.bg3:before {
        background: #ffae00; }
    #topVisual #bg.bg4 {
      background: #a5d404; }
      #topVisual #bg.bg4:before {
        background: #ffae00; }
    #topVisual #bg .centerInner {
      width: 100%;
      right: 50%;
      bottom: 0;
      margin: 0;
      position: absolute;
      transform: translate(50%, 50%); }
      #topVisual #bg .centerInner p {
        white-space: nowrap;
        letter-spacing: .1em;
        transition: all .5s ease;
        color: #fff;
        font-size: 12.8rem;
        text-align: center; }
        @media screen and (max-width: 767px) {
          #topVisual #bg .centerInner p {
            font-size: 15vw;
            line-height: 1.6; } }
        #topVisual #bg .centerInner p.landChange {
          font-size: 9vw;
          line-height: 1.7; }
          #topVisual #bg .centerInner p.landChange span {
            margin: -22px !important;
            width: 12% !important; }
            #topVisual #bg .centerInner p.landChange span:nth-child(1) {
              width: 122px !important; }
            #topVisual #bg .centerInner p.landChange span:nth-child(10), #topVisual #bg .centerInner p.landChange span:nth-child(17) {
              width: 105px !important; }
  #topVisual span {
    margin: -24px;
    width: 140px;
    height: 30px;
    display: inline-block; }
    @media screen and (max-width: 767px) {
      #topVisual span {
        margin: -7%;
        width: 24%; } }
    #topVisual span:nth-child(1) {
      width: 175px; }
      @media screen and (max-width: 767px) {
        #topVisual span:nth-child(1) {
          width: 49%;
          margin: -15.888%; } }
    #topVisual span:nth-child(10), #topVisual span:nth-child(17) {
      width: 122px; }
      @media screen and (max-width: 767px) {
        #topVisual span:nth-child(10), #topVisual span:nth-child(17) {
          width: 21%; } }
    #topVisual span.nemoy {
      font-family: 'NemoyLight'; }
    #topVisual span.caesar {
      font-family: 'Caesar Dressing', cursive; }
    #topVisual span.zilla {
      font-family: 'Zilla Slab Highlight', cursive; }
    #topVisual span.alegreya {
      font-family: 'Alegreya SC', serif; }
    #topVisual span.josefin {
      font-family: 'Josefin Slab', serif; }
    #topVisual span.tangerin {
      font-family: 'Tangerine', cursive; }
    #topVisual span.bungee {
      font-family: 'Bungee Shade', cursive; }
    #topVisual span.akronim {
      font-family: 'Akronim', cursive; }
    #topVisual span.fredericka {
      font-family: 'Fredericka the Great', cursive; }
    #topVisual span.codystar {
      font-family: 'Codystar', cursive; }
    #topVisual span.monoton {
      font-family: 'Monoton', cursive; }
    #topVisual span.gaegu {
      font-family: 'Gaegu', cursive; }
  #topVisual .mainTxt {
    overflow: hidden;
    white-space: nowrap;
    letter-spacing: .1em;
    transition: all .5s ease;
    width: 100%;
    font-size: 12.8rem;
    right: 50%;
    bottom: 50%;
    margin: 0;
    position: absolute;
    transform: translate(50%, 50%); }
    @media screen and (max-width: 767px) {
      #topVisual .mainTxt {
        font-size: 15vw;
        line-height: 1.6; } }
    #topVisual .mainTxt.landChange {
      font-size: 9vw;
      line-height: 1.7; }
      #topVisual .mainTxt.landChange span {
        margin: -22px !important;
        width: 12% !important; }
        #topVisual .mainTxt.landChange span:nth-child(1) {
          width: 122px !important; }
        #topVisual .mainTxt.landChange span:nth-child(10), #topVisual .mainTxt.landChange span:nth-child(17) {
          width: 105px !important; }
  #topVisual.under {
    height: 424px; }
    @media screen and (max-width: 767px) {
      #topVisual.under {
        height: 283px; } }
    #topVisual.under #bg p,
    #topVisual.under .centerInner,
    #topVisual.under .mainTxt {
      font-size: 7.2rem !important; }
      @media screen and (max-width: 767px) {
        #topVisual.under #bg p,
        #topVisual.under .centerInner,
        #topVisual.under .mainTxt {
          font-size: 12.5vw !important; } }
  @media screen and (max-width: 767px) and (max-width: 767px) {
    #topVisual.under #bg p span,
    #topVisual.under .centerInner span,
    #topVisual.under .mainTxt span {
      margin: -3%;
      width: 16.5%; } }
      @media screen and (max-width: 767px) {
          #topVisual.under #bg p.landChange,
          #topVisual.under .centerInner.landChange,
          #topVisual.under .mainTxt.landChange {
            line-height: 1.7; }
            #topVisual.under #bg p.landChange span,
            #topVisual.under .centerInner.landChange span,
            #topVisual.under .mainTxt.landChange span {
              margin: -39px !important;
              width: 24% !important; }
              #topVisual.under #bg p.landChange span:nth-child(1), #topVisual.under #bg p.landChange span:nth-child(10), #topVisual.under #bg p.landChange span:nth-child(17),
              #topVisual.under .centerInner.landChange span:nth-child(1),
              #topVisual.under .centerInner.landChange span:nth-child(10),
              #topVisual.under .centerInner.landChange span:nth-child(17),
              #topVisual.under .mainTxt.landChange span:nth-child(1),
              #topVisual.under .mainTxt.landChange span:nth-child(10),
              #topVisual.under .mainTxt.landChange span:nth-child(17) {
                margin: -39px !important;
                width: 24% !important; } }
    #topVisual.under #bg p.mini,
    #topVisual.under .centerInner.mini,
    #topVisual.under .mainTxt.mini {
      font-size: 6rem; }
      #topVisual.under #bg p.mini span,
      #topVisual.under .centerInner.mini span,
      #topVisual.under .mainTxt.mini span {
        margin: -12px;
        width: 68px; }
        @media screen and (max-width: 767px) {
          #topVisual.under #bg p.mini span,
          #topVisual.under .centerInner.mini span,
          #topVisual.under .mainTxt.mini span {
            margin: -9%;
            width: 24%; } }
      #topVisual.under #bg p.mini.landChange span,
      #topVisual.under .centerInner.mini.landChange span,
      #topVisual.under .mainTxt.mini.landChange span {
        margin: -12px !important;
        width: 7.88% !important; }
        @media screen and (max-width: 767px) {
          #topVisual.under #bg p.mini.landChange span,
          #topVisual.under .centerInner.mini.landChange span,
          #topVisual.under .mainTxt.mini.landChange span {
            margin: -12px !important;
            width: 10.88% !important; } }
      @media screen and (max-width: 767px) {
        #topVisual.under #bg p.mini,
        #topVisual.under .centerInner.mini,
        #topVisual.under .mainTxt.mini {
          font-size: 9vw !important; } }
    #topVisual.under span {
      margin: -14px;
      width: 80px; }
      @media screen and (max-width: 767px) {
        #topVisual.under span {
          margin: -9%;
          width: 25%; } }
      #topVisual.under span:nth-child(1), #topVisual.under span:nth-child(10), #topVisual.under span:nth-child(17) {
        margin: -14px;
        width: 80px; }
        @media screen and (max-width: 767px) {
          #topVisual.under span:nth-child(1), #topVisual.under span:nth-child(10), #topVisual.under span:nth-child(17) {
            margin: -9%;
            width: 25%; } }
    #topVisual.under h1 {
      margin: 180px 0 0; }
      @media screen and (max-width: 767px) {
        #topVisual.under h1 {
          font-size: 4vw;
          margin: 23.555% 0 0; } }

/* ==========================================================================

ヘッダー(下層用)

========================================================================== */
.header {
  position: absolute;
  z-index: 2;
  top: 0;
  left: 0;
  width: 100%;
  background: transparent; }
  .header .inner {
    padding: 0 20px; }
    @media screen and (max-width: 767px) {
      .header .inner {
        padding: 8px 0; } }
    .header .inner .logo {
      display: inline-block;
      margin: 81px 0 0 -40px;
      transform: rotate(90deg); }
      @media screen and (max-width: 767px) {
        .header .inner .logo {
          margin: 43px 0 0 -10px; } }
      .header .inner .logo a {
        width: 120px;
        display: block; }
        @media screen and (max-width: 767px) {
          .header .inner .logo a {
            width: 80px;
            margin: 0 auto; } }
    .header .inner #topMenu {
      float: right;
      text-align: right;
      margin: 26px 0 0;
      /*
			&.under {
				a {
					color: #363a42 !important;
					@include underBar(#363a42);

					&:before,
					&:hover:before {
						background: #363a42 !important;
					}
				}
			}
			*/ }
      @media screen and (max-width: 767px) {
        .header .inner #topMenu {
          display: none; } }
      .header .inner #topMenu li {
        margin: 0 0 18px;
        font-size: 1.8rem;
        line-height: 1;
        letter-spacing: .06em;
        font-family: "BebasNeueRegular", sans-serif; }
        .header .inner #topMenu li:last-of-type {
          margin-bottom: 0; }
        .header .inner #topMenu li a {
          color: #fff; }
          .header .inner #topMenu li a.pcHover {
            color: #fff;
            position: relative;
            display: inline-block; }
            .header .inner #topMenu li a.pcHover:before {
              z-index: 2;
              position: absolute;
              top: 1.2em;
              left: 0;
              content: "";
              display: inline-block;
              width: 0;
              height: 1px;
              background: #fff;
              transition: all .5s ease; }
            .header .inner #topMenu li a.pcHover:hover:before {
              width: 100%; }
        .header .inner #topMenu li.contactSub {
          margin: 30px 0 0; }

.snsLink {
  position: fixed;
  z-index: 101;
  bottom: 50px;
  left: 34px;
  width: 100%;
  width: auto;
  background: transparent; }
  @media screen and (max-width: 767px) {
    .snsLink {
      display: none; } }
  .snsLink.under {
    z-index: 101; }
  .snsLink .snsTtl {
    float: left;
    color: #bfbfbf;
    position: relative;
    margin: 0 0 11px -4px; }
    .snsLink .snsTtl:after {
      position: absolute;
      top: 0;
      left: 38px;
      bottom: 0;
      right: 0;
      margin: 0;
      z-index: 2;
      content: '';
      height: 8px;
      width: 54px;
      background: url(/images/common/img_top_arrow.svg) no-repeat;
      background-size: 54px 8px; }
  .snsLink ul {
    clear: both;
    padding: 61px 0 0; }
    .snsLink ul li {
      margin: 18px 0 0; }
    .snsLink ul a svg {
      fill: #bfbfbf;
      transition: all .5s ease; }
    .snsLink ul a:hover svg {
      fill: #525863; }

/* ==========================================================================

ハンバーガーボタン

========================================================================== */
.btnHunArea {
  width: 100%;
  position: fixed;
  top: 0;
  right: 0;
  z-index: 1002;
  display: none;
  padding: 0 20px;
  background: transparent; }
  @media screen and (max-width: 767px) {
    .btnHunArea {
      padding: 8px 0;
      display: block !important; } }
  .btnHunArea .mainMenu {
    top: 20px;
    right: 20px;
    position: absolute; }
    @media screen and (max-width: 767px) {
      .btnHunArea .mainMenu {
        top: 27px;
        right: 20px; } }
    .btnHunArea .mainMenu a {
      width: 46px;
      height: 34px;
      display: block;
      margin: 0 0 0 -46px; }
      .btnHunArea .mainMenu a span {
        top: 50%;
        right: 50%;
        width: 33px;
        height: 6px;
        display: block;
        position: absolute;
        text-indent: -9999px;
        background: #525863;
        transition: all .5s ease;
        margin: -3px 0 0 0; }
        .btnHunArea .mainMenu a span:before {
          top: 50%;
          right: 0;
          width: 46px;
          height: 6px;
          content: '';
          display: block;
          position: absolute;
          background: #525863;
          transition: all .5s ease;
          margin-top: -17px; }
        .btnHunArea .mainMenu a span:after {
          top: 50%;
          right: 0;
          width: 24px;
          height: 6px;
          content: '';
          display: block;
          position: absolute;
          background: #525863;
          transition: all .5s ease;
          margin-top: 11px; }
        .btnHunArea .mainMenu a span.iconClose {
          background: transparent; }
          .btnHunArea .mainMenu a span.iconClose:before, .btnHunArea .mainMenu a span.iconClose:after {
            width: 46px;
            margin-top: -1px;
            background: #fff; }
          .btnHunArea .mainMenu a span.iconClose:before {
            transform: rotate(-45deg); }
          .btnHunArea .mainMenu a span.iconClose:after {
            transform: rotate(-135deg); }
      .btnHunArea .mainMenu a.hover span {
        background: #789807; }
        .btnHunArea .mainMenu a.hover span:before, .btnHunArea .mainMenu a.hover span:after {
          background: #789807; }
        .btnHunArea .mainMenu a.hover span.iconClose {
          background: transparent !important; }

/* ==========================================================================

ドロワー

========================================================================== */
#drawer {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1001;
  width: 100%;
  height: 100%;
  background: #a5d404; }

@keyframes navActive {
  0% {
    clip-path: polygon(100% 0, 100% 0%, 100% 100%, 100% 100%); }
  50% {
    clip-path: polygon(50% 0, 100% 0, 100% 100%, 0 100%); }
  100% {
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%); } }

@keyframes navPassive {
  0% {
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%); }
  50% {
    clip-path: polygon(50% 0, 100% 0, 100% 100%, 0 100%); }
  100% {
    clip-path: polygon(100% 0, 100% 0, 100% 100%, 100% 100%); } }
  #drawer.active {
    display: block;
    animation: navActive .5s ease 1 both; }
  #drawer.passive {
    display: block;
    animation: navPassive .5s ease-out 1 both; }
  #drawer #drawerInner {
    top: -500px;
    position: relative;
    overflow-y: auto;
    display: flex;
    justify-content: center;
    align-items: center; }
    #drawer #drawerInner li {
      line-height: 1;
      text-align: center;
      margin: 0 0 27px;
      font-size: 2.4rem;
      letter-spacing: .06em;
      font-family: "BebasNeueRegular", sans-serif; }
      @media screen and (max-width: 767px) {
        #drawer #drawerInner li {
          font-size: 2.8rem; } }
      #drawer #drawerInner li a {
        color: #fff; }
        #drawer #drawerInner li a.pcHover {
          color: #fff;
          position: relative;
          display: inline-block; }
          #drawer #drawerInner li a.pcHover:before {
            z-index: 2;
            position: absolute;
            top: 1.2em;
            left: 0;
            content: "";
            display: inline-block;
            width: 0;
            height: 1px;
            background: #fff;
            transition: all .5s ease; }
          #drawer #drawerInner li a.pcHover:hover:before {
            width: 100%; }
      #drawer #drawerInner li.contactSub {
        margin: 45px 0 0; }

/* ==========================================================================

フッター

========================================================================== */
.footer {
  width: 100%;
  overflow: hidden;
  background: #fff; }
  .footer .inner {
    max-width: 1300px;
    margin: 0 auto;
    padding: 0 20px; }
    .footer .inner:after {
      content: "";
      display: table;
      clear: both; }
    @media screen and (max-width: 767px) {
      .footer .inner {
        max-width: initial; } }
    .footer .inner .topFooter {
      padding: 91px 0 0; }
      .footer .inner .topFooter:after {
        content: "";
        display: table;
        clear: both; }
      @media screen and (max-width: 767px) {
        .footer .inner .topFooter {
          padding: 0 0 0; } }
      .footer .inner .topFooter .ttlBox {
        position: relative;
        float: left;
        margin-right: 7.55%;
        padding: 14px 5.952% 13px 6.428%;
        transition: all .5s ease;
        border-right: 2px solid #bfbfbf; }
        @media screen and (min-width: 768px) and (max-width: 1200px) {
          .footer .inner .topFooter .ttlBox {
            float: none;
            padding: 14px 0 60px;
            text-align: center;
            margin-right: 0;
            border-right: none; }
            .footer .inner .topFooter .ttlBox:before {
              background: #bfbfbf;
              width: 107px;
              height: 2px;
              position: absolute;
              top: 128px;
              bottom: 0;
              right: 0;
              left: 0;
              margin: 0 auto;
              content: '';
              z-index: 2; } }
        @media screen and (max-width: 767px) {
          .footer .inner .topFooter .ttlBox {
            padding: 0 0 70px;
            float: none;
            text-align: center;
            margin-right: 0;
            border-right: none; }
            .footer .inner .topFooter .ttlBox:before {
              background: #bfbfbf;
              width: 107px;
              height: 1px;
              position: absolute;
              top: 110px;
              bottom: 0;
              right: 0;
              left: 0;
              margin: 0 auto;
              content: '';
              z-index: 2; } }
        .footer .inner .topFooter .ttlBox h2 {
          color: #00b2d8;
          font-family: "BebasNeueBold", sans-serif;
          font-size: 4.8rem;
          line-height: 1; }
        .footer .inner .topFooter .ttlBox p {
          font-size: 1.6rem;
          padding: 7px 0 0;
          letter-spacing: .09em; }
      .footer .inner .topFooter .infoBox {
        display: flex;
        align-content: space-between;
        transition: all .5s ease; }
        .footer .inner .topFooter .infoBox:after {
          content: "";
          display: table;
          clear: both; }
        @media screen and (min-width: 768px) and (max-width: 1200px) {
          .footer .inner .topFooter .infoBox {
            padding: 0;
            width: 100%; } }
        @media screen and (max-width: 767px) {
          .footer .inner .topFooter .infoBox {
            display: block;
            width: 100%;
            padding: 0; } }
        .footer .inner .topFooter .infoBox .left {
          margin: 0 15.25% 0 0; }
          @media screen and (min-width: 768px) and (max-width: 1200px) {
            .footer .inner .topFooter .infoBox .left {
              margin: 0;
              width: 50%; } }
          @media screen and (max-width: 767px) {
            .footer .inner .topFooter .infoBox .left {
              margin: 0 0 30px; } }
        .footer .inner .topFooter .infoBox .right {
          width: 41.99%; }
          @media screen and (min-width: 768px) and (max-width: 1200px) {
            .footer .inner .topFooter .infoBox .right {
              width: 50%; } }
        .footer .inner .topFooter .infoBox .left,
        .footer .inner .topFooter .infoBox .right {
          white-space: nowrap;
          text-align: center;
          box-sizing: border-box; }
          .footer .inner .topFooter .infoBox .left:after,
          .footer .inner .topFooter .infoBox .right:after {
            content: "";
            display: table;
            clear: both; }
          @media screen and (max-width: 767px) {
            .footer .inner .topFooter .infoBox .left,
            .footer .inner .topFooter .infoBox .right {
              width: 100%;
              white-space: inherit; } }
          .footer .inner .topFooter .infoBox .left h3,
          .footer .inner .topFooter .infoBox .right h3 {
            font-size: 1.8rem;
            font-weight: 700;
            color: #00b2d8;
            letter-spacing: .05em; }
          .footer .inner .topFooter .infoBox .left .tel,
          .footer .inner .topFooter .infoBox .right .tel {
            margin: 12px 0 3px;
            line-height: 1;
            letter-spacing: .05em;
            font-size: 3.6rem;
            font-family: "BebasNeueBold", sans-serif; }
            .footer .inner .topFooter .infoBox .left .tel a,
            .footer .inner .topFooter .infoBox .right .tel a {
              line-height: 1;
              padding: 0 0 0 32px;
              color: #525863;
              position: relative;
              letter-spacing: .03em; }
              .footer .inner .topFooter .infoBox .left .tel a:before,
              .footer .inner .topFooter .infoBox .right .tel a:before {
                position: absolute;
                content: '';
                z-index: 2;
                left: -2px;
                top: 1px;
                margin: auto 0;
                width: 26px;
                height: 26px;
                background: url(/images/common/icon_tel.svg) no-repeat;
                background-size: 26px 26px; }
              .footer .inner .topFooter .infoBox .left .tel a.windowsActive:before,
              .footer .inner .topFooter .infoBox .right .tel a.windowsActive:before {
                top: 6px; }
          .footer .inner .topFooter .infoBox .left .businessHours,
          .footer .inner .topFooter .infoBox .right .businessHours {
            font-size: 1.4rem;
            letter-spacing: .05em; }
        .footer .inner .topFooter .infoBox .btnBox {
          width: 100%;
          margin: 13px auto 0; }
          @media screen and (min-width: 768px) and (max-width: 1200px) {
            .footer .inner .topFooter .infoBox .btnBox {
              width: 335px; } }
          .footer .inner .topFooter .infoBox .btnBox a {
            padding: 0 0 0 11.2%; }
            @media screen and (max-width: 767px) {
              .footer .inner .topFooter .infoBox .btnBox a {
                font-size: 1.4rem;
                padding: 0 0 0 35px; } }
            .footer .inner .topFooter .infoBox .btnBox a.modan:hover {
              box-shadow: 0 0 0 2px #00b2d8 inset; }
              @media screen and (max-width: 767px) {
                .footer .inner .topFooter .infoBox .btnBox a.modan:hover {
                  box-shadow: 0 0 0 1px #00b2d8 inset; } }
            .footer .inner .topFooter .infoBox .btnBox a:before {
              background: #00b2d8; }
            .footer .inner .topFooter .infoBox .btnBox a:after {
              left: 7.88%;
              top: 21px;
              width: 23px;
              height: 17px;
              background: url(/images/common/icon_mail.svg) no-repeat;
              background-size: 23px 17px;
              transition: all .5s ease; }
              @media screen and (min-width: 768px) and (max-width: 1200px) {
                .footer .inner .topFooter .infoBox .btnBox a:after {
                  left: 33px; } }
              @media screen and (max-width: 767px) {
                .footer .inner .topFooter .infoBox .btnBox a:after {
                  left: 24px; } }
            .footer .inner .topFooter .infoBox .btnBox a.modan:hover:after {
              width: 23px;
              height: 17px;
              background: url(/images/common/icon_mail_w.svg) no-repeat;
              background-size: 23px 17px;
              transition: all .5s ease; }
            .footer .inner .topFooter .infoBox .btnBox a.spNotHover:hover {
              box-shadow: 0 0 0 2px #00b2d8 inset; }
              @media screen and (max-width: 767px) {
                .footer .inner .topFooter .infoBox .btnBox a.spNotHover:hover {
                  box-shadow: 0 0 0 1px #00b2d8 inset; } }
            .footer .inner .topFooter .infoBox .btnBox a.spNotHover:hover:after {
              background: url(/images/common/icon_mail.svg) no-repeat; }
    .footer .inner .underFooter {
      position: relative;
      padding: 40px 0 94px;
      border-top: 1px solid #ddd;
      margin: 148px 0 0; }
      .footer .inner .underFooter:after {
        content: "";
        display: table;
        clear: both; }
      @media screen and (max-width: 767px) {
        .footer .inner .underFooter {
          padding: 105px 0 58px;
          margin: 130px 0 0; } }
      .footer .inner .underFooter .backToTop {
        top: -60px;
        bottom: 0;
        left: 0;
        right: 0;
        margin: 0 auto;
        width: 68px;
        height: 110px;
        z-index: 12;
        overflow: hidden;
        position: absolute; }
        .footer .inner .underFooter .backToTop a.hover .cls,
        .footer .inner .underFooter .backToTop a.hover .gototopTxt {
          fill: #a5d404; }
        .footer .inner .underFooter .backToTop a.hover .cls,
        .footer .inner .underFooter .backToTop a.hover .arrows {
          stroke: #a5d404; }
        .footer .inner .underFooter .backToTop a {
          transition: all .5s ease;
          display: block; }
          .footer .inner .underFooter .backToTop a .cls,
          .footer .inner .underFooter .backToTop a .gototopTxt {
            transition: all .5s ease;
            fill: #525863; }
          .footer .inner .underFooter .backToTop a .cls,
          .footer .inner .underFooter .backToTop a .arrows {
            transition: all .5s ease;
            stroke: #525863; }
          .footer .inner .underFooter .backToTop a .cls {
            transition: all .5s ease;
            stroke-width: 2px; }
          .footer .inner .underFooter .backToTop a .gototopTxt {
            transition: all .5s ease;
            font-size: 1.8rem;
            text-anchor: middle;
            font-family: BebasNeueBold;
            letter-spacing: .08em; }
          .footer .inner .underFooter .backToTop a .arrows {
            fill: none;
            stroke-width: 4px;
            fill-rule: evenodd; }
      .footer .inner .underFooter .copy {
        float: left;
        line-height: 1;
        margin: 0 0 0 45px; }
        @media screen and (max-width: 767px) {
          .footer .inner .underFooter .copy {
            float: none;
            text-align: center;
            margin: 0; } }
        .footer .inner .underFooter .copy small {
          font-size: 1.3rem; }
      .footer .inner .underFooter .footerList {
        float: right;
        margin: 0 47px 0 0; }
        @media screen and (max-width: 767px) {
          .footer .inner .underFooter .footerList {
            float: none;
            text-align: center;
            margin: 0 0 48px; } }
        .footer .inner .underFooter .footerList li {
          line-height: 1;
          display: inline-block;
          vertical-align: top;
          font-size: 1.4rem;
          margin: 0 0 0 30px; }
          @media screen and (max-width: 767px) {
            .footer .inner .underFooter .footerList li {
              margin: 0; } }
          .footer .inner .underFooter .footerList li a {
            color: #525863;
            transition: all .5s ease; }
            .footer .inner .underFooter .footerList li a:hover {
              color: #a5d404; }
      .footer .inner .underFooter .snsArea {
        display: none;
        margin: 58px 0 48px; }
        @media screen and (max-width: 767px) {
          .footer .inner .underFooter .snsArea {
            display: block; } }
        .footer .inner .underFooter .snsArea .snsTtl {
          white-space: nowrap;
          margin: 0 auto 26px;
          width: 40px;
          font-size: 1.4rem; }
        .footer .inner .underFooter .snsArea ul {
          display: flex;
          justify-content: center; }
          .footer .inner .underFooter .snsArea ul li {
            margin-right: 5.4945%; }
            .footer .inner .underFooter .snsArea ul li:last-of-type {
              margin-right: 0; }
          .footer .inner .underFooter .snsArea ul a svg {
            fill: #525863;
            transition: all .5s ease; }
          .footer .inner .underFooter .snsArea ul a:hover svg {
            fill: #a5d404; }

/* ==========================================================================

トップページ

========================================================================== */
.index .mouse {
  position: absolute;
  z-index: 15;
  bottom: 28px;
  left: 0;
  right: 0;
  margin: 0 auto;
  width: 37px;
  height: 72px;
  animation: mouseMove 3s ease infinite; }
  .index .mouse .mouseMain,
  .index .mouse .mousetxt {
    transition: all .5s ease;
    fill: #00b2d8; }
  .index .mouse .mouseCircle {
    fill: #fff; }
  .index .mouse .mousetxt {
    font-size: 1.4rem;
    text-anchor: middle;
    font-family: BebasNeueBold; }
  .index .mouse.landChange {
    width: 20px;
    bottom: -21px; }

@keyframes mouseMove {
  0%, 10%, 100%, 25%, 5%, 50% {
    transform: translateY(0); }
  20%, 30% {
    transform: translateY(10px); } }

.index section.whoWeAreArea {
  visibility: hidden;
  position: relative;
  z-index: 5; }
  .index section.whoWeAreArea:before {
    position: absolute;
    top: 146px;
    left: -53px;
    content: '';
    width: 452px;
    height: 327px;
    transform: skew(18deg);
    background: #a5d404;
    transition: all .5s ease;
    opacity: .13; }
    @media screen and (min-width: 426px) and (max-width: 767px) {
      .index section.whoWeAreArea:before {
        width: 444px; } }
    @media screen and (max-width: 767px) {
      .index section.whoWeAreArea:before {
        top: 60px;
        left: -53px;
        width: 199px; } }
  .index section.whoWeAreArea .inner {
    max-width: 1300px;
    margin: 0 auto;
    position: relative;
    padding: 0 20px; }
    .index section.whoWeAreArea .inner:after {
      content: "";
      display: table;
      clear: both; }
    @media screen and (max-width: 767px) {
      .index section.whoWeAreArea .inner {
        max-width: initial; } }
    @media screen and (max-width: 767px) {
      .index section.whoWeAreArea .inner {
        padding: 500px 20px 0; } }
    .index section.whoWeAreArea .inner .ttl {
      visibility: hidden;
      position: relative;
      top: 0;
      left: 25.25%; }
      @media screen and (max-width: 767px) {
        .index section.whoWeAreArea .inner .ttl {
          top: -234px;
          left: 76%; } }
      .index section.whoWeAreArea .inner .ttl:before {
        position: absolute;
        top: -81px;
        left: 12px;
        content: '';
        width: 26px;
        height: 310px;
        z-index: 2;
        transform: skew(-4deg);
        background: #a5d404;
        opacity: .3; }
        @media screen and (max-width: 767px) {
          .index section.whoWeAreArea .inner .ttl:before {
            top: -89px;
            left: 13px;
            height: 353px;
            width: 30px;
            transform: skew(-4deg); } }
      .index section.whoWeAreArea .inner .ttl h2 {
        white-space: nowrap;
        position: absolute;
        top: 22px;
        left: -121px;
        z-index: 3;
        font-size: 7.2rem;
        letter-spacing: -.02em;
        transform: rotate(90deg);
        font-family: "BebasNeueRegular", sans-serif; }
    .index section.whoWeAreArea .inner .iclipBox {
      position: relative;
      margin: 0 0 50px 37.539%; }
      .index section.whoWeAreArea .inner .iclipBox:after {
        content: "";
        display: table;
        clear: both; }
      @media screen and (max-width: 767px) {
        .index section.whoWeAreArea .inner .iclipBox {
          width: 100%;
          margin: 0; } }
      .index section.whoWeAreArea .inner .iclipBox .rightBox {
        width: 465px;
        box-sizing: border-box;
        position: relative; }
        @media screen and (max-width: 767px) {
          .index section.whoWeAreArea .inner .iclipBox .rightBox {
            width: 100%; } }
        .index section.whoWeAreArea .inner .iclipBox .rightBox h3 {
          width: 205px;
          display: block;
          margin: 0 0 40px 49px;
          transition: all .5s ease;
          -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
          overflow: hidden; }
          @media screen and (max-width: 767px) {
            .index section.whoWeAreArea .inner .iclipBox .rightBox h3 {
              width: 120px;
              margin: 0 0 32px; } }

@keyframes birdRot1 {
  0% {
    transform: rotate(9deg); }
  50% {
    transform: rotate(-9deg); }
  100% {
    transform: rotate(9deg); } }

@keyframes birdRot2 {
  0% {
    transform: rotate(4deg); }
  50% {
    transform: rotate(-4deg); }
  100% {
    transform: rotate(4deg); } }
        .index section.whoWeAreArea .inner .iclipBox .rightBox ul {
          position: relative; }
          .index section.whoWeAreArea .inner .iclipBox .rightBox ul li:nth-child(1) {
            width: 17px;
            height: 38px;
            position: absolute;
            top: -26px;
            right: 148px;
            animation: birdRot1 5s ease 0s infinite; }
          .index section.whoWeAreArea .inner .iclipBox .rightBox ul li:nth-child(2) {
            width: 14px;
            height: 41px;
            position: absolute;
            top: -28px;
            right: 131px;
            animation: birdRot1 5s ease 0s infinite; }
          .index section.whoWeAreArea .inner .iclipBox .rightBox ul li:nth-child(3) {
            width: 20px;
            height: 37px;
            position: absolute;
            top: -27px;
            right: 108px;
            animation: birdRot2 5s ease 0s infinite; }
          .index section.whoWeAreArea .inner .iclipBox .rightBox ul li:nth-child(4) {
            width: 15px;
            height: 40px;
            position: absolute;
            top: -28px;
            right: 59px;
            animation: birdRot1 3s ease 0s infinite; }
          .index section.whoWeAreArea .inner .iclipBox .rightBox ul li:nth-child(5) {
            width: 15px;
            height: 42px;
            position: absolute;
            top: -29px;
            right: 20px;
            animation: birdRot2 2s ease 0s infinite; }
        .index section.whoWeAreArea .inner .iclipBox .rightBox p {
          width: 465px;
          border-top: 3px solid #a5d404;
          line-height: 1.6;
          font-size: 1.6rem;
          letter-spacing: .05em;
          padding: 23px 0 0 48px;
          line-height: 3.1;
          box-sizing: border-box; }
          @media screen and (max-width: 767px) {
            .index section.whoWeAreArea .inner .iclipBox .rightBox p {
              line-height: 1.4; } }
          @media screen and (max-width: 767px) {
            .index section.whoWeAreArea .inner .iclipBox .rightBox p {
              width: 100%;
              border-top: 2px solid #a5d404;
              padding: 28px 0 0;
              line-height: 2.2; } }

.index section.worksArea {
  visibility: hidden;
  position: relative;
  transition: all .5s ease;
  z-index: 4;
  margin: 400px 0 0; }
  @media screen and (max-width: 767px) {
    .index section.worksArea {
      margin: 0; } }
  .index section.worksArea:before {
    position: absolute;
    top: 31px;
    right: calc(50% - 640px);
    content: '';
    width: 459px;
    height: 129px;
    background-image: url(/images/works/top_pickup_back.svg);
    background-repeat: no-repeat;
    transition: all .5s ease; }
    @media screen and (min-width: 768px) and (max-width: 1120px) {
      .index section.worksArea:before {
        top: -1.5%;
        right: 0;
        width: 35%;
        background-size: contain;
        background-position: bottom; } }
    @media screen and (max-width: 767px) {
      .index section.worksArea:before {
        top: 1.5%;
        right: 0;
        width: 65%;
        background-size: contain;
        background-position: bottom; } }
  .index section.worksArea:after {
    position: absolute;
    top: 35%;
    right: -53px;
    content: '';
    width: 735px;
    height: 327px;
    transform: skew(-18deg);
    background: #00b2d8;
    transition: all .5s ease;
    opacity: .13;
    z-index: -1; }
    @media screen and (min-width: 768px) and (max-width: 1120px) {
      .index section.worksArea:after {
        top: 33%;
        right: -53px;
        width: 367px; } }
    @media screen and (max-width: 767px) {
      .index section.worksArea:after {
        top: 26.5%;
        right: -53px;
        width: 199px; } }
  .index section.worksArea .inner {
    max-width: 1300px;
    margin: 0 auto;
    position: relative;
    padding: 0 20px 82px; }
    .index section.worksArea .inner:after {
      content: "";
      display: table;
      clear: both; }
    @media screen and (max-width: 767px) {
      .index section.worksArea .inner {
        max-width: initial; } }
    .index section.worksArea .inner:after {
      content: "";
      display: table;
      clear: both; }
    @media screen and (min-width: 768px) and (max-width: 1120px) {
      .index section.worksArea .inner {
        padding: 0 20px 82px; } }
    @media screen and (max-width: 767px) {
      .index section.worksArea .inner {
        padding: 0 20px 31px; } }
    .index section.worksArea .inner .ttl {
      visibility: hidden;
      float: left;
      width: 125px;
      margin: 0 0 0 11.2%;
      padding: 20px 0 0;
      text-align: center;
      position: relative;
      color: #00b2d8;
      border-top: 4px solid #00b2d8; }
      @media screen and (max-width: 767px) {
        .index section.worksArea .inner .ttl {
          float: none;
          width: 125px;
          margin: 0; } }
      .index section.worksArea .inner .ttl p {
        line-height: 1;
        letter-spacing: .5em;
        font-size: 1.2rem;
        white-space: nowrap; }
      .index section.worksArea .inner .ttl h2 {
        white-space: nowrap;
        padding: 0;
        line-height: 1;
        margin: 64px 0 0 -21px;
        font-size: 7.2rem;
        letter-spacing: -.02em;
        transform: rotate(90deg);
        font-family: "BebasNeueRegular", sans-serif; }
        .index section.worksArea .inner .ttl h2.windowsActive {
          margin: 64px 0 0 -9px; }
      .index section.worksArea .inner .ttl .btnBox {
        width: 53px;
        height: 191px;
        margin: 80px auto 0; }
        .index section.worksArea .inner .ttl .btnBox.mainBtn a {
          width: 53px;
          height: 191px;
          line-height: inherit;
          position: relative;
          writing-mode: vertical-rl; }
          .index section.worksArea .inner .ttl .btnBox.mainBtn a.modan:hover {
            box-shadow: 0 0 0 2px #00b2d8 inset; }
            @media screen and (max-width: 767px) {
              .index section.worksArea .inner .ttl .btnBox.mainBtn a.modan:hover {
                box-shadow: 0 0 0 1px #00b2d8 inset; } }
          .index section.worksArea .inner .ttl .btnBox.mainBtn a:after {
            top: 0;
            bottom: -148px; }
          .index section.worksArea .inner .ttl .btnBox.mainBtn a span {
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            margin: auto 0;
            display: flex;
            justify-content: center;
            align-items: center;
            position: absolute; }
    .index section.worksArea .inner .worksPickUpList {
      float: left;
      max-width: 67.1%;
      width: 100%;
      margin: 68px 0 175px 2.91%;
      display: flex; }
      .index section.worksArea .inner .worksPickUpList:after {
        content: "";
        display: table;
        clear: both; }
      @media screen and (min-width: 768px) and (max-width: 1120px) {
        .index section.worksArea .inner .worksPickUpList {
          margin: 68px 0 120px 2.91%; } }
      @media screen and (max-width: 767px) {
        .index section.worksArea .inner .worksPickUpList {
          float: none;
          max-width: 100%;
          width: 100%;
          margin: 0 0 2.91%;
          flex-wrap: wrap; } }
      .index section.worksArea .inner .worksPickUpList .worksPickUpBox {
        max-width: 372px;
        width: 100%; }
        @media screen and (max-width: 767px) {
          .index section.worksArea .inner .worksPickUpList .worksPickUpBox {
            max-width: 100%; } }
        .index section.worksArea .inner .worksPickUpList .worksPickUpBox:nth-of-type(2) {
          margin-left: 6.7rem;
          margin-top: 65px; }
          @media screen and (max-width: 767px) {
            .index section.worksArea .inner .worksPickUpList .worksPickUpBox:nth-of-type(2) {
              margin-left: 0; } }
        .index section.worksArea .inner .worksPickUpList .worksPickUpBox .worksPickUpParentCategory {
          font-family: "BebasNeueRegular", sans-serif;
          font-size: 2.8rem;
          line-height: 0.86;
          letter-spacing: 1.4px;
          color: #01b2d8;
          margin-left: 3px; }
          .index section.worksArea .inner .worksPickUpList .worksPickUpBox .worksPickUpParentCategory::before {
            content: "";
            display: inline-block;
            width: 15px;
            height: 2px;
            background-color: #01b2d8;
            vertical-align: middle;
            margin-right: 6px; }
          @media screen and (max-width: 767px) {
            .index section.worksArea .inner .worksPickUpList .worksPickUpBox .worksPickUpParentCategory {
              font-size: 3.8rem; }
              .index section.worksArea .inner .worksPickUpList .worksPickUpBox .worksPickUpParentCategory::before {
                width: 25px; } }
        .index section.worksArea .inner .worksPickUpList .worksPickUpBox a:hover img {
          transform: scale(1.1); }
          @media screen and (max-width: 767px) {
            .index section.worksArea .inner .worksPickUpList .worksPickUpBox a:hover img {
              transform: scale(1); } }
        .index section.worksArea .inner .worksPickUpList .worksPickUpBox .pickUpImgBox {
          width: 100%;
          overflow: hidden;
          position: relative;
          box-shadow: 2px 5px 10px 0 rgba(51, 51, 51, 0.16); }
          .index section.worksArea .inner .worksPickUpList .worksPickUpBox .pickUpImgBox:before {
            content: "";
            display: block;
            padding-top: 100%; }
          .index section.worksArea .inner .worksPickUpList .worksPickUpBox .pickUpImgBox img {
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            margin: auto;
            object-fit: cover;
            transition: all .3s ease; }
        .index section.worksArea .inner .worksPickUpList .worksPickUpBox .pickUpTxtBox {
          box-sizing: border-box;
          position: absolute;
          left: 0;
          bottom: 0;
          width: 100%;
          background-color: rgba(255, 255, 255, 0.9);
          padding: 10px 20px; }
        .index section.worksArea .inner .worksPickUpList .worksPickUpBox .woksPickUpCategory {
          font-size: 1.2rem;
          font-weight: 400;
          line-height: 1.5;
          color: #aaaaaa;
          margin-bottom: 2px; }
        .index section.worksArea .inner .worksPickUpList .worksPickUpBox .woksPickUpTit {
          font-size: 1.6rem;
          font-weight: 500;
          line-height: 1.5;
          letter-spacing: 0.8px;
          color: #333333;
          margin-bottom: 9px; }
          @media screen and (max-width: 767px) {
            .index section.worksArea .inner .worksPickUpList .worksPickUpBox .woksPickUpTit {
              font-weight: 600; } }
        .index section.worksArea .inner .worksPickUpList .worksPickUpBox .worksPickUpTag {
          font-size: 1.2rem;
          font-weight: 300;
          line-height: 1.8;
          color: #11a3c3;
          border-top: solid 1px #11a3c3;
          padding-top: 5px; }
    .index section.worksArea .inner .worksTopList {
      max-width: 76%;
      width: 100%;
      margin: 68px auto 2.91%;
      display: flex;
      flex-wrap: wrap; }
      @media screen and (min-width: 768px) and (max-width: 1120px) {
        .index section.worksArea .inner .worksTopList {
          max-width: 80%;
          margin: 68px auto 2.91% 13%; } }
      @media screen and (max-width: 767px) {
        .index section.worksArea .inner .worksTopList {
          float: none;
          max-width: 100%;
          width: 100%;
          margin: 25% 0 0;
          flex-wrap: wrap; } }
      .index section.worksArea .inner .worksTopList .worksBox {
        max-width: 30%;
        width: 100%;
        margin: 0 0 75px 40px;
        flex: 1 1 25.333%; }
        @media screen and (max-width: 767px) {
          .index section.worksArea .inner .worksTopList .worksBox {
            max-width: 100%;
            flex: auto; }
            .index section.worksArea .inner .worksTopList .worksBox:nth-of-type(2n) {
              margin: 0 40px 20% 0; }
            .index section.worksArea .inner .worksTopList .worksBox:nth-of-type(2n+1) {
              margin: 0 0 20% 40px; } }
        .index section.worksArea .inner .worksTopList .worksBox:nth-of-type(6n+1) {
          margin-left: 0; }
        .index section.worksArea .inner .worksTopList .worksBox a:hover img {
          transform: scale(1.1); }
          @media screen and (max-width: 767px) {
            .index section.worksArea .inner .worksTopList .worksBox a:hover img {
              transform: scale(1); } }
        .index section.worksArea .inner .worksTopList .worksBox .worksImgBox {
          overflow: hidden;
          box-shadow: 2px 5px 10px 0 rgba(51, 51, 51, 0.16);
          position: relative; }
          .index section.worksArea .inner .worksTopList .worksBox .worksImgBox:before {
            content: "";
            display: block;
            padding-top: 100%; }
          .index section.worksArea .inner .worksTopList .worksBox .worksImgBox img {
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            margin: auto;
            object-fit: cover;
            transition: all .3s ease; }
        .index section.worksArea .inner .worksTopList .worksBox .worksCategory {
          font-size: 1.2rem;
          font-weight: 400;
          line-height: 1.5;
          color: #aaaaaa;
          margin-top: 20px;
          margin-bottom: 2px; }
          @media screen and (max-width: 767px) {
            .index section.worksArea .inner .worksTopList .worksBox .worksCategory {
              margin-bottom: 5px; } }
        .index section.worksArea .inner .worksTopList .worksBox .worksTit {
          font-size: 1.5rem;
          font-weight: 500;
          line-height: 1.43;
          color: #333333;
          margin-bottom: 4px; }
          @media screen and (max-width: 767px) {
            .index section.worksArea .inner .worksTopList .worksBox .worksTit {
              font-weight: 600;
              margin-bottom: 6px; } }
        .index section.worksArea .inner .worksTopList .worksBox .worksTag {
          font-size: 1.2rem;
          font-weight: 300;
          line-height: 1.8;
          color: #11a3c3; }
    .index section.worksArea .inner .btnBox {
      clear: both;
      margin: 0 auto; }
      @media screen and (max-width: 767px) {
        .index section.worksArea .inner .btnBox {
          width: 280px; } }
      .index section.worksArea .inner .btnBox a.modan:hover {
        box-shadow: 0 0 0 2px #00b2d8 inset; }
        @media screen and (max-width: 767px) {
          .index section.worksArea .inner .btnBox a.modan:hover {
            box-shadow: 0 0 0 1px #00b2d8 inset; } }
      .index section.worksArea .inner .btnBox a:before {
        background: #00b2d8; }

.index section.aboutUsArea {
  visibility: hidden;
  position: relative;
  transition: all .5s ease;
  z-index: 7;
  margin-bottom: 345px; }
  @media screen and (min-width: 768px) and (max-width: 1200px) {
    .index section.aboutUsArea {
      margin: 0; } }
  @media screen and (min-width: 426px) and (max-width: 767px) {
    .index section.aboutUsArea {
      margin: 0; } }
  @media screen and (max-width: 767px) {
    .index section.aboutUsArea {
      margin: 50px 0 0; } }
  .index section.aboutUsArea:before {
    position: absolute;
    top: 0;
    left: -53px;
    content: '';
    width: 479px;
    height: 327px;
    transform: skew(18deg);
    background: #e74291;
    transition: all .5s ease;
    opacity: .1; }
    @media screen and (max-width: 767px) {
      .index section.aboutUsArea:before {
        top: 0;
        left: -53px;
        width: 199px; } }
  .index section.aboutUsArea .inner {
    max-width: 1300px;
    margin: 0 auto;
    position: relative;
    padding: 0 20px; }
    .index section.aboutUsArea .inner:after {
      content: "";
      display: table;
      clear: both; }
    @media screen and (max-width: 767px) {
      .index section.aboutUsArea .inner {
        max-width: initial; } }
    @media screen and (max-width: 767px) {
      .index section.aboutUsArea .inner {
        margin: 0 auto;
        padding: 0 20px; } }
    .index section.aboutUsArea .inner .contents {
      width: 559px;
      position: relative;
      margin: 0 0 0 40px; }
      @media screen and (min-width: 768px) and (max-width: 1200px) {
        .index section.aboutUsArea .inner .contents {
          margin: 0 auto; } }
      @media screen and (max-width: 767px) {
        .index section.aboutUsArea .inner .contents {
          width: 100%;
          margin: 0; } }
    .index section.aboutUsArea .inner .ttl {
      visibility: hidden;
      width: 147px;
      margin: 0 0 0 30px;
      padding: 20px 0 0;
      text-align: center;
      color: #e74291;
      position: relative;
      border-top: 4px solid #e74291; }
      @media screen and (max-width: 767px) {
        .index section.aboutUsArea .inner .ttl {
          float: right;
          margin: 0 0 2px; }
          .index section.aboutUsArea .inner .ttl.windowsActive {
            margin: 0 0 10px; } }
      .index section.aboutUsArea .inner .ttl p {
        line-height: 1;
        letter-spacing: .5em;
        font-size: 1.2rem;
        white-space: nowrap; }
      .index section.aboutUsArea .inner .ttl h2 {
        padding: 0;
        line-height: 1;
        white-space: nowrap;
        margin: 64px 0 0 -21px;
        font-size: 7.2rem;
        letter-spacing: -.02em;
        transform: rotate(90deg);
        font-family: "BebasNeueRegular", sans-serif; }
        .index section.aboutUsArea .inner .ttl h2.windowsActive {
          margin: 64px 0 0 -9px; }
    .index section.aboutUsArea .inner figure {
      transition: all .5s ease;
      position: relative;
      overflow: hidden;
      margin: -21px 0 0 91px; }
      @media screen and (max-width: 767px) {
        .index section.aboutUsArea .inner figure {
          clear: both;
          margin: 0 0 0 0; }
          .index section.aboutUsArea .inner figure img {
            display: block;
            width: 100%; } }
      .index section.aboutUsArea .inner figure .txt {
        color: #fff;
        position: absolute;
        top: -38px;
        left: -95px;
        line-height: 1;
        white-space: nowrap;
        font-size: 7.2rem;
        letter-spacing: -.02em;
        transform: rotate(90deg);
        font-family: "BebasNeueRegular", sans-serif; }
        @media screen and (max-width: 767px) {
          .index section.aboutUsArea .inner figure .txt {
            position: static;
            float: right;
            margin: -59px -14px -72px 0; } }
        .index section.aboutUsArea .inner figure .txt.windowsActive {
          top: -31px;
          left: -89px; }
          @media screen and (max-width: 767px) {
            .index section.aboutUsArea .inner figure .txt.windowsActive {
              margin: -59px -20px -72px 0; } }
    .index section.aboutUsArea .inner .exTxt {
      line-height: 1.6;
      font-size: 1.6rem;
      letter-spacing: .05em;
      padding: 31px 0 50px;
      line-height: 2.3;
      display: flex;
      justify-content: flex-end;
      box-sizing: border-box; }
      @media screen and (max-width: 767px) {
        .index section.aboutUsArea .inner .exTxt {
          line-height: 1.4; } }
      @media screen and (max-width: 767px) {
        .index section.aboutUsArea .inner .exTxt {
          width: 100%;
          line-height: 2.3;
          padding: 17px 0 40px;
          display: block; } }
    .index section.aboutUsArea .inner .btnBox {
      width: 236px;
      font-size: 1.2rem;
      margin: 0 auto; }
      @media screen and (max-width: 767px) {
        .index section.aboutUsArea .inner .btnBox {
          width: 236px; } }
      @media screen and (max-width: 767px) {
        .index section.aboutUsArea .inner .btnBox a {
          width: 236px;
          max-width: 236px; } }
      .index section.aboutUsArea .inner .btnBox a.modan:hover {
        box-shadow: 0 0 0 2px #e74291 inset; }
        @media screen and (max-width: 767px) {
          .index section.aboutUsArea .inner .btnBox a.modan:hover {
            box-shadow: 0 0 0 1px #e74291 inset; } }
      .index section.aboutUsArea .inner .btnBox a:before {
        background: #e74291; }

.index section.serviceArea {
  position: absolute;
  transition: all .5s ease;
  z-index: 6;
  right: 0%;
  left: 0%; }
  .index section.serviceArea:after {
    content: "";
    display: table;
    clear: both; }
  @media screen and (min-width: 768px) and (max-width: 1200px) {
    .index section.serviceArea {
      margin: 120px 0 0;
      position: relative; } }
  @media screen and (min-width: 426px) and (max-width: 767px) {
    .index section.serviceArea {
      margin: -190px 0 0;
      position: relative; } }
  @media screen and (max-width: 767px) {
    .index section.serviceArea {
      position: relative;
      margin: 0; } }
  .index section.serviceArea:before {
    position: absolute;
    top: -566px;
    right: -53px;
    content: '';
    width: 452px;
    height: 327px;
    transform: skew(-18deg);
    background: #ffae00;
    transition: all .5s ease;
    opacity: .29; }
    @media screen and (min-width: 768px) and (max-width: 1200px) {
      .index section.serviceArea:before {
        top: 72px;
        width: 735px; } }
    @media screen and (min-width: 426px) and (max-width: 767px) {
      .index section.serviceArea:before {
        top: 289px;
        width: 444px; } }
    @media screen and (max-width: 767px) {
      .index section.serviceArea:before {
        top: 201px;
        width: 253px; } }
  .index section.serviceArea .inner {
    max-width: 1300px;
    margin: 0 auto;
    position: relative;
    margin: 0 auto;
    padding: 0 20px; }
    .index section.serviceArea .inner:after {
      content: "";
      display: table;
      clear: both; }
    @media screen and (max-width: 767px) {
      .index section.serviceArea .inner {
        max-width: initial; } }
    .index section.serviceArea .inner:after {
      content: "";
      display: table;
      clear: both; }
    @media screen and (min-width: 768px) and (max-width: 1200px) {
      .index section.serviceArea .inner {
        margin: 0;
        float: none; } }
    @media screen and (min-width: 426px) and (max-width: 767px) {
      .index section.serviceArea .inner {
        padding: 0 20px 100px;
        float: none; } }
    @media screen and (max-width: 767px) {
      .index section.serviceArea .inner {
        padding: 0 20px;
        float: none; } }
    .index section.serviceArea .inner .contents {
      width: 602px;
      float: right;
      position: relative;
      margin: -450px 0 0; }
      @media screen and (min-width: 768px) and (max-width: 1200px) {
        .index section.serviceArea .inner .contents {
          float: none;
          margin: 0 auto; } }
      @media screen and (min-width: 426px) and (max-width: 767px) {
        .index section.serviceArea .inner .contents {
          float: none;
          margin: 0 auto 130px; } }
      @media screen and (max-width: 767px) {
        .index section.serviceArea .inner .contents {
          float: none;
          width: 100%;
          margin: 50px 0 -70px; } }
    .index section.serviceArea .inner .ttl {
      visibility: hidden;
      width: 147px;
      margin: 0 0 0 30px;
      padding: 20px 0 0;
      text-align: center;
      color: #ffae00;
      border-top: 4px solid #ffae00; }
      @media screen and (max-width: 767px) {
        .index section.serviceArea .inner .ttl {
          margin: 0; } }
      .index section.serviceArea .inner .ttl p {
        line-height: 1;
        letter-spacing: .5em;
        font-size: 1.2rem;
        white-space: nowrap; }
      .index section.serviceArea .inner .ttl h2 {
        padding: 0;
        line-height: 1;
        white-space: nowrap;
        margin: 64px 0 0 -21px;
        font-size: 7.2rem;
        letter-spacing: -.02em;
        transform: rotate(90deg);
        font-family: "BebasNeueRegular", sans-serif; }
        .index section.serviceArea .inner .ttl h2.windowsActive {
          margin: 64px 0 0 -9px; }
    .index section.serviceArea .inner ul {
      transition: all .5s ease;
      margin: -71px 0 0 108px; }
      @media screen and (min-width: 426px) and (max-width: 767px) {
        .index section.serviceArea .inner ul {
          margin: -92px 21% 0 0; } }
      @media screen and (max-width: 767px) {
        .index section.serviceArea .inner ul {
          margin: 107px 89px 0 0;
          float: right; } }
      .index section.serviceArea .inner ul li {
        height: 100px;
        margin: 0 0 20px;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative; }
        @media screen and (max-width: 767px) {
          .index section.serviceArea .inner ul li {
            height: auto; } }
        .index section.serviceArea .inner ul li:last-of-type {
          margin-bottom: 0; }
        .index section.serviceArea .inner ul li:before {
          position: absolute;
          content: '';
          bottom: -20px;
          width: 2px;
          height: 20px;
          background: #ffae00;
          transition: all .5s ease; }
        .index section.serviceArea .inner ul li img {
          display: block; }
        .index section.serviceArea .inner ul li:nth-child(1) {
          width: 288px;
          margin-left: 31px; }
          @media screen and (max-width: 767px) {
            .index section.serviceArea .inner ul li:nth-child(1) {
              width: 230px; } }
          .index section.serviceArea .inner ul li:nth-child(1):before {
            left: 71px; }
          .index section.serviceArea .inner ul li:nth-child(1) img {
            width: 288px; }
            @media screen and (max-width: 767px) {
              .index section.serviceArea .inner ul li:nth-child(1) img {
                width: 100%; } }
        .index section.serviceArea .inner ul li:nth-child(2) {
          width: 292px;
          margin-left: 59px; }
          @media screen and (max-width: 767px) {
            .index section.serviceArea .inner ul li:nth-child(2) {
              width: 231px; } }
          .index section.serviceArea .inner ul li:nth-child(2):before {
            left: 43px; }
          .index section.serviceArea .inner ul li:nth-child(2) img {
            width: 292px; }
            @media screen and (max-width: 767px) {
              .index section.serviceArea .inner ul li:nth-child(2) img {
                width: 100%; } }
        .index section.serviceArea .inner ul li:nth-child(3) {
          width: 323px; }
          @media screen and (max-width: 767px) {
            .index section.serviceArea .inner ul li:nth-child(3) {
              width: 258px;
              margin-left: 14px; } }
          .index section.serviceArea .inner ul li:nth-child(3):before {
            left: 102px; }
            @media screen and (max-width: 767px) {
              .index section.serviceArea .inner ul li:nth-child(3):before {
                left: 88px; } }
          .index section.serviceArea .inner ul li:nth-child(3) img {
            width: 323px; }
            @media screen and (max-width: 767px) {
              .index section.serviceArea .inner ul li:nth-child(3) img {
                width: 100%; } }
        .index section.serviceArea .inner ul li:nth-child(4) {
          width: 355px;
          margin-left: 31px; }
          @media screen and (max-width: 767px) {
            .index section.serviceArea .inner ul li:nth-child(4) {
              width: 283px;
              margin-left: 0; } }
          .index section.serviceArea .inner ul li:nth-child(4):before {
            display: none; }
          .index section.serviceArea .inner ul li:nth-child(4) img {
            width: 355px; }
            @media screen and (max-width: 767px) {
              .index section.serviceArea .inner ul li:nth-child(4) img {
                width: 100%; } }
    .index section.serviceArea .inner .exTxt {
      line-height: 1.6;
      font-size: 1.6rem;
      letter-spacing: .05em;
      clear: both;
      padding: 26px 21px 22px 0;
      line-height: 2.3;
      display: flex;
      justify-content: flex-end;
      box-sizing: border-box; }
      @media screen and (max-width: 767px) {
        .index section.serviceArea .inner .exTxt {
          line-height: 1.4; } }
      @media screen and (max-width: 767px) {
        .index section.serviceArea .inner .exTxt {
          width: 100%;
          line-height: 2.3;
          padding: 17px 0 40px; } }
    .index section.serviceArea .inner .btnBox {
      width: 236px;
      font-size: 1.2rem;
      margin: 0 0 0 110px; }
      @media screen and (min-width: 768px) and (max-width: 1200px) {
        .index section.serviceArea .inner .btnBox {
          margin: 0 auto; } }
      @media screen and (max-width: 767px) {
        .index section.serviceArea .inner .btnBox {
          width: 236px;
          margin: 0 auto; } }
      @media screen and (max-width: 767px) {
        .index section.serviceArea .inner .btnBox a {
          width: 236px;
          max-width: 236px; } }
      .index section.serviceArea .inner .btnBox a.modan:hover {
        box-shadow: 0 0 0 2px #ffae00 inset; }
        @media screen and (max-width: 767px) {
          .index section.serviceArea .inner .btnBox a.modan:hover {
            box-shadow: 0 0 0 1px #ffae00 inset; } }
      .index section.serviceArea .inner .btnBox a:before {
        background: #ffae00; }

.index section.memberArea {
  visibility: hidden;
  clear: both;
  position: relative;
  background: #fff;
  z-index: 1; }
  .index section.memberArea:before {
    position: absolute;
    top: 0;
    right: -53px;
    content: '';
    width: 452px;
    height: 327px;
    transform: skew(-18deg);
    background: #a5d404;
    transition: all .5s ease;
    opacity: .15; }
    @media screen and (max-width: 767px) {
      .index section.memberArea:before {
        top: 170px;
        width: 253px; } }
  .index section.memberArea .inner {
    max-width: 1300px;
    margin: 0 auto;
    position: relative;
    padding: 0 20px; }
    .index section.memberArea .inner:after {
      content: "";
      display: table;
      clear: both; }
    @media screen and (max-width: 767px) {
      .index section.memberArea .inner {
        max-width: initial; } }
    @media screen and (max-width: 767px) {
      .index section.memberArea .inner {
        padding: 0 20px; } }
    .index section.memberArea .inner .ttl {
      width: 147px;
      margin: 0 0 0 30px;
      padding: 20px 0 0;
      text-align: center;
      color: #a5d404;
      position: relative;
      border-top: 4px solid #a5d404; }
      @media screen and (max-width: 767px) {
        .index section.memberArea .inner .ttl {
          margin: 0; } }
      .index section.memberArea .inner .ttl p {
        line-height: 1;
        letter-spacing: .5em;
        font-size: 1.2rem;
        white-space: nowrap; }
      .index section.memberArea .inner .ttl h2 {
        padding: 0;
        line-height: 1;
        white-space: nowrap;
        margin: 64px 0 0 -21px;
        font-size: 7.2rem;
        letter-spacing: -.02em;
        transform: rotate(90deg);
        font-family: "BebasNeueRegular", sans-serif; }
        .index section.memberArea .inner .ttl h2.windowsActive {
          margin: 64px 0 0 -9px; }
    .index section.memberArea .inner .btnBox {
      width: 236px;
      font-size: 1.2rem;
      margin: 0;
      float: right; }
      @media screen and (max-width: 767px) {
        .index section.memberArea .inner .btnBox {
          width: 236px;
          margin: 0 auto; } }
      @media screen and (max-width: 767px) {
        .index section.memberArea .inner .btnBox a {
          width: 236px;
          max-width: 236px; } }
      .index section.memberArea .inner .btnBox a.modan:hover {
        box-shadow: 0 0 0 2px #ffae00 inset; }
        @media screen and (max-width: 767px) {
          .index section.memberArea .inner .btnBox a.modan:hover {
            box-shadow: 0 0 0 1px #ffae00 inset; } }
      .index section.memberArea .inner .btnBox a:before {
        background: #ffae00; }

.index .recruitArea {
  clear: both;
  position: relative;
  z-index: 10; }
  @media screen and (max-width: 767px) {
    .index .recruitArea {
      margin: 0 0 79px; } }
  .index .recruitArea .topInner {
    padding: 0 20px;
    max-width: 1300px;
    margin: 0 auto;
    overflow: hidden;
    height: 37px;
    position: relative;
    z-index: 10;
    width: 100%; }
    .index .recruitArea .topInner:after {
      content: "";
      display: table;
      clear: both; }
    @media screen and (max-width: 767px) {
      .index .recruitArea .topInner {
        max-width: initial; } }
    @media screen and (max-width: 767px) {
      .index .recruitArea .topInner {
        height: 21px; }
        .index .recruitArea .topInner.windowsActive {
          height: 37px; } }
    .index .recruitArea .topInner .txt {
      transition: all .5s ease;
      position: absolute;
      top: -5px;
      left: 154px;
      line-height: 1;
      white-space: nowrap;
      font-size: 13rem;
      letter-spacing: -.01em;
      font-family: "BebasNeueRegular", sans-serif; }
      @media screen and (min-width: 768px) and (max-width: 1200px) {
        .index .recruitArea .topInner .txt {
          left: 54px; } }
      @media screen and (max-width: 767px) {
        .index .recruitArea .topInner .txt {
          font-size: 6.5rem;
          top: 0;
          left: 20px; } }
      .index .recruitArea .topInner .txt.windowsActive {
        top: -18px; }
        @media screen and (max-width: 767px) {
          .index .recruitArea .topInner .txt.windowsActive {
            top: 6px; } }
  .index .recruitArea .photoBox {
    height: 398px;
    background: url(/images/index/bg_recruit_pc.jpg) center center no-repeat;
    background-size: cover; }
    @media screen and (max-width: 767px) {
      .index .recruitArea .photoBox {
        height: 199px;
        background: url(/images/index/bg_recruit_sp.jpg) center top no-repeat;
        background-size: cover; } }
    .index .recruitArea .photoBox .inner {
      max-width: 1300px;
      margin: 0 auto;
      position: relative;
      padding: 0 20px; }
      .index .recruitArea .photoBox .inner:after {
        content: "";
        display: table;
        clear: both; }
      @media screen and (max-width: 767px) {
        .index .recruitArea .photoBox .inner {
          max-width: initial; } }
      .index .recruitArea .photoBox .inner h2 {
        float: left;
        transition: all .5s ease;
        margin: -43px 0 0 134px;
        line-height: 1;
        color: #fff;
        white-space: nowrap;
        font-size: 13rem;
        letter-spacing: -.01em;
        font-family: "BebasNeueRegular", sans-serif; }
        @media screen and (min-width: 768px) and (max-width: 1200px) {
          .index .recruitArea .photoBox .inner h2 {
            margin: -37px 0 0 34px; } }
        @media screen and (max-width: 767px) {
          .index .recruitArea .photoBox .inner h2 {
            font-size: 6.5rem;
            margin: -22px 0 0; } }
        .index .recruitArea .photoBox .inner h2.windowsActive {
          margin-top: -55px; }
          @media screen and (max-width: 767px) {
            .index .recruitArea .photoBox .inner h2.windowsActive {
              margin-top: -34px; } }
      .index .recruitArea .photoBox .inner .right {
        float: right;
        transition: all .5s ease;
        margin: 114px 133px 0 0; }
        @media screen and (min-width: 768px) and (max-width: 1200px) {
          .index .recruitArea .photoBox .inner .right {
            margin: 114px 34px 0 0; } }
        @media screen and (max-width: 767px) {
          .index .recruitArea .photoBox .inner .right {
            display: none; } }
        .index .recruitArea .photoBox .inner .right p {
          line-height: 1.6;
          font-size: 1.6rem;
          letter-spacing: .05em;
          color: #fff;
          font-size: 1.8rem;
          padding: 0 0 32px;
          line-height: 2.1;
          text-align: right;
          white-space: nowrap;
          font-weight: 700; }
          @media screen and (max-width: 767px) {
            .index .recruitArea .photoBox .inner .right p {
              line-height: 1.4; } }
          @media screen and (min-width: 768px) and (max-width: 1200px) {
            .index .recruitArea .photoBox .inner .right p {
              font-size: 1.6rem; } }
        .index .recruitArea .photoBox .inner .right .btnBox {
          width: 280px;
          margin: 0;
          float: right; }
          @media screen and (max-width: 767px) {
            .index .recruitArea .photoBox .inner .right .btnBox {
              margin: 0 auto; } }
          .index .recruitArea .photoBox .inner .right .btnBox a {
            position: relative;
            color: #fff;
            background: rgba(43, 65, 98, 0.25);
            box-shadow: 0 0 0 2px #fff inset; }
            @media screen and (max-width: 767px) {
              .index .recruitArea .photoBox .inner .right .btnBox a {
                width: 236px;
                max-width: 236px; } }
            .index .recruitArea .photoBox .inner .right .btnBox a.modan:hover {
              box-shadow: 0 0 0 2px #525863 inset; }
              @media screen and (max-width: 767px) {
                .index .recruitArea .photoBox .inner .right .btnBox a.modan:hover {
                  box-shadow: 0 0 0 1px #525863 inset; } }
            .index .recruitArea .photoBox .inner .right .btnBox a:before {
              background: #525863; }
            .index .recruitArea .photoBox .inner .right .btnBox a:after {
              position: absolute;
              content: '';
              margin: auto 0;
              top: 23px;
              background: url(/images/common/icon_long_btn_arrow_w.png) no-repeat;
              background-size: 58px 7px;
              width: 58px;
              height: 7px;
              right: -11px;
              transition: all .5s ease; }
            .index .recruitArea .photoBox .inner .right .btnBox a:hover:after {
              right: -30px;
              transition-timing-function: cubic-bezier(0.52, 1.64, 0.37, 0.66); }
  .index .recruitArea .underExBox {
    display: none;
    padding: 0 20px;
    margin: 15px 0 0; }
    .index .recruitArea .underExBox p {
      line-height: 1.6;
      font-size: 1.6rem;
      letter-spacing: .05em;
      font-size: 1.5rem;
      font-weight: 700;
      margin: 0 0 32px;
      text-align: right;
      color: #363a42;
      line-height: 2 !important; }
      @media screen and (max-width: 767px) {
        .index .recruitArea .underExBox p {
          line-height: 1.4; } }
      @media screen and (max-width: 767px) {
        .index .recruitArea .underExBox p {
          font-size: 1.4rem; } }
    .index .recruitArea .underExBox .btnBox {
      margin: 0 auto;
      width: 280px !important; }
      .index .recruitArea .underExBox .btnBox a {
        color: #363a42;
        box-shadow: 0 0 0 1px #363a42 inset; }
        .index .recruitArea .underExBox .btnBox a.modan:hover {
          box-shadow: 0 0 0 1px #525863 inset; }
          @media screen and (max-width: 767px) {
            .index .recruitArea .underExBox .btnBox a.modan:hover {
              box-shadow: 0 0 0 1px #525863 inset; } }
        .index .recruitArea .underExBox .btnBox a:before {
          background: #525863; }
        .index .recruitArea .underExBox .btnBox a:after {
          background: url(/images/common/icon_long_btn_arrow_g.png) no-repeat !important;
          background-size: 58px 7px !important;
          width: 58px !important;
          height: 7px !important;
          right: -11px !important;
          top: 23px;
          transition: all .5s ease !important; }
        .index .recruitArea .underExBox .btnBox a:hover:after {
          right: -30px !important;
          transition-timing-function: cubic-bezier(0.52, 1.64, 0.37, 0.66) !important; }
        .index .recruitArea .underExBox .btnBox a.spNotHover:before {
          display: none; }
        @media screen and (max-width: 767px) {
          .index .recruitArea .underExBox .btnBox a.spNotHover:hover:after {
            right: -11px !important; } }
    @media screen and (max-width: 767px) {
      .index .recruitArea .underExBox {
        display: block; } }

/* ==========================================================================

ABOUT US

========================================================================== */
.aboutUs section.ideaArea {
  padding-top: 20px; }
  .aboutUs section.ideaArea .inner {
    max-width: 1300px;
    margin: 0 auto;
    padding: 0 20px 80px; }
    .aboutUs section.ideaArea .inner:after {
      content: "";
      display: table;
      clear: both; }
    @media screen and (max-width: 767px) {
      .aboutUs section.ideaArea .inner {
        max-width: initial; } }
    @media screen and (max-width: 767px) {
      .aboutUs section.ideaArea .inner {
        padding: 0 20px 40px; } }
    .aboutUs section.ideaArea .inner h3 {
      text-align: center;
      font-size: 2.5rem;
      line-height: 2.3;
      padding: 60px 0 120px;
      letter-spacing: .2em; }
      @media screen and (max-width: 767px) {
        .aboutUs section.ideaArea .inner h3 {
          padding: 30px 0 80px;
          line-height: 1.8;
          font-size: 2rem; } }
    .aboutUs section.ideaArea .inner h4 {
      text-align: center;
      font-size: 3rem;
      line-height: 1;
      letter-spacing: .1em;
      font-family: "BebasNeueBold", sans-serif; }
    .aboutUs section.ideaArea .inner p {
      text-align: center;
      font-size: 1.6rem;
      line-height: 2.6;
      padding: 35px 0 120px; }
      @media screen and (max-width: 767px) {
        .aboutUs section.ideaArea .inner p {
          line-height: 2.4;
          text-align: left;
          padding: 25px 0 80px; } }
      .aboutUs section.ideaArea .inner p:last-of-type {
        padding-bottom: 0; }

.aboutUs section.infoArea {
  background: #f7f7f7; }
  .aboutUs section.infoArea .inner {
    max-width: 1300px;
    margin: 0 auto;
    position: relative;
    padding: 80px 20px; }
    .aboutUs section.infoArea .inner:after {
      content: "";
      display: table;
      clear: both; }
    @media screen and (max-width: 767px) {
      .aboutUs section.infoArea .inner {
        max-width: initial; } }
    @media screen and (max-width: 767px) {
      .aboutUs section.infoArea .inner {
        padding: 40px 20px; } }
    .aboutUs section.infoArea .inner table {
      width: 90%;
      margin: 70px auto 0;
      font-size: 1.5rem; }
      @media screen and (max-width: 767px) {
        .aboutUs section.infoArea .inner table {
          width: 100%;
          margin: 35px 0 0; } }
      .aboutUs section.infoArea .inner table tr {
        border-bottom: 1px solid #f7f7f7; }
        .aboutUs section.infoArea .inner table tr:last-of-type {
          border-bottom: none; }
      .aboutUs section.infoArea .inner table th {
        padding: 20px; }
        @media screen and (max-width: 767px) {
          .aboutUs section.infoArea .inner table th {
            width: 100%;
            font-size: 1.4rem;
            font-weight: 700;
            padding: 15px 20px 0 20px; } }
      .aboutUs section.infoArea .inner table td {
        padding: 20px; }
        @media screen and (max-width: 767px) {
          .aboutUs section.infoArea .inner table td {
            font-size: 1.4rem;
            padding: 5px 20px 15px 20px; } }

.aboutUs section.mainClientArea .inner {
  max-width: 1300px;
  margin: 0 auto;
  position: relative;
  padding: 80px 20px; }
  .aboutUs section.mainClientArea .inner:after {
    content: "";
    display: table;
    clear: both; }
  @media screen and (max-width: 767px) {
    .aboutUs section.mainClientArea .inner {
      max-width: initial; } }
  @media screen and (max-width: 767px) {
    .aboutUs section.mainClientArea .inner {
      padding: 40px 20px; } }

.aboutUs section.googleMapArea {
  margin: 0 0 60px;
  background: #f7f7f7;
  position: relative;
  padding-top: 35%; }
  @media screen and (max-width: 767px) {
    .aboutUs section.googleMapArea {
      margin: 0 0 30px;
      padding-top: 100%; } }
  .aboutUs section.googleMapArea .btnBox {
    position: absolute;
    top: 10px;
    left: 10px;
    z-index: 2;
    width: 160px;
    border-radius: 2px;
    box-shadow: rgba(0, 0, 0, 0.3) 0px 1px 4px -1px; }
    @media screen and (max-width: 767px) {
      .aboutUs section.googleMapArea .btnBox {
        width: 160px; } }
    .aboutUs section.googleMapArea .btnBox a {
      padding: 0 0 0 31px;
      height: 42px;
      line-height: 42px;
      font-size: 1.2rem;
      box-shadow: none;
      border-radius: 2px; }
      @media screen and (max-width: 767px) {
        .aboutUs section.googleMapArea .btnBox a {
          width: 160px;
          box-shadow: none; } }
      .aboutUs section.googleMapArea .btnBox a.modan:hover {
        box-shadow: none; }
        @media screen and (max-width: 767px) {
          .aboutUs section.googleMapArea .btnBox a.modan:hover {
            box-shadow: none; } }
      .aboutUs section.googleMapArea .btnBox a:after {
        left: 10px;
        top: 9px;
        width: 23px;
        height: 23px;
        background: url(/images/about_us/icon_map.svg) no-repeat;
        background-size: 23px 23px; }
      .aboutUs section.googleMapArea .btnBox a.modan:hover:after {
        content: '';
        width: 23px;
        height: 23px;
        background: url(/images/about_us/icon_map_w.svg) no-repeat;
        background-size: 23px 23px; }
      .aboutUs section.googleMapArea .btnBox a.spNotHover:hover {
        box-shadow: none !important; }
      .aboutUs section.googleMapArea .btnBox a.spNotHover:hover:after {
        content: '';
        width: 23px !important;
        height: 23px !important;
        background: url(/images/about_us/icon_map.svg) no-repeat !important;
        background-size: 23px 23px !important; }
  .aboutUs section.googleMapArea #gMap {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0; }

/* ==========================================================================

MEMBER

========================================================================== */
.member section.memberMainArea .inner {
  max-width: 1300px;
  margin: 0 auto;
  position: relative;
  padding: 80px 20px; }
  .member section.memberMainArea .inner:after {
    content: "";
    display: table;
    clear: both; }
  @media screen and (max-width: 767px) {
    .member section.memberMainArea .inner {
      max-width: initial; } }
  @media screen and (max-width: 767px) {
    .member section.memberMainArea .inner {
      padding: 40px 20px; } }

/* ==========================================================================

SERVICE

========================================================================== */
.service section.serviceArea {
  padding-top: 20px; }
  .service section.serviceArea .inner {
    max-width: 1300px;
    margin: 0 auto;
    padding: 0 20px 80px; }
    .service section.serviceArea .inner:after {
      content: "";
      display: table;
      clear: both; }
    @media screen and (max-width: 767px) {
      .service section.serviceArea .inner {
        max-width: initial; } }
    @media screen and (max-width: 767px) {
      .service section.serviceArea .inner {
        padding: 0 20px 40px; } }
    .service section.serviceArea .inner li {
      float: left;
      width: 48.11320%;
      text-align: center;
      overflow: hidden;
      border-radius: 4px;
      margin: 0 3.666% 3.666% 0; }
      .service section.serviceArea .inner li:nth-child(2n) {
        margin-right: 0; }
      .service section.serviceArea .inner li:nth-child(3), .service section.serviceArea .inner li:nth-child(4) {
        margin-bottom: 0; }
      @media screen and (min-width: 768px) and (max-width: 1200px) {
        .service section.serviceArea .inner li {
          float: none;
          width: 100%;
          margin: 0 0 30px;
          height: auto !important; }
          .service section.serviceArea .inner li:nth-child(3) {
            margin: 0 0 30px; } }
      @media screen and (max-width: 767px) {
        .service section.serviceArea .inner li {
          float: none;
          width: 100%;
          margin: 0 0 30px;
          height: auto !important; }
          .service section.serviceArea .inner li:nth-child(3) {
            margin: 0 0 30px; } }
      .service section.serviceArea .inner li h3 {
        display: inline-block;
        letter-spacing: .15em;
        margin: 30px 0;
        font-weight: 600; }
        @media screen and (max-width: 767px) {
          .service section.serviceArea .inner li h3 {
            margin: 20px 0; } }
        .service section.serviceArea .inner li h3 span {
          margin: 10px 0 0;
          line-height: 1;
          display: block;
          font-size: 3rem;
          letter-spacing: .1em;
          font-family: "BebasNeueRegular", sans-serif;
          transition: opacity 1s 0.3s, transform 1s 0.3s cubic-bezier(0.215, 0.61, 0.355, 1); }
      .service section.serviceArea .inner li figure {
        padding: 20px;
        position: relative;
        text-align: center;
        transform: perspective(1px) translateZ(0);
        transition-property: color;
        transition-duration: .3s; }
        .service section.serviceArea .inner li figure:before {
          content: '';
          position: absolute;
          z-index: -1;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          background: #fafafa;
          transform: scale(0);
          transition-property: transform;
          transition-duration: .3s;
          transition-timing-function: ease-out; }
        .service section.serviceArea .inner li figure.animeStart:before {
          transform: scale(1); }
        .service section.serviceArea .inner li figure svg {
          width: 75.45%;
          height: 220px;
          opacity: 0; }
          @media screen and (max-width: 767px) {
            .service section.serviceArea .inner li figure svg {
              width: 80%;
              height: auto; } }
        .service section.serviceArea .inner li figure #directionSvg .directionOffset01 {
          stop-color: #7F7F80;
          stop-opacity: .25; }
        .service section.serviceArea .inner li figure #directionSvg .directionOffset02 {
          stop-color: #7F7F80;
          stop-opacity: .12; }
        .service section.serviceArea .inner li figure #directionSvg .directionOffset03 {
          stop-color: #7F7F80;
          stop-opacity: .1; }
        .service section.serviceArea .inner li figure #directionSvg .directionOffset04 {
          stop-color: #B2B2B2;
          stop-opacity: .25; }
        .service section.serviceArea .inner li figure #directionSvg .directionOffset05 {
          stop-color: #B2B2B2;
          stop-opacity: .1; }
        .service section.serviceArea .inner li figure #directionSvg .directionOffset06 {
          stop-color: #B2B2B2;
          stop-opacity: .2; }
        .service section.serviceArea .inner li figure #directionSvg.animeStart {
          opacity: 1; }
          .service section.serviceArea .inner li figure #directionSvg.animeStart .direction0 {
            animation: show 2s both;
            animation-delay: 1s;
            fill: #DFDFDF;
            position: relative; }
          .service section.serviceArea .inner li figure #directionSvg.animeStart .direction1 {
            animation: show 3s both;
            animation-delay: .5s;
            fill: url(#direction1); }
          .service section.serviceArea .inner li figure #directionSvg.animeStart .direction2 {
            animation: show .6s both;
            animation-delay: .5s;
            fill: #FFFFFF; }
          .service section.serviceArea .inner li figure #directionSvg.animeStart .direction3 {
            animation: show 2s both;
            animation-delay: 1s;
            fill: url(#direction2); }
          .service section.serviceArea .inner li figure #directionSvg.animeStart .direction4 {
            animation: show 3s both;
            animation-delay: 1.8s;
            fill: #18A6C9; }
          .service section.serviceArea .inner li figure #directionSvg.animeStart .direction5 {
            animation: show 3s both;
            animation-delay: 1s;
            fill: #BCBCBC; }
          .service section.serviceArea .inner li figure #directionSvg.animeStart .direction6 {
            animation: show 2s both;
            animation-delay: 1.8s;
            fill: #7ec6d8; }
          .service section.serviceArea .inner li figure #directionSvg.animeStart .direction7 {
            animation: show 2s both;
            animation-delay: 1s;
            fill: #18A6C9; }
        .service section.serviceArea .inner li figure #designSvg .designOffset01 {
          stop-color: #7F7F80;
          stop-opacity: .25; }
        .service section.serviceArea .inner li figure #designSvg .designOffset02 {
          stop-color: #7F7F80;
          stop-opacity: .12; }
        .service section.serviceArea .inner li figure #designSvg .designOffset03 {
          stop-color: #7F7F80;
          stop-opacity: .1; }
        .service section.serviceArea .inner li figure #designSvg.animeStart {
          opacity: 1; }
          .service section.serviceArea .inner li figure #designSvg.animeStart .design0 {
            animation: show 2s both;
            animation-delay: .5s;
            fill: url(#design1); }
          .service section.serviceArea .inner li figure #designSvg.animeStart .design1 {
            animation: show 2s both;
            animation-delay: 1s;
            fill: url(#design2); }
          .service section.serviceArea .inner li figure #designSvg.animeStart .design2 {
            animation: show 2s both;
            animation-delay: 1s;
            fill: #FFFFFF; }
          .service section.serviceArea .inner li figure #designSvg.animeStart .design4 {
            animation: show 2s both;
            animation-delay: .5s;
            fill: #D64287; }
          .service section.serviceArea .inner li figure #designSvg.animeStart .design8 {
            animation: show 2s both;
            animation-delay: .5s;
            fill: #B43377; }
          .service section.serviceArea .inner li figure #designSvg.animeStart .design5 {
            animation: show 2s both;
            animation-delay: 2s;
            fill: url(#design3); }
          .service section.serviceArea .inner li figure #designSvg.animeStart .design6 {
            animation: show 2s both;
            animation-delay: 2s;
            fill: url(#design4); }
          .service section.serviceArea .inner li figure #designSvg.animeStart .design7 {
            animation: show 2s both;
            animation-delay: 2s;
            fill: url(#design5); }
          .service section.serviceArea .inner li figure #designSvg.animeStart .design9 {
            opacity: .2;
            fill: none;
            enable-background: new; }
          .service section.serviceArea .inner li figure #designSvg.animeStart .design10 {
            animation: show 2s both;
            animation-delay: 2s;
            fill: #D64287; }
          .service section.serviceArea .inner li figure #designSvg.animeStart .design3 {
            animation: show 1s both;
            animation-delay: 2.5s;
            fill: #9E9E9F; }
        .service section.serviceArea .inner li figure #programmingSvg .programmingOffset01 {
          stop-color: #7F7F80;
          stop-opacity: .25; }
        .service section.serviceArea .inner li figure #programmingSvg .programmingOffset02 {
          stop-color: #7F7F80;
          stop-opacity: .12; }
        .service section.serviceArea .inner li figure #programmingSvg .programmingOffset03 {
          stop-color: #7F7F80;
          stop-opacity: .1; }
        .service section.serviceArea .inner li figure #programmingSvg.animeStart {
          opacity: 1; }
          .service section.serviceArea .inner li figure #programmingSvg.animeStart .programming0 {
            animation: show 1s both;
            animation-delay: .5s;
            fill: url(#programming1); }
          .service section.serviceArea .inner li figure #programmingSvg.animeStart .programming1 {
            animation: show 1s both;
            animation-delay: .5s;
            fill: #FFFFFF; }
          .service section.serviceArea .inner li figure #programmingSvg.animeStart .programming6 {
            animation: show 1s both;
            animation-delay: 2s;
            fill: #F4A814; }
          .service section.serviceArea .inner li figure #programmingSvg.animeStart .programming7 {
            animation: show 1s both;
            animation-delay: 1s;
            fill: url(#programming4); }
          .service section.serviceArea .inner li figure #programmingSvg.animeStart .programming8 {
            animation: show 1s both;
            animation-delay: 2s;
            fill: url(#programming5); }
          .service section.serviceArea .inner li figure #programmingSvg.animeStart .programming11 {
            animation: show 1s both;
            animation-delay: 1s;
            fill: #F4A814; }
          .service section.serviceArea .inner li figure #programmingSvg.animeStart .programming2 {
            animation: show 1s both;
            animation-delay: 1.8s;
            fill: #B4B4B5; }
          .service section.serviceArea .inner li figure #programmingSvg.animeStart .programming3 {
            animation: show 1s both;
            animation-delay: 1.8s;
            fill: url(#programming2); }
          .service section.serviceArea .inner li figure #programmingSvg.animeStart .programming4 {
            animation: show 1s both;
            animation-delay: 1.8s;
            fill: #F4F4F4; }
          .service section.serviceArea .inner li figure #programmingSvg.animeStart .programming5 {
            animation: show 1s both;
            animation-delay: 1.3s;
            fill: url(#programming3); }
          .service section.serviceArea .inner li figure #programmingSvg.animeStart .programming9 {
            animation: show 1s both;
            animation-delay: 1.3s;
            fill: #FFFFFF; }
          .service section.serviceArea .inner li figure #programmingSvg.animeStart .programming10 {
            animation: show 1s both;
            animation-delay: 1.3s;
            fill: #F4A814; }
          .service section.serviceArea .inner li figure #programmingSvg.animeStart .programming12 {
            animation: show 1s both;
            animation-delay: 1.3s;
            fill: #F4F4F4; }
          .service section.serviceArea .inner li figure #programmingSvg.animeStart .programming13 {
            animation: show 1.2s both;
            animation-delay: 2s;
            fill: #FFFFFF; }
          .service section.serviceArea .inner li figure #programmingSvg.animeStart .programming14 {
            opacity: .5; }
        .service section.serviceArea .inner li figure #systemServerSvg .systemServer3 {
          opacity: .7; }
        .service section.serviceArea .inner li figure #systemServerSvg .systemServer4 {
          opacity: .6; }
        .service section.serviceArea .inner li figure #systemServerSvg .systemServer5 {
          opacity: .5; }
        .service section.serviceArea .inner li figure #systemServerSvg .systemServer6 {
          opacity: .4; }
        .service section.serviceArea .inner li figure #systemServerSvg .systemServer7 {
          opacity: .3; }
        .service section.serviceArea .inner li figure #systemServerSvg .systemServer16 {
          opacity: .2;
          fill: #FFFFFF;
          enable-background: new; }
        .service section.serviceArea .inner li figure #systemServerSvg .systemServerOffset01 {
          stop-color: #7F7F80;
          stop-opacity: .25; }
        .service section.serviceArea .inner li figure #systemServerSvg .systemServerOffset02 {
          stop-color: #7F7F80;
          stop-opacity: .12; }
        .service section.serviceArea .inner li figure #systemServerSvg .systemServerOffset03 {
          stop-color: #7F7F80;
          stop-opacity: .1; }
        .service section.serviceArea .inner li figure #systemServerSvg.animeStart {
          opacity: 1; }
          .service section.serviceArea .inner li figure #systemServerSvg.animeStart .systemServer0 {
            animation: show .8s both;
            animation-delay: .5s;
            fill: url(#systemServer1); }
          .service section.serviceArea .inner li figure #systemServerSvg.animeStart .systemServer1 {
            animation: show .8s both;
            animation-delay: .5s;
            fill: #FFFFFF; }
          .service section.serviceArea .inner li figure #systemServerSvg.animeStart .systemServer15 {
            animation: show .8s both;
            animation-delay: .5s;
            fill: url(#systemServer6); }
          .service section.serviceArea .inner li figure #systemServerSvg.animeStart .systemServer2 {
            animation: show 1s both;
            animation-delay: 1s;
            fill: #85CABC; }
          .service section.serviceArea .inner li figure #systemServerSvg.animeStart .systemServer8 {
            animation: show 1s both;
            animation-delay: 1s;
            fill: #0DAC67; }
          .service section.serviceArea .inner li figure #systemServerSvg.animeStart .systemServer9 {
            animation: show 1s both;
            animation-delay: 1s;
            fill: url(#systemServer2); }
          .service section.serviceArea .inner li figure #systemServerSvg.animeStart .systemServer10 {
            animation: show 1s both;
            animation-delay: 1s;
            fill: url(#systemServer3); }
          .service section.serviceArea .inner li figure #systemServerSvg.animeStart .systemServer11 {
            animation: show 1s both;
            animation-delay: 1s;
            fill: url(#systemServer4); }
          .service section.serviceArea .inner li figure #systemServerSvg.animeStart .systemServer18 {
            animation: show .8s both;
            animation-delay: 1s;
            fill: #F4F4F4; }
          .service section.serviceArea .inner li figure #systemServerSvg.animeStart .systemServer17 {
            animation: show 2s both;
            animation-delay: 2.2s;
            fill: #0DAC67; }
          .service section.serviceArea .inner li figure #systemServerSvg.animeStart .systemServer12 {
            animation: show .8s both;
            animation-delay: 1.5s;
            fill: #B4B4B5; }
          .service section.serviceArea .inner li figure #systemServerSvg.animeStart .systemServer13 {
            animation: show .8s both;
            animation-delay: 1.5s;
            fill: url(#systemServer5); }
          .service section.serviceArea .inner li figure #systemServerSvg.animeStart .systemServer14 {
            animation: show .8s both;
            animation-delay: 1.5s;
            fill: #F4F4F4; }
      .service section.serviceArea .inner li p {
        text-align: left;
        font-size: 1.6rem;
        line-height: 1.7;
        padding: 30px 0; }
        @media screen and (max-width: 767px) {
          .service section.serviceArea .inner li p {
            padding: 20px 0; } }
      .service section.serviceArea .inner li.direction h3 {
        color: #00b2d8; }
      .service section.serviceArea .inner li.design h3 {
        color: #e74291; }
      .service section.serviceArea .inner li.programming h3 {
        color: #ffae00; }
      .service section.serviceArea .inner li.systemServer h3 {
        color: #4ea96d; }
    .service section.serviceArea .inner .btnBox {
      clear: both;
      margin: 0 auto;
      padding: 80px 0 0; }
      @media screen and (max-width: 767px) {
        .service section.serviceArea .inner .btnBox {
          width: 280px;
          padding: 40px 0 0; } }

.service section.specialtyArea {
  background: #f7f7f7; }
  .service section.specialtyArea .inner {
    max-width: 1300px;
    margin: 0 auto;
    padding: 0 20px 60px; }
    .service section.specialtyArea .inner:after {
      content: "";
      display: table;
      clear: both; }
    @media screen and (max-width: 767px) {
      .service section.specialtyArea .inner {
        max-width: initial; } }
    @media screen and (max-width: 767px) {
      .service section.specialtyArea .inner {
        padding: 0 20px 30px; } }

/* ==========================================================================

SERVICE

========================================================================== */
.works section.worksArea {
  padding-top: 20px; }
  .works section.worksArea .inner {
    max-width: 1300px;
    margin: 0 auto;
    padding: 0 20px 80px; }
    .works section.worksArea .inner:after {
      content: "";
      display: table;
      clear: both; }
    @media screen and (max-width: 767px) {
      .works section.worksArea .inner {
        max-width: initial; } }
    @media screen and (max-width: 767px) {
      .works section.worksArea .inner {
        padding: 0 0 120px; } }
    .works section.worksArea .inner .worksLeadTxt {
      font-size: 1.8rem;
      font-weight: 500;
      line-height: 1.78;
      letter-spacing: 0.9px;
      text-align: center;
      color: #333333;
      margin-bottom: 80px; }
      @media screen and (max-width: 767px) {
        .works section.worksArea .inner .worksLeadTxt {
          padding: 0 20px 0; } }
    .works section.worksArea .inner .worksNav {
      display: flex;
      justify-content: space-between;
      align-content: center;
      padding: 0 30px 20px;
      margin-bottom: 45px;
      border-bottom: solid 1px #dddddd; }
      @media screen and (max-width: 767px) {
        .works section.worksArea .inner .worksNav {
          padding: 0 20px 0;
          margin-bottom: 90px;
          border-bottom: none;
          flex-wrap: wrap;
          justify-content: center; } }
    .works section.worksArea .inner .worksNavMenu {
      display: flex; }
      @media screen and (max-width: 767px) {
        .works section.worksArea .inner .worksNavMenu {
          justify-content: space-evenly;
          padding-bottom: 40px;
          margin-bottom: 40px;
          border-bottom: solid 1px #dddddd;
          width: 100%; } }
    .works section.worksArea .inner .worksNavList {
      margin-right: 30px; }
      @media screen and (max-width: 767px) {
        .works section.worksArea .inner .worksNavList {
          margin-right: 0; } }
      .works section.worksArea .inner .worksNavList a {
        font-size: 2.2rem;
        font-family: "BebasNeueRegular", sans-serif;
        line-height: 1.45;
        letter-spacing: 1.76px;
        color: #888888;
        transition: all .5s ease; }
        .works section.worksArea .inner .worksNavList a:hover {
          opacity: 0.7; }
      .works section.worksArea .inner .worksNavList.active a {
        font-size: 2.6rem;
        font-weight: bold;
        line-height: 1.23;
        letter-spacing: 2.08px;
        color: #01b2d8; }
        .works section.worksArea .inner .worksNavList.active a::after {
          content: "";
          display: block;
          width: 5px;
          height: 5px;
          margin: 3px auto 0;
          background-color: #01b2d8;
          border-radius: 50px; }
    .works section.worksArea .inner .download {
      max-width: 225px;
      width: 100%; }
      @media screen and (max-width: 767px) {
        .works section.worksArea .inner .download {
          max-width: 100%; } }
      .works section.worksArea .inner .download a {
        position: relative;
        display: flex;
        width: 100%;
        font-size: 1.4rem;
        font-weight: 500;
        line-height: 2;
        text-align: center;
        vertical-align: middle;
        color: #ffffff;
        background-color: #525863;
        padding: 8px 15px 7px;
        transform: perspective(1px) translateZ(0);
        box-shadow: 0 0 1px rgba(0, 0, 0, 0);
        transition: color .3s;
        justify-content: center; }
        @media screen and (max-width: 767px) {
          .works section.worksArea .inner .download a {
            max-width: 100%;
            padding: 10px 0; } }
        .works section.worksArea .inner .download a::before {
          z-index: 2;
          content: '';
          display: block;
          margin: auto 15px auto 0;
          width: 20px;
          height: 20px;
          background-size: 20px 20px;
          transition: all .5s ease;
          background: url(/images/common/icon_download_w.svg) no-repeat; }
        .works section.worksArea .inner .download a::after {
          content: '';
          position: absolute;
          z-index: -1;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          background: #00b2d8;
          transform: scaleY(0);
          transform-origin: 50%;
          transition: transform .3s ease-out; }
        .works section.worksArea .inner .download a:hover::after {
          transform: scaleY(1); }
    .works section.worksArea .inner .moreBtn {
      text-align: center;
      width: 100%; }
      @media screen and (max-width: 767px) {
        .works section.worksArea .inner .moreBtn {
          padding: 0 20px; } }
      .works section.worksArea .inner .moreBtn:hover {
        opacity: 0.7; }
      .works section.worksArea .inner .moreBtn span {
        display: block;
        font-family: "BebasNeueRegular", sans-serif;
        font-size: 2.2rem;
        font-weight: bold;
        line-height: 1.09;
        letter-spacing: 1.76px;
        color: #525863;
        padding: 22px 0 17px;
        border-top: solid 1px #525863;
        border-bottom: solid 1px #525863;
        cursor: pointer; }
        .works section.worksArea .inner .moreBtn span.close::after {
          transform: rotate(135deg);
          margin-top: 3px; }
        .works section.worksArea .inner .moreBtn span.close:hover::after {
          transform: translateY(-3px) rotate(135deg); }
        .works section.worksArea .inner .moreBtn span::after {
          content: "";
          display: inline-block;
          width: 8px;
          height: 8px;
          border-left: solid 2px #515864;
          border-bottom: solid 2px #515864;
          transform: rotate(-45deg);
          vertical-align: middle;
          margin-top: -3px;
          margin-left: 15px;
          transition: transform .3s ease-out; }
        .works section.worksArea .inner .moreBtn span:hover::after {
          transform: translateY(3px) rotate(-45deg); }
    .works section.worksArea .inner .worksContentsBox {
      padding: 0 30px;
      display: flex;
      flex-wrap: wrap; }
      @media screen and (max-width: 767px) {
        .works section.worksArea .inner .worksContentsBox {
          padding: 0; } }
      .works section.worksArea .inner .worksContentsBox.worksContentsAccordionBox {
        display: none; }
      .works section.worksArea .inner .worksContentsBox > .flex {
        display: flex;
        flex-wrap: wrap; }
      .works section.worksArea .inner .worksContentsBox .worksBox {
        max-width: 30%;
        width: 100%;
        margin: 0 0 75px 40px;
        flex: 1 1 25.333%; }
        @media screen and (max-width: 767px) {
          .works section.worksArea .inner .worksContentsBox .worksBox {
            max-width: 100%;
            flex: auto;
            margin-left: 0; }
            .works section.worksArea .inner .worksContentsBox .worksBox:nth-of-type(2n) {
              margin: 0 0 20%; }
              .works section.worksArea .inner .worksContentsBox .worksBox:nth-of-type(2n) .worksImgBox {
                margin-right: 40px; }
            .works section.worksArea .inner .worksContentsBox .worksBox:nth-of-type(2n+1) {
              margin: 0 0 20%; }
              .works section.worksArea .inner .worksContentsBox .worksBox:nth-of-type(2n+1) .worksImgBox {
                margin-left: 40px; } }
        .works section.worksArea .inner .worksContentsBox .worksBox:nth-of-type(6n+1) {
          margin-left: 0; }
        .works section.worksArea .inner .worksContentsBox .worksBox a:hover img {
          transform: scale(1.1); }
          @media screen and (max-width: 767px) {
            .works section.worksArea .inner .worksContentsBox .worksBox a:hover img {
              transform: scale(1); } }
        .works section.worksArea .inner .worksContentsBox .worksBox .worksImgBox {
          overflow: hidden;
          box-shadow: 2px 5px 10px 0 rgba(51, 51, 51, 0.16);
          position: relative; }
          .works section.worksArea .inner .worksContentsBox .worksBox .worksImgBox:before {
            content: "";
            display: block;
            padding-top: 100%; }
          .works section.worksArea .inner .worksContentsBox .worksBox .worksImgBox img {
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            margin: auto;
            object-fit: cover;
            transition: all .3s ease; }
        @media screen and (max-width: 767px) {
          .works section.worksArea .inner .worksContentsBox .worksBox .worksTxtBox {
            padding: 0 40px; } }
        .works section.worksArea .inner .worksContentsBox .worksBox .worksCategory {
          font-size: 1.2rem;
          font-weight: 400;
          line-height: 1.5;
          color: #aaaaaa;
          margin-top: 20px;
          margin-bottom: 2px; }
          @media screen and (max-width: 767px) {
            .works section.worksArea .inner .worksContentsBox .worksBox .worksCategory {
              margin-bottom: 5px; } }
        .works section.worksArea .inner .worksContentsBox .worksBox .worksTit {
          font-size: 1.5rem;
          font-weight: 500;
          line-height: 1.43;
          color: #333333;
          margin-bottom: 4px;
          min-height: 21px; }
          @media screen and (max-width: 767px) {
            .works section.worksArea .inner .worksContentsBox .worksBox .worksTit {
              font-weight: 600;
              margin-bottom: 6px; } }
        .works section.worksArea .inner .worksContentsBox .worksBox .worksTag {
          font-size: 1.2rem;
          font-weight: 300;
          line-height: 1.8;
          color: #11a3c3; }
  .works section.worksArea .worksArticleBox {
    margin-bottom: 80px; }
    .works section.worksArea .worksArticleBox .worksArticleTit {
      font-size: 2.4rem;
      font-weight: bold;
      line-height: 1.67;
      letter-spacing: 1.32px;
      text-align: center;
      color: #555555;
      margin-bottom: 60px; }
      @media screen and (max-width: 767px) {
        .works section.worksArea .worksArticleBox .worksArticleTit {
          font-size: 1.8rem;
          margin-bottom: 40px; } }
      .works section.worksArea .worksArticleBox .worksArticleTit::after {
        content: "";
        display: block;
        width: 35px;
        height: 2px;
        background-color: #01b2d8;
        margin: 10px auto 0; }
        @media screen and (max-width: 767px) {
          .works section.worksArea .worksArticleBox .worksArticleTit::after {
            margin: 10px auto 0; } }
    .works section.worksArea .worksArticleBox .worksArticleLead {
      font-size: 2.8rem;
      font-weight: 400;
      line-height: 2.14;
      letter-spacing: 1.57px;
      text-align: center;
      color: #333333;
      margin-bottom: 90px; }
      @media screen and (max-width: 767px) {
        .works section.worksArea .worksArticleBox .worksArticleLead {
          font-size: 1.9rem;
          margin-bottom: 60px; } }
    .works section.worksArea .worksArticleBox .worksArticleKv {
      overflow: hidden;
      position: relative;
      margin-bottom: 70px; }
      @media screen and (max-width: 767px) {
        .works section.worksArea .worksArticleBox .worksArticleKv {
          margin-bottom: 40px; } }
      .works section.worksArea .worksArticleBox .worksArticleKv:before {
        content: "";
        display: block;
        padding-top: 41.4%; }
        @media screen and (max-width: 767px) {
          .works section.worksArea .worksArticleBox .worksArticleKv:before {
            padding-top: 61.8%; } }
      .works section.worksArea .worksArticleBox .worksArticleKv img {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        margin: auto;
        object-fit: cover; }
        @media screen and (max-width: 767px) {
          .works section.worksArea .worksArticleBox .worksArticleKv img.spOnly {
            display: block; } }
    .works section.worksArea .worksArticleBox .worksArticleDateArea {
      max-width: 1020px;
      width: 100%;
      padding: 0 20px;
      margin: 0 auto 100px;
      box-sizing: border-box; }
      @media screen and (max-width: 767px) {
        .works section.worksArea .worksArticleBox .worksArticleDateArea {
          margin-bottom: 60px; } }
      .works section.worksArea .worksArticleBox .worksArticleDateArea .client {
        font-size: 2rem;
        font-weight: 500;
        line-height: 1.8;
        color: #333333;
        border-bottom: solid 1px #01b2d8;
        padding: 0 10px 15px;
        margin-bottom: 30px; }
        @media screen and (max-width: 767px) {
          .works section.worksArea .worksArticleBox .worksArticleDateArea .client {
            font-size: 1.6rem;
            padding: 0 0 10px;
            margin-bottom: 15px; } }
      .works section.worksArea .worksArticleBox .worksArticleDateArea .flexLayout {
        display: flex;
        padding: 0 10px; }
        @media screen and (max-width: 767px) {
          .works section.worksArea .worksArticleBox .worksArticleDateArea .flexLayout {
            flex-wrap: wrap;
            padding: 0; } }
        .works section.worksArea .worksArticleBox .worksArticleDateArea .flexLayout .leftColumn {
          margin-right: 5%;
          width: 41%; }
          @media screen and (max-width: 767px) {
            .works section.worksArea .worksArticleBox .worksArticleDateArea .flexLayout .leftColumn {
              width: 100%;
              margin-right: 0;
              margin-bottom: 35px; } }
        .works section.worksArea .worksArticleBox .worksArticleDateArea .flexLayout .rightColumn {
          width: 59%; }
          @media screen and (max-width: 767px) {
            .works section.worksArea .worksArticleBox .worksArticleDateArea .flexLayout .rightColumn {
              width: 100%; } }
      .works section.worksArea .worksArticleBox .worksArticleDateArea .dateList {
        display: flex;
        flex-wrap: wrap;
        margin-bottom: 30px; }
        @media screen and (max-width: 767px) {
          .works section.worksArea .worksArticleBox .worksArticleDateArea .dateList {
            width: 100%;
            margin-bottom: 25px; } }
      .works section.worksArea .worksArticleBox .worksArticleDateArea .dateName {
        font-size: 1.3rem;
        font-weight: 300;
        line-height: 1.6;
        color: #333333;
        width: 25%;
        padding: 0 0 5px; }
        @media screen and (max-width: 767px) {
          .works section.worksArea .worksArticleBox .worksArticleDateArea .dateName {
            width: 30%;
            margin-right: 2%; } }
      .works section.worksArea .worksArticleBox .worksArticleDateArea .dateCon {
        font-size: 1.3rem;
        font-weight: 300;
        line-height: 1.6;
        color: #333333;
        width: 75%;
        padding: 0 0 5px; }
        @media screen and (max-width: 767px) {
          .works section.worksArea .worksArticleBox .worksArticleDateArea .dateCon {
            width: 68%; } }
        .works section.worksArea .worksArticleBox .worksArticleDateArea .dateCon a {
          color: #01b2d8;
          text-decoration: underline;
          background-image: url(/images/works/link_icon.svg);
          background-repeat: no-repeat;
          background-position: right center;
          padding: 0 12px 0 0;
          word-break: break-all; }
      .works section.worksArea .worksArticleBox .worksArticleDateArea .freeArea {
        display: flex;
        flex-wrap: wrap; }
        .works section.worksArea .worksArticleBox .worksArticleDateArea .freeArea .freeTxt {
          font-size: 1.5rem;
          font-weight: 400;
          line-height: 2;
          text-align: center;
          color: #333333;
          padding: 7px 15px;
          margin-right: 10px;
          margin-bottom: 5px;
          background-color: #f4f4f4; }
      .works section.worksArea .worksArticleBox .worksArticleDateArea .explanationTxt {
        font-size: 1.6rem;
        font-weight: 400;
        line-height: 2;
        letter-spacing: 0.8px;
        color: #555555; }
    .works section.worksArea .worksArticleBox .column {
      padding: 80px 0 100px; }
      .works section.worksArea .worksArticleBox .column:nth-of-type(2n+1) {
        background-color: #f8f8f8; }
      .works section.worksArea .worksArticleBox .column:nth-of-type(2n) {
        background-color: #fff; }
      @media screen and (max-width: 767px) {
        .works section.worksArea .worksArticleBox .column {
          padding: 50px 0 60px; } }
    .works section.worksArea .worksArticleBox .worksArticleGallery {
      padding-bottom: 0; }
      .works section.worksArea .worksArticleBox .worksArticleGallery .oneColumn {
        max-width: 1064px;
        padding: 0 20px;
        margin: 0 auto; }
        .works section.worksArea .worksArticleBox .worksArticleGallery .oneColumn .imgBox {
          margin-bottom: 50px; }
          .works section.worksArea .worksArticleBox .worksArticleGallery .oneColumn .imgBox:last-of-type {
            margin-bottom: 100px; }
          @media screen and (max-width: 767px) {
            .works section.worksArea .worksArticleBox .worksArticleGallery .oneColumn .imgBox {
              margin-bottom: 30px; }
              .works section.worksArea .worksArticleBox .worksArticleGallery .oneColumn .imgBox:last-of-type {
                margin-bottom: 60px; } }
      .works section.worksArea .worksArticleBox .worksArticleGallery .twoColumn {
        max-width: 1064px;
        padding: 0 20px;
        margin: 0 auto;
        display: flex;
        flex-wrap: wrap;
        justify-content: center; }
        @media screen and (max-width: 767px) {
          .works section.worksArea .worksArticleBox .worksArticleGallery .twoColumn {
            flex-wrap: wrap; } }
        .works section.worksArea .worksArticleBox .worksArticleGallery .twoColumn .imgBox {
          width: 48%;
          margin: 0 auto 50px; }
          @media screen and (max-width: 767px) {
            .works section.worksArea .worksArticleBox .worksArticleGallery .twoColumn .imgBox {
              width: 100%;
              margin: 0 0 30px; }
              .works section.worksArea .worksArticleBox .worksArticleGallery .twoColumn .imgBox:last-of-type {
                margin-bottom: 60px; } }
          .works section.worksArea .worksArticleBox .worksArticleGallery .twoColumn .imgBox:last-of-type {
            margin-bottom: 100px; }
          .works section.worksArea .worksArticleBox .worksArticleGallery .twoColumn .imgBox:nth-of-type(2n+1) {
            margin-right: 1%; }
            @media screen and (max-width: 767px) {
              .works section.worksArea .worksArticleBox .worksArticleGallery .twoColumn .imgBox:nth-of-type(2n+1) {
                margin-right: 0; } }
          .works section.worksArea .worksArticleBox .worksArticleGallery .twoColumn .imgBox:nth-of-type(2n) {
            margin-left: 1%; }
            @media screen and (max-width: 767px) {
              .works section.worksArea .worksArticleBox .worksArticleGallery .twoColumn .imgBox:nth-of-type(2n) {
                margin-left: 0; } }
      .works section.worksArea .worksArticleBox .worksArticleGallery .slideColumn {
        max-width: 1100px;
        padding: 0 30px;
        margin: 0 auto;
        position: relative;
        opacity: 0;
        transition: opacity .25s ease;
        box-sizing: border-box; }
        @media screen and (max-width: 767px) {
          .works section.worksArea .worksArticleBox .worksArticleGallery .slideColumn {
            padding: 0;
            margin: 0 auto; } }
        .works section.worksArea .worksArticleBox .worksArticleGallery .slideColumn.slick-initialized {
          opacity: 1; }
        .works section.worksArea .worksArticleBox .worksArticleGallery .slideColumn .slide {
          margin-bottom: 100px; }
          @media screen and (max-width: 767px) {
            .works section.worksArea .worksArticleBox .worksArticleGallery .slideColumn .slide {
              margin-bottom: 60px; } }
        .works section.worksArea .worksArticleBox .worksArticleGallery .slideColumn .slide-arrow {
          position: absolute;
          top: 42%;
          transform: translateY(-42%);
          width: 32px;
          height: 32px;
          border-right: solid 2px #515864;
          border-bottom: solid 2px #515864;
          z-index: 10; }
          @media screen and (max-width: 767px) {
            .works section.worksArea .worksArticleBox .worksArticleGallery .slideColumn .slide-arrow {
              width: 22px;
              height: 22px; } }
          .works section.worksArea .worksArticleBox .worksArticleGallery .slideColumn .slide-arrow:hover {
            cursor: pointer; }
          .works section.worksArea .worksArticleBox .worksArticleGallery .slideColumn .slide-arrow.prev-arrow {
            left: 10px;
            transform: rotate(135deg); }
            @media screen and (max-width: 767px) {
              .works section.worksArea .worksArticleBox .worksArticleGallery .slideColumn .slide-arrow.prev-arrow {
                left: 40px; } }
          .works section.worksArea .worksArticleBox .worksArticleGallery .slideColumn .slide-arrow.next-arrow {
            right: 10px;
            transform: rotate(-45deg); }
            @media screen and (max-width: 767px) {
              .works section.worksArea .worksArticleBox .worksArticleGallery .slideColumn .slide-arrow.next-arrow {
                right: 40px; } }
    .works section.worksArea .worksArticleBox .worksArticleProduction .productionTit {
      font-family: "BebasNeueRegular", sans-serif;
      font-size: 3.6rem;
      font-weight: bold;
      line-height: 1.11;
      letter-spacing: 2.88px;
      text-align: center;
      color: #555555;
      margin-bottom: 30px; }
      .works section.worksArea .worksArticleBox .worksArticleProduction .productionTit::after {
        content: "";
        display: block;
        width: 35px;
        height: 2px;
        background-color: #01b2d8;
        margin: 10px auto 0; }
    .works section.worksArea .worksArticleBox .worksArticleProduction .flexLayout {
      display: flex;
      justify-content: center;
      padding: 0 20px; }
      @media screen and (max-width: 767px) {
        .works section.worksArea .worksArticleBox .worksArticleProduction .flexLayout {
          flex-wrap: wrap; } }
      .works section.worksArea .worksArticleBox .worksArticleProduction .flexLayout p {
        max-width: 360px;
        width: 100%; }
        .works section.worksArea .worksArticleBox .worksArticleProduction .flexLayout p:first-of-type {
          margin-right: 50px; }
        @media screen and (max-width: 767px) {
          .works section.worksArea .worksArticleBox .worksArticleProduction .flexLayout p {
            max-width: 100%; }
            .works section.worksArea .worksArticleBox .worksArticleProduction .flexLayout p:first-of-type {
              margin-right: 0; } }
      .works section.worksArea .worksArticleBox .worksArticleProduction .flexLayout ul {
        max-width: 360px;
        width: 100%; }
        .works section.worksArea .worksArticleBox .worksArticleProduction .flexLayout ul:first-of-type {
          margin-right: 50px; }
        @media screen and (max-width: 767px) {
          .works section.worksArea .worksArticleBox .worksArticleProduction .flexLayout ul {
            max-width: 100%; }
            .works section.worksArea .worksArticleBox .worksArticleProduction .flexLayout ul:first-of-type {
              margin-right: 0; } }
        .works section.worksArea .worksArticleBox .worksArticleProduction .flexLayout ul li {
          font-size: 1.5rem;
          font-weight: 400;
          line-height: 2;
          color: #333333;
          position: relative;
          padding-left: 15px;
          margin-bottom: 3px; }
          .works section.worksArea .worksArticleBox .worksArticleProduction .flexLayout ul li::before {
            content: "・";
            display: inline-block;
            position: absolute;
            top: 0;
            left: 0; }
    .works section.worksArea .worksArticleBox .worksArticleOther .otherTit {
      font-family: "BebasNeueRegular", sans-serif;
      font-size: 3.6rem;
      font-weight: bold;
      line-height: 1.11;
      letter-spacing: 2.88px;
      text-align: center;
      color: #555555;
      margin-bottom: 50px; }
      .works section.worksArea .worksArticleBox .worksArticleOther .otherTit::after {
        content: "";
        display: block;
        width: 35px;
        height: 2px;
        background-color: #01b2d8;
        margin: 10px auto 0; }
    .works section.worksArea .worksArticleBox .worksArticleOther .backBtn {
      letter-spacing: .05em;
      width: 300px;
      text-align: center;
      position: relative;
      color: #525863;
      font-size: 1.6rem;
      font-weight: 500;
      box-sizing: border-box;
      background: #fff;
      transition: all .5s ease;
      margin: 60px auto 0; }
      .works section.worksArea .worksArticleBox .worksArticleOther .backBtn a {
        display: block;
        width: 100%;
        color: #5a5a5a;
        padding: 13px 0;
        box-shadow: 0 0 0 1px #707070 inset; }
        .works section.worksArea .worksArticleBox .worksArticleOther .backBtn a::before {
          background: #00b2d8; }
        .works section.worksArea .worksArticleBox .worksArticleOther .backBtn a::after {
          position: absolute;
          content: '';
          margin: auto 0;
          top: 20px;
          background: url(/images/common/icon_long_btn_arrow.png) no-repeat;
          background-size: 58px 7px;
          width: 58px;
          height: 7px;
          left: -16px;
          transition: all .5s ease;
          transform: scale(-1, 1); }
        .works section.worksArea .worksArticleBox .worksArticleOther .backBtn a:hover {
          box-shadow: 0 0 0 1px #00b2d8 inset; }
          .works section.worksArea .worksArticleBox .worksArticleOther .backBtn a:hover::after {
            left: -30px;
            transition-timing-function: cubic-bezier(0.52, 1.64, 0.37, 0.66); }
    .works section.worksArea .worksArticleBox .worksArticleOther .worksContentsBox {
      max-width: 980px;
      margin: 0 auto;
      padding: 0 30px;
      display: flex;
      flex-wrap: wrap; }
      @media screen and (max-width: 767px) {
        .works section.worksArea .worksArticleBox .worksArticleOther .worksContentsBox {
          padding: 0 20px;
          justify-content: space-between; } }
      .works section.worksArea .worksArticleBox .worksArticleOther .worksContentsBox .worksBox {
        max-width: 287px;
        width: 100%;
        margin: 0 0 0 40px;
        flex: 1 1 25.333%; }
        .works section.worksArea .worksArticleBox .worksArticleOther .worksContentsBox .worksBox:first-of-type {
          margin-left: 0; }
        @media screen and (max-width: 767px) {
          .works section.worksArea .worksArticleBox .worksArticleOther .worksContentsBox .worksBox {
            max-width: 46%;
            margin-left: 0; }
            .works section.worksArea .worksArticleBox .worksArticleOther .worksContentsBox .worksBox:nth-of-type(3) {
              display: none !important; } }
        .works section.worksArea .worksArticleBox .worksArticleOther .worksContentsBox .worksBox a:hover img {
          transform: scale(1.1); }
          @media screen and (max-width: 767px) {
            .works section.worksArea .worksArticleBox .worksArticleOther .worksContentsBox .worksBox a:hover img {
              transform: scale(1); } }
        .works section.worksArea .worksArticleBox .worksArticleOther .worksContentsBox .worksBox .worksImgBox {
          overflow: hidden;
          box-shadow: 2px 5px 10px 0 rgba(51, 51, 51, 0.16);
          position: relative; }
          .works section.worksArea .worksArticleBox .worksArticleOther .worksContentsBox .worksBox .worksImgBox:before {
            content: "";
            display: block;
            padding-top: 100%; }
          .works section.worksArea .worksArticleBox .worksArticleOther .worksContentsBox .worksBox .worksImgBox img {
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            margin: auto;
            object-fit: cover;
            transition: all .3s ease; }
        @media screen and (max-width: 767px) {
          .works section.worksArea .worksArticleBox .worksArticleOther .worksContentsBox .worksBox .worksTxtBox {
            padding: 0; } }
        .works section.worksArea .worksArticleBox .worksArticleOther .worksContentsBox .worksBox .worksCategory {
          font-size: 1.2rem;
          font-weight: 400;
          line-height: 1.5;
          color: #aaaaaa;
          margin-top: 20px;
          margin-bottom: 2px; }
          @media screen and (max-width: 767px) {
            .works section.worksArea .worksArticleBox .worksArticleOther .worksContentsBox .worksBox .worksCategory {
              margin-bottom: 5px; } }
        .works section.worksArea .worksArticleBox .worksArticleOther .worksContentsBox .worksBox .worksTit {
          font-size: 1.5rem;
          font-weight: 500;
          line-height: 1.43;
          color: #333333;
          margin-bottom: 4px; }
          @media screen and (max-width: 767px) {
            .works section.worksArea .worksArticleBox .worksArticleOther .worksContentsBox .worksBox .worksTit {
              font-weight: 600;
              margin-bottom: 6px; } }
        .works section.worksArea .worksArticleBox .worksArticleOther .worksContentsBox .worksBox .worksTag {
          font-size: 1.2rem;
          font-weight: 300;
          line-height: 1.8;
          color: #11a3c3; }

/* ==========================================================================

CONTACT

========================================================================== */
.contact section.contactArea .inner {
  max-width: 1300px;
  margin: 0 auto;
  padding: 0 20px 80px; }
  .contact section.contactArea .inner:after {
    content: "";
    display: table;
    clear: both; }
  @media screen and (max-width: 767px) {
    .contact section.contactArea .inner {
      max-width: initial; } }
  @media screen and (max-width: 767px) {
    .contact section.contactArea .inner {
      padding: 0 20px 40px; } }
  .contact section.contactArea .inner .readTxt {
    line-height: 1.6;
    font-size: 1.6rem;
    letter-spacing: .05em;
    width: 80%;
    margin: 0 auto 50px;
    font-size: 1.4rem;
    line-height: 1.7; }
    @media screen and (max-width: 767px) {
      .contact section.contactArea .inner .readTxt {
        line-height: 1.4; } }
    @media screen and (min-width: 768px) and (max-width: 1200px) {
      .contact section.contactArea .inner .readTxt {
        width: 100%; } }
    @media screen and (max-width: 767px) {
      .contact section.contactArea .inner .readTxt {
        width: 100%;
        margin: 0 0 25px;
        line-height: 1.7; } }
  .contact section.contactArea .inner .required {
    color: #dd4f51;
    text-align: center;
    display: inline-block;
    line-height: 1; }
  .contact section.contactArea .inner table tr {
    border: none; }
  .contact section.contactArea .inner table th, .contact section.contactArea .inner table td {
    border: none; }
    .contact section.contactArea .inner table th .required, .contact section.contactArea .inner table td .required {
      font-size: 1.2rem; }
    .contact section.contactArea .inner table th:first-of-type, .contact section.contactArea .inner table td:first-of-type {
      border: none; }
      @media screen and (max-width: 767px) {
        .contact section.contactArea .inner table th:first-of-type, .contact section.contactArea .inner table td:first-of-type {
          border: none; } }
    .contact section.contactArea .inner table th input.widthS, .contact section.contactArea .inner table td input.widthS {
      width: 50%; }
      @media screen and (max-width: 767px) {
        .contact section.contactArea .inner table th input.widthS, .contact section.contactArea .inner table td input.widthS {
          width: 100%; } }
    .contact section.contactArea .inner table th input.widthL, .contact section.contactArea .inner table td input.widthL {
      width: 80%; }
      @media screen and (max-width: 767px) {
        .contact section.contactArea .inner table th input.widthL, .contact section.contactArea .inner table td input.widthL {
          width: 100%; } }
  .contact section.contactArea .inner table th {
    width: 28%;
    padding: 30px 10px 0 0; }
    @media screen and (max-width: 767px) {
      .contact section.contactArea .inner table th {
        width: 100%;
        padding: 15px 0 4px; } }
  .contact section.contactArea .inner table td {
    padding: 20px 0; }
    @media screen and (max-width: 767px) {
      .contact section.contactArea .inner table td {
        padding: 0 0 7px; } }
  .contact section.contactArea .inner table.confirmTable {
    width: 80%;
    margin: 80px auto; }
    @media screen and (min-width: 768px) and (max-width: 1200px) {
      .contact section.contactArea .inner table.confirmTable {
        width: 100%;
        margin: 80px auto; } }
    @media screen and (max-width: 767px) {
      .contact section.contactArea .inner table.confirmTable {
        width: 100%;
        margin: 30px auto; } }
    .contact section.contactArea .inner table.confirmTable th {
      padding: 20px 0;
      border-bottom: 1px solid #f0f0f0; }
      @media screen and (max-width: 767px) {
        .contact section.contactArea .inner table.confirmTable th {
          width: 100%;
          font-weight: 700;
          padding: 10px;
          background: #f7f7f7;
          border: none; } }
    .contact section.contactArea .inner table.confirmTable td {
      border-bottom: 1px solid #f0f0f0; }
      @media screen and (max-width: 767px) {
        .contact section.contactArea .inner table.confirmTable td {
          width: 100%;
          padding: 10px;
          border: none; } }
  .contact section.contactArea .inner .characterLimitTxt {
    text-align: right;
    font-size: 1.2rem;
    width: 80%;
    padding: 3px 0 0;
    line-height: 1; }
    @media screen and (max-width: 767px) {
      .contact section.contactArea .inner .characterLimitTxt {
        width: 100%; } }
  .contact section.contactArea .inner .errorTxt {
    color: #dd4f51;
    font-size: 1.3rem;
    margin: 5px 0 0; }
  .contact section.contactArea .inner .inputBox.error input,
  .contact section.contactArea .inner .inputBox.error textarea {
    background: #dd4f51; }
  .contact section.contactArea .inner .btnBox {
    margin: 0 auto; }
    @media screen and (max-width: 767px) {
      .contact section.contactArea .inner .btnBox {
        width: 280px; } }
    .contact section.contactArea .inner .btnBox button {
      cursor: pointer; }
  .contact section.contactArea .inner .twoSingBox {
    width: 100%;
    display: flex;
    justify-content: center; }
    @media screen and (max-width: 767px) {
      .contact section.contactArea .inner .twoSingBox {
        width: 100%;
        display: block;
        padding: 20px 0 0; } }
    .contact section.contactArea .inner .twoSingBox li.btnBox {
      margin: 0 37px 0 0; }
      @media screen and (max-width: 767px) {
        .contact section.contactArea .inner .twoSingBox li.btnBox {
          width: 280px;
          margin: 0 auto 20px; } }
      .contact section.contactArea .inner .twoSingBox li.btnBox:last-of-type {
        margin: 0; }
        @media screen and (max-width: 767px) {
          .contact section.contactArea .inner .twoSingBox li.btnBox:last-of-type {
            margin: 0 auto; } }
  .contact section.contactArea .inner .errorTtl {
    font-size: 2rem;
    text-align: center;
    margin: 0 0 100px;
    line-height: 1; }
    @media screen and (max-width: 767px) {
      .contact section.contactArea .inner .errorTtl {
        margin: 0 0 60px; } }
  .contact section.contactArea .inner .completeBox {
    font-size: 1.6rem;
    text-align: center;
    margin: 0 0 80px;
    line-height: 2; }
    @media screen and (max-width: 767px) {
      .contact section.contactArea .inner .completeBox {
        line-height: 1.7;
        text-align: left;
        margin: 0 0 40px; } }

/* ==========================================================================

その他

========================================================================== */
.other section.mainArea {
  padding-top: 20px; }
  .other section.mainArea .inner .contentsBox h2 {
    font-size: 1.6rem;
    margin: 50px 0 5px;
    font-weight: 700; }
  .other section.mainArea .inner .contentsBox p {
    line-height: 1.6;
    font-size: 1.6rem;
    letter-spacing: .05em;
    font-size: 1.4rem;
    line-height: 1.7; }
    @media screen and (max-width: 767px) {
      .other section.mainArea .inner .contentsBox p {
        line-height: 1.4; } }
    .other section.mainArea .inner .contentsBox p.cradit {
      margin: 40px 0 0; }
      .other section.mainArea .inner .contentsBox p.cradit img {
        vertical-align: top;
        display: inline-block;
        width: 144px;
        margin: 4px 0 0; }
  .other section.mainArea .inner .contentsBox ol {
    margin: 10px 0 0; }
    .other section.mainArea .inner .contentsBox ol li {
      line-height: 1.6;
      font-size: 1.6rem;
      letter-spacing: .05em;
      font-size: 1.4rem;
      padding-left: 1em;
      text-indent: -1em; }
      @media screen and (max-width: 767px) {
        .other section.mainArea .inner .contentsBox ol li {
          line-height: 1.4; } }
    .other section.mainArea .inner .contentsBox ol.tops {
      margin: 0; }
  .other section.mainArea .inner .errorTtl {
    width: 260px;
    margin: 0 auto 18px; }
    @media screen and (max-width: 767px) {
      .other section.mainArea .inner .errorTtl {
        width: 220px;
        margin: 0 auto 15px; } }
  .other section.mainArea .inner .errorTxt {
    line-height: 1.6;
    font-size: 1.6rem;
    letter-spacing: .05em;
    margin: 0 0 25px;
    font-size: 1.4rem;
    line-height: 2;
    text-align: center; }
    @media screen and (max-width: 767px) {
      .other section.mainArea .inner .errorTxt {
        line-height: 1.4; } }
    @media screen and (max-width: 767px) {
      .other section.mainArea .inner .errorTxt {
        line-height: 1.7;
        margin: 0 0 30px;
        text-align: left; } }
  .other section.mainArea .inner .btnBox {
    margin: 0 auto; }
    @media screen and (max-width: 767px) {
      .other section.mainArea .inner .btnBox {
        width: 280px;
        margin: 0 auto; } }
