@charset "utf-8";

/* ==========================================================================

Webフォントの定義

========================================================================== */

// BEBAS Neue レギュラー
@font-face {
    font-family:"BebasNeueRegular";
    src:url("/css/font/BebasNeue Regular.woff2") format("woff2"),url("/css/font/BebasNeue Regular.woff") format("woff"),url("/css/font/BebasNeue Regular.otf") format("opentype");
    font-style: normal;
    font-weight: 400;
    font-display: swap;
}
// BEBAS Neue ボールド
@font-face {
    font-family:"BebasNeueBold";
    src:url("/css/font/BebasNeue Bold.woff2") format("woff2"),url("/css/font/BebasNeue Bold.woff") format("woff"),url("/css/font/BebasNeue Bold.otf") format("opentype");
    font-style: normal;
    font-weight: 400;
    font-display: swap;
}
$bebasneue-regular-font: 'BebasNeueRegular', sans-serif; // BEBAS Neue レギュラーフォント
$bebasneue-bold-font: 'BebasNeueBold', sans-serif; // BEBAS Neue ボールドフォント
$base-font: 'Noto Sans Japanese', sans-serif; // 日本語Noto Sansフォント

// 大見出しの切り替えフォント
// Nemoy Light
@font-face {
    font-family: 'NemoyLight';
    font-style: normal;
    font-weight: 400;
    src: url('/css/font/nemoy_light-webfont.eot'); /* IE9 Compat Modes */
    src: local('☺'),
    url('/css/font/nemoy_light-webfont.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('/css/font/nemoy_light-webfont.woff') format('woff'), /* Modern Browsers */
    url('/css/font/nemoy_light-webfont.ttf') format('truetype'), /* Safari, Android, iOS */
    url('/css/font/nemoy_light-webfont.svg#NemoyLight') format('svg'); /* Legacy iOS */
    font-display: swap;
}

// Caesar Dressing
@font-face {
    font-family: 'Caesar Dressing';
    font-style: normal;
    font-weight: 400;
    src: url('/css/font/caesar-dressing-v7-latin-regular.eot'); /* IE9 Compat Modes */
    src: local('Caesar Dressing'), local('CaesarDressing-Regular'),
    url('/css/font/caesar-dressing-v7-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('/css/font/caesar-dressing-v7-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
    url('/css/font/caesar-dressing-v7-latin-regular.woff') format('woff'), /* Modern Browsers */
    url('/css/font/caesar-dressing-v7-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
    url('/css/font/caesar-dressing-v7-latin-regular.svg#CaesarDressing') format('svg'); /* Legacy iOS */
    font-display: swap;
}

// Zilla Slab Highlight
@font-face {
    font-family: 'Zilla Slab Highlight';
    font-style: normal;
    font-weight: 400;
    src: url('/css/font/zilla-slab-highlight-v6-latin-regular.eot'); /* IE9 Compat Modes */
    src: local('Zilla Slab Highlight'), local('ZillaSlabHighlight-Regular'),
    url('/css/font/zilla-slab-highlight-v6-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('/css/font/zilla-slab-highlight-v6-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
    url('/css/font/zilla-slab-highlight-v6-latin-regular.woff') format('woff'), /* Modern Browsers */
    url('/css/font/zilla-slab-highlight-v6-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
    url('/css/font/zilla-slab-highlight-v6-latin-regular.svg#ZillaSlabHighlight') format('svg'); /* Legacy iOS */
    font-display: swap;
}

//Alegreya SC
@font-face {
    font-family: 'Alegreya SC';
    font-style: normal;
    font-weight: 400;
    src: url('/css/font/alegreya-sc-v10-latin-regular.eot'); /* IE9 Compat Modes */
    src: local('Alegreya SC Regular'), local('AlegreyaSC-Regular'),
    url('/css/font/alegreya-sc-v10-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('/css/font/alegreya-sc-v10-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
    url('/css/font/alegreya-sc-v10-latin-regular.woff') format('woff'), /* Modern Browsers */
    url('/css/font/alegreya-sc-v10-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
    url('/css/font/alegreya-sc-v10-latin-regular.svg#AlegreyaSC') format('svg'); /* Legacy iOS */
    font-display: swap;
}

// Josefin Slab
@font-face {
    font-family: 'Josefin Slab';
    font-style: normal;
    font-weight: 400;
    src: url('/css/font/josefin-slab-v9-latin-regular.eot'); /* IE9 Compat Modes */
    src: local('Josefin Slab Regular'), local('JosefinSlab-Regular'),
    url('/css/font/josefin-slab-v9-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('/css/font/josefin-slab-v9-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
    url('/css/font/josefin-slab-v9-latin-regular.woff') format('woff'), /* Modern Browsers */
    url('/css/font/josefin-slab-v9-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
    url('/css/font/josefin-slab-v9-latin-regular.svg#JosefinSlab') format('svg'); /* Legacy iOS */
    font-display: swap;
}

// Tangerine
@font-face {
    font-family: 'Tangerine';
    font-style: normal;
    font-weight: 400;
    src: url('/css/font/tangerine-v10-latin-regular.eot'); /* IE9 Compat Modes */
    src: local('Tangerine Regular'), local('Tangerine-Regular'),
    url('/css/font/tangerine-v10-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('/css/font/tangerine-v10-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
    url('/css/font/tangerine-v10-latin-regular.woff') format('woff'), /* Modern Browsers */
    url('/css/font/tangerine-v10-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
    url('/css/font/tangerine-v10-latin-regular.svg#Tangerine') format('svg'); /* Legacy iOS */
    font-display: swap;
}

// Bungee Shade
@font-face {
    font-family: 'Bungee Shade';
    font-style: normal;
    font-weight: 400;
    src: url('/css/font/bungee-shade-v4-latin-regular.eot'); /* IE9 Compat Modes */
    src: local('Bungee Shade'), local('BungeeShade-Regular'),
    url('/css/font/bungee-shade-v4-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('/css/font/bungee-shade-v4-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
    url('/css/font/bungee-shade-v4-latin-regular.woff') format('woff'), /* Modern Browsers */
    url('/css/font/bungee-shade-v4-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
    url('/css/font/bungee-shade-v4-latin-regular.svg#BungeeShade') format('svg'); /* Legacy iOS */
    font-display: swap;
}

// Akronim
@font-face {
    font-family: 'Akronim';
    font-style: normal;
    font-weight: 400;
    src: url('/css/font/akronim-v8-latin-regular.eot'); /* IE9 Compat Modes */
    src: local('Akronim Regular'), local('Akronim-Regular'),
    url('/css/font/akronim-v8-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('/css/font/akronim-v8-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
    url('/css/font/akronim-v8-latin-regular.woff') format('woff'), /* Modern Browsers */
    url('/css/font/akronim-v8-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
    url('/css/font/akronim-v8-latin-regular.svg#Akronim') format('svg'); /* Legacy iOS */
    font-display: swap;
}

// Fredericka the Great 
@font-face {
    font-family: 'Fredericka the Great';
    font-style: normal;
    font-weight: 400;
    src: url('/css/font/fredericka-the-great-v7-latin-regular.eot'); /* IE9 Compat Modes */
    src: local('Fredericka the Great'), local('FrederickatheGreat'),
    url('/css/font/fredericka-the-great-v7-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('/css/font/fredericka-the-great-v7-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
    url('/css/font/fredericka-the-great-v7-latin-regular.woff') format('woff'), /* Modern Browsers */
    url('/css/font/fredericka-the-great-v7-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
    url('/css/font/fredericka-the-great-v7-latin-regular.svg#FrederickatheGreat') format('svg'); /* Legacy iOS */
    font-display: swap;
}

// Codystar
@font-face {
    font-family: 'Codystar';
    font-style: normal;
    font-weight: 400;
    src: url('/css/font/codystar-v6-latin-regular.eot'); /* IE9 Compat Modes */
    src: local('Codystar'),
    url('/css/font/codystar-v6-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('/css/font/codystar-v6-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
    url('/css/font/codystar-v6-latin-regular.woff') format('woff'), /* Modern Browsers */
    url('/css/font/codystar-v6-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
    url('/css/font/codystar-v6-latin-regular.svg#Codystar') format('svg'); /* Legacy iOS */
    font-display: swap;
}

// Monoton
@font-face {
    font-family: 'Monoton';
    font-style: normal;
    font-weight: 400;
    src: url('/css/font/monoton-v8-latin-regular.eot'); /* IE9 Compat Modes */
    src: local('Monoton'), local('Monoton-Regular'),
    url('/css/font/monoton-v8-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('/css/font/monoton-v8-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
    url('/css/font/monoton-v8-latin-regular.woff') format('woff'), /* Modern Browsers */
    url('/css/font/monoton-v8-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
    url('/css/font/monoton-v8-latin-regular.svg#Monoton') format('svg'); /* Legacy iOS */
    font-display: swap;
}

// Gaegu
@font-face {
    font-family: 'Gaegu';
    font-style: normal;
    font-weight: 400;
    src: url('/css/font/gaegu-v5-latin-regular.eot'); /* IE9 Compat Modes */
    src: local('Gaegu Regular'), local('Gaegu-Regular'),
    url('/css/font/gaegu-v5-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('/css/font/gaegu-v5-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
    url('/css/font/gaegu-v5-latin-regular.woff') format('woff'), /* Modern Browsers */
    url('/css/font/gaegu-v5-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
    url('/css/font/gaegu-v5-latin-regular.svg#Gaegu') format('svg'); /* Legacy iOS */
    font-display: swap;
}