@charset "utf-8";

/* ==========================================================================

その他

========================================================================== */

.other {
	section.mainArea {
		padding-top: 20px;

		.inner {	
			// プライバシーポリシー
			.contentsBox {
				h2 {
					font-size: 1.6rem;
					margin: 50px 0 5px;
					font-weight: 700;
				}

				p {
					@include typography;
					font-size: 1.4rem;
					line-height: 1.7;

					&.cradit {
						margin: 40px 0 0;

						img {
							vertical-align: top;
							display: inline-block;
							width: 144px;
							margin: 4px 0 0;
						}
					}
				}

				ol {
					margin: 10px 0 0;

					li {
						@include typography;
						font-size: 1.4rem;
						padding-left: 1em;
						text-indent: -1em;
					}

					&.tops {
						margin: 0;
					}
				}
			}

			// エラーページ（404）
			.errorTtl {
				width: 260px;
				margin: 0 auto 18px;

				// 横幅767pxまで
				@include mq(md) {
					width: 220px;
					margin: 0 auto 15px;
				}
			}

			// エラーページ（404）
			.errorTxt {
				@include typography;
				margin: 0 0 25px;
				font-size: 1.4rem;
				line-height: 2;
				text-align: center;

				// 横幅767pxまで
				@include mq(md) {
					line-height: 1.7;
					margin: 0 0 30px;
					text-align: left;
				}
			}

			// アクティブボタンスタイル
			.btnBox {
				@extend %btnBace;
				margin: 0 auto;

				// 横幅767pxまで
				@include mq(md) {
					width: 280px;
					margin: 0 auto;
				}
			}
		}
	}
}