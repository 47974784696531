@charset "utf-8";

/* =================================================================

基本設定

================================================================= */

html {
	font-size: $mainRemFontSize;
}

body {
	font-size: 1.6rem;
	font-family: $base-font;
}

/* 要素の非表示
-------------------------------------------------------------*/
.continer,
.opacityHidden {
	opacity: 0;
}

/* SVG画像調整
-------------------------------------------------------------*/
img[src$=".svg"],
object[src$=".svg"] {
	max-width: 100%;
	width: 100%;
	height: auto;
}

/* ローディング表示
-------------------------------------------------------------*/
#loading {
	background: #fff;
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 10000;
	transition: ease .5s;
	display: none;

	&.isDisplay {
		display: block;
	}

	.loadTxt {
		width: 100%;
		display: block;
		text-align: center;
		position: absolute;
		top: calc(50% - 38px);
		left: 0;

		span {
			margin: 0 .3rem;
			position: relative;
			display: inline-block;
			font-size: 4.8rem;
			font-weight: bold;
			color: rgba(255,255,255,.1);
    		font-family: $bebasneue-regular-font;

			&::after {
				position: absolute;
				top: 0;
				left: 0;
				opacity: 0;
				content: attr(data-text);
				color: $mainColor;
				transform: scale(1.2);
				animation: loading 1.2s infinite;
			}

			&:nth-child(2)::after {
				animation-delay: .1s;
			}
			&:nth-child(3)::after {
				animation-delay: .2s;
			}
			&:nth-child(4)::after {
				animation-delay: .3s;
			}
			&:nth-child(5)::after {
				animation-delay: .4s;
			}
			&:nth-child(6)::after {
				animation-delay: .5s;
			}
			&:nth-child(7)::after {
				animation-delay: .6s;
			}
		}
	}

	@keyframes loading {
		0%, 75%, 100% {transform: scale(1.2); opacity: 0;}
		25%, 50% {transform: scale(1); opacity: 1;}
	}
}


/* レスポンシブチェック
-------------------------------------------------------------*/
#responsibleCheck {
	width: 1px;
	height: 0;

	// 横幅768px〜1200pxまで
	@include mq(md_jlg) {
		width: 1.5px;
	}

	// 横幅426px〜767pxまで
	@include mq(md_maxmd) {
		width: 3px;
	}

	// 横幅767pxまで
	@include mq(md) {
		width: 2px;
	}
}

/* PC/SPで要素を出しわけ
-------------------------------------------------------------*/
.pcOnly {
	// 横幅767pxまで
	@include mq(md) {
		display: none;
	}
}

.tabOnly {
	display: none;

	// 横幅768px〜1200pxまで
	@include mq(md_jlg) {
		display: static;
	}
	// 横幅767pxまで
	@include mq(md) {
		display: static;
	}
}

.spOnly {
	display: none;

	// 横幅767pxまで
	@include mq(md) {
		display: static;
	}
}

/* アニメーション関連
-------------------------------------------------------------*/
.animateTrigger {
	visibility: hidden;
}

// 表示させるキーフレーム
@keyframes show {// mediatypeの内部で@keyframesを定義するとNG
	0% {opacity: 0;}
	100% {opacity: 1;}
}

// アニメーションさせたい要素のクラス
.sa {
	opacity: 0;
	transition: all .5s ease;
}

// 要素をアニメーションさせるクラス
.sa.show {
	opacity: 1;
	transform: none;
}

// 左から右に表示アニメーション
.saLr {
	transform: translate(-100px, 0);
}

// 右から左に表示アニメーション
.saRr {
	transform: translate(100px, 0);
}

// 下から上に表示アニメーション
.saUp {
	transform: translate(0, 100px);
}

// 上から下に表示アニメーション
.saDown {
	transform: translate(0, -100px);
}

// スクロールするたびにフェードインするクラス
.animeFadeIn {
    animation: 2s forwards animeFadeIn;
}
@keyframes animeFadeIn {
	0% {opacity: 0;}
	100% {opacity: 1;}
}

/* カーソル反転表示
-------------------------------------------------------------*/
::selection {
    background: $mainColor;
    color: $basicWhiteColor;
}

::-moz-selection {
    background: $mainColor;
    color: $basicWhiteColor;
}

/* ホバーで要素を拡大
-------------------------------------------------------------*/
.bigHover {
	transition: all .3s ease;
	&:hover {
		transform: scale(1.1);

		// 横幅767pxまで
		@include mq(md) {
			transform: scale(1);
		}
	}
}

/* フォーム関連
-------------------------------------------------------------*/
select {
	font-weight: normal;
}

input,
select,
textarea {
	-webkit-tap-highlight-color: rgba(0,0,0,0);
}

/* inputスタイル
-------------------------------------------------------------*/
input {
	&[type='text'],
	&[type='email'],
	&[type='password'],
	&[type='tel'],
	&[type='search'],
	&[type='number'] {
		line-height: 46px;
		height: 46px;
		padding: 0 10px;
		box-sizing: border-box;
		-webkit-appearance: none;
		border: none;
		border-radius: 4px;
		background: #f0f0f0;
		transition: all .5s ease;
		@include placeholder-color($borderColor);
	}

	&[type='text']:focus,
	&[type='email']:focus,
	&[type='password']:focus,
	&[type='tel']:focus,
	&[type='search']:focus,
	&[type='number']:focus {
		outline: 0;
		background: $greenColor;
		color: $basicWhiteColor;
	}

	&:placeholder-shown,
	&::-webkit-input-placeholder,
	&::-moz-placeholder,
	&:-moz-placeholder,
	&:-ms-input-placeholder {
		color: #fafafa;
		vertical-align: middle;
	}
}

/* textareaスタイル
-------------------------------------------------------------*/
textarea {
	width: 80%;
	min-height: 180px;
	max-height: 220px;
	display: block;
	padding: 14px 10px;
	overflow: auto;
	appearance: none;
	resize: vertical;
	word-break: keep-all;
	box-sizing: border-box;
	border: none;
	border-radius: 4px;
	background: #f0f0f0;
	transition: all .5s ease;
	@include placeholder-color($borderColor);

	&:focus {
		outline: 0;
		background: #6cb051;
		color: $basicWhiteColor;
	}

	// 横幅767pxまで
	@include mq(md) {
		width: 100%;
		min-height: 150px;
		max-height: 200px;
	}
}

/* リンクスタイル
-------------------------------------------------------------*/
a[href^="tel:"] {
	cursor: default;
}

/* SNSリンクボタンエリア（基本）
-------------------------------------------------------------*/
.snsArea {
	text-align: center;

	.snsTtl {
		transform: rotate(90deg);
		transition: all .5s ease;
		font-size: 1.6rem;
		line-height: 1;
		letter-spacing: .1em;
		font-family: $bebasneue-regular-font;
	}

	ul {
		li {
			svg {
				fill: #525863;
			}

			&.fbIcon {
				width: 21px;
				height: 21px;
			}

			&.twIcon {
				width: 25px;
				height: 20px;
			}

			&.plusIcon {
				width: 24px;
				height: 15px;
			}

			&.hatenaIcon {
				width: 21px;
				height: 21px;
			}

			&.lineIcon {
				width: 22px;
				height: 22px;
			}

			&.instagramIcon {
				width: 21px;
				height: 21px;
			}	
		}
	}
}


/* 下部SNSボタンエリア
-------------------------------------------------------------*/
.snsUnderArea {
	padding: 0 20px;
	
	ul {
		height: auto;
		display: flex;
		justify-content: space-around;

		li {
			width: 100%;
			margin-right: 3%;
			margin-bottom: 2px;
			border-radius: 4px;

			&:last-of-type {
				margin-right: 0;
			}

			&.shareFacebook {
				background: #3b5998;
				box-shadow: 0 2px #2d4373;

				&:hover {
					background:#4c70ba;
				}
			}

			&.shareTwitter {
				background: #55acee;
				box-shadow: 0 2px #2795e9;

				&:hover {
					background: #83c3f3;
				}
			}

			&.shareGoogle {
				background: #dd4b39;
				box-shadow: 0 2px #c23321;

				&:hover {
					background: #e47365;
				}
			}

			&.shareHatena {
				background: #2c6ebd;
				box-shadow:0 2px #225694;

				&:hover {
					background: #4888d4;
				}
			}

			&.shareLine {
				background: #00C300;
				box-shadow: 0 2px #009d00;

				&:hover {
					background: #2bd92b;
				}
			}

			&.shareInstagram {
				background: #f13d53;
				box-shadow:0 2px #e6152f;

				&:hover {
					background: #f26f7f;
				}
			}

			a {
				height: 27px;
				min-height: 27px;
				color: #fff;
				width: 100%;
				display: inline-block;
				vertical-align: middle;
				font-size: 1.4rem;
				text-align: center;
				position: relative;
			}
		}
	}
}

/* テーブルリスト
-------------------------------------------------------------*/
table {
	width: 100%;
	margin: 0 auto 80px;

	// 横幅768px〜1200pxまで
	@include mq(md_jlg) {
		width: 100%;
	}

	// 横幅767pxまで
	@include mq(md) {
		width: 100%;
		margin: 0 auto 30px;
	}

	tr {
		// 横幅767pxまで
		@include mq(md) {
			display: block;
			position: relative;
		}

		th {
			width: 25%;
			padding: 20px 0;
			font-size: 1.6rem;
			background: $basicWhiteColor;
			word-break: break-all;
			box-sizing: border-box;

			// 横幅767pxまで
			@include mq(md) {
				width: 100%;
				display: block;
				border-bottom: none;
				padding-bottom: 4px;
			}
		}

		td {
			padding: 20px 0;
			font-size: 1.6rem;
			vertical-align: middle;
			background: $basicWhiteColor;
			word-break: break-all;
			box-sizing: border-box;

			&:first-of-type {
				// 横幅767pxまで
				@include mq(md) {
					padding: 0 0 7px;
				}
			}

			// 横幅767pxまで
			@include mq(md) {
				width: 100%;
				border: none;
				display: block;
				padding: 8px 0;
			}
		}
	}
}

/* パン屑リスト
-------------------------------------------------------------*/
.breadcrumb {
	@include innerWidth;
	clear: both;
	padding: 0 20px;

	// 横幅767pxまで
	@include mq(md) {
		margin: 0 0 79px;
		max-width: initial;
	}

	ol {
		@include clearfix;
		font-size: 1.2rem;
		word-break: break-all;
		padding: 0 0 15px;
		border-bottom: 1px solid $thinBorderColor;

		// 横幅767pxまで
		@include mq(md) {
			padding: 0 0 20px;
		}

		& > li {
			display: inline-block;
			font-weight: 700;
			padding: 0 0 0 17px;
			margin: 0 7px 0 0;
			position: relative;

			&:first-of-type {
				padding: 0;
			}

			&:last-of-type {
				margin: 0;
			}

			& + li:before {
				content: '';
				margin: 2px 0 0 0;
				position: absolute;
				left: 0;
				top: 0;
				background: url(/images/common/icon_arrow.svg) no-repeat;
				width: 6px;
				height: 14px;
				background-size: 6px 14px;
			}
		}

		a {
			font-weight: normal;
			color: $basicColor;
			transition: all .5s ease;

			&:hover {
				color: $mainColor;
			}
		}
	}

	// コンタクトページのみ採用
	&.contactPage {
		// 横幅767pxまで
		@include mq(md) {
			margin: 0;
		}
		ol {
			border-bottom: none;
		}
	}
}

/* オーバーレイのスタイル
-------------------------------------------------------------*/
// モーダルウィンドウ(オーバーレイ)
#modalOverlay {
	top: 0;
	left: 0;
	width: 100%;
	height: 120%;
	z-index: 1001;
	display: none;
	position: fixed;
	background-color: rgba(0,0,0, .8);
}

// モーダルウィンドウ(オーバーレイ)
#normalModalOverlay {
	top: 0;
	left: 0;
	width: 100%;
	height: 120%;
	z-index: 1001;
	display: none;
	position: fixed;
	background-color: rgba(0,0,0, .8);
}

// クリック領域オーバーレイ
#clickOverlay {
	top: 0;
	left: 0;
	width: 100%;
	height: 120%;
	z-index: 100;
	display: none;
	position: fixed;
}

/* グーグルマップのスタイル
-------------------------------------------------------------*/
.gmnoprint a,
.gmnoprint span {
	display: none;
}


/* ==========================================================================

メインコンテンツベース

========================================================================== */
.continer {
	@include clearfix;
	width: 100%;
	z-index: 100;
	background: $basicWhiteColor;
	position: relative;
	top: 0;
	left: 0;
	transform: translate3d(0,0,0);
	-webkit-backface-visibility: hidden;
	backface-visibility: hidden;
	overflow: hidden;

	&.under {
		margin: 424px 0 0;

		// 横幅767pxまで
		@include mq(md) {
			margin: 260px 0 0;
		}
	}

	.wrap {
		@include clearfix;
		background: $basicWhiteColor;

		// 共通スタイル
		section.mainArea {
			.inner {
				@include innerWidth;
				position: relative;
				padding: 0 20px 80px;

				// 横幅767pxまで
				@include mq(md) {
					padding: 0 20px 40px;
				}
			}
		}

		// 両幅の隙間調整
		.contentsBox {
			@include clearfix;
			padding: 0 9.2307%;

			// 横幅767pxまで
			@include mq(md) {
				padding: 0;
			}
		}

		// タイトルスタイル
		.sectionTtl {
			text-align: center;

			h2 {
				font-size: 2.8rem;
				position: relative;
				display: inline-block;
				letter-spacing: .15em;
				margin: 0 0 19px;
				font-weight: 600;

				// 横幅767pxまで
				@include mq(md) {
					font-size: 2.6rem;
				}

				&:before {
					content: '';
					display: block;
					position: absolute;
					background: $mainColor;
					height: 2px;
					width: 50px;
					bottom: -70px;
					top: 0;
					left: 0;
					right: 0;
					margin: auto;

					// 横幅767pxまで
					@include mq(md) {
						height: 1px;
					}
				}

				span {
					transition: opacity 1s 0.3s, transform 1s 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
				}
			}
		}

		// メインリードテキスト
		.mainReadTxt {
			padding: 70px 10%;
			line-height: 2.2;
			font-size: 1.6rem;

			// 横幅768px~1120pxまで
			@include mq(md_maxlg) {
				padding: 70px 0;
			}

			// 横幅767pxまで
			@include mq(md) {
				padding: 35px 0;
				line-height: 1.9;
			}
		}
	}
}


/* ==========================================================================

メインビジュアル（下層は見出し扱い）

========================================================================== */
#topVisual {
	width: 100%;
	height: 100vh;
	display: flex;
	align-items: center;
	justify-content: center;
	text-align: center;
	top: 0;
	left: 0;
	z-index: 1;
	position: absolute; // 固定化、もしくはそのままかを選択
	transform: translate3d(0,0,0);
	-webkit-backface-visibility: hidden;
	backface-visibility: hidden;
	overflow: hidden;

	h1 {
		z-index: 14;
		font-size: 1.8rem;
		margin: 195px 0 0;
		transition: all .5s ease;

		// 横幅767pxまで
		@include mq(md) {
			font-size: 2.5vw;
			margin: 24.25% 0 0;
		}

		// スマホ、タブレットで横にした時のフォントサイズ調整
		&.landChange {
			margin: 17.25% 0 0;
		}
	}

	#bg {
		height: 50%;
		width: 100%;
		top: 0;
		left: 0;
		z-index: 12;
		position: absolute;
		overflow: hidden; // 上下半分にする
		background: $mainColor; // 黄緑（デフォルト）

		&:before {
			content: '';
			position: absolute;
			z-index: -1;
			top: 0;
			bottom: 0;
			left: 0;
			right: 0;
			transform-origin: 50%;
			transition-property: transform;
			transition-duration: .7s;
			transition-timing-function: cubic-bezier(0.68, -0.55, 0.265, 1.55);
			transform: scale(0,0); //初期値
		}

		// スケールアニメーション
		&.scale1:before {transform: scale(1);}
		&.scale0:before {transform: scale(0);}
		&.scaleX1:before {transform: scaleX(1);}
		&.scaleX0:before {transform: scaleX(0);}
		&.scaleY1:before {transform: scaleY(1);}
		&.scaleY0:before {transform: scaleY(0);}

		// 背景色
		&.bg1 {
			background: $mainColor; // 黄緑
			&:before {
				background: $blueColor; // 青
			}
		}
		&.bg2 {
			background: $redColor; // 赤
			&:before {
				background: $blueColor; // 青
			}
		}
		&.bg3 {
			background: $redColor; // 赤
			&:before {
				background: $orengeColor; // 橙
			}
		}
		&.bg4 {
			background: $mainColor; // 黄緑
			&:before {	
				background: $orengeColor; // 橙
			}
		}

		// 文字を中央に寄せる
		.centerInner {
			width: 100%;
			right: 50%;
			bottom: 0;
			margin: 0;
			position: absolute;
			transform: translate(50%, 50%);

			p {
				white-space: nowrap;
				letter-spacing: .1em;
				transition: all .5s ease;
				color: $basicWhiteColor;
				font-size: 12.8rem;
				text-align: center;

				// 横幅767pxまで
				@include mq(md) {
					font-size: 15vw;
					line-height: 1.6;
				}

				// スマホ、タブレットで横にした時のフォントサイズ調整
				&.landChange {
					font-size: 9vw;
					line-height: 1.7;

					// フォント切り替え要素
					span {
						margin: -22px !important;
						width: 12% !important;

						// 「W」の文字調整
						&:nth-child(1) {
							width: 122px !important;
						}

						// 「I」の文字調整
						&:nth-child(10),
						&:nth-child(17) {
							width: 105px !important;
						}
					}
				}
			}
		}
	} 

	// フォント切り替え要素
	span {
		margin: -24px;
		width: 140px;
		height: 30px;
		display: inline-block;

		// 横幅767pxまで
		@include mq(md) {
			margin: -7%;
			width: 24%;
		}

		// 「W」の文字調整
		&:nth-child(1) {
			width: 175px;

			// 横幅767pxまで
			@include mq(md) {
				width: 49%;
				margin: -15.888%;
			}
		}

		// 「I」の文字調整
		&:nth-child(10),
		&:nth-child(17) {
			width: 122px;

			// 横幅767pxまで
			@include mq(md) {
				width: 21%;
			}
		}

		// 各フォントの指定
		&.nemoy {
			font-family: 'NemoyLight';
		}
		&.caesar {
			font-family: 'Caesar Dressing', cursive;
		}
		&.zilla {
			font-family: 'Zilla Slab Highlight', cursive;
		}
		&.alegreya {
			font-family: 'Alegreya SC', serif;
		}
		&.josefin {
			font-family: 'Josefin Slab', serif;
		}
		&.tangerin {
			font-family: 'Tangerine', cursive;
		}
		&.bungee {
			font-family: 'Bungee Shade', cursive;
		}
		&.akronim {
			font-family: 'Akronim', cursive;
		}
		&.fredericka {
			font-family: 'Fredericka the Great', cursive;
		}
		&.codystar {
			font-family: 'Codystar', cursive;
		}
		&.monoton {
			font-family: 'Monoton', cursive;
		}
		&.gaegu {
			font-family: 'Gaegu', cursive;
		}
	}

	.mainTxt {
		overflow: hidden;
		white-space: nowrap;
		letter-spacing: .1em;
		transition: all .5s ease;
		width: 100%;
		font-size: 12.8rem;
		right: 50%;
		bottom: 50%;
		margin: 0;
		position: absolute;
		transform: translate(50%, 50%);

		// 横幅767pxまで
		@include mq(md) {
			font-size: 15vw;
			line-height: 1.6;
		}

		// スマホ、タブレットで横にした時のフォントサイズ調整
		&.landChange {
			font-size: 9vw;
			line-height: 1.7;

			// フォント切り替え要素
			span {
				margin: -22px !important;
				width: 12% !important;

				// 「W」の文字調整
				&:nth-child(1) {
					width: 122px !important;
				}

				// 「I」の文字調整
				&:nth-child(10),
				&:nth-child(17) {
					width: 105px !important;
				}
			}
		}
	}

	// 下層ページ用スタイル
	&.under {
		height: 424px;

		// 横幅767pxまで
		@include mq(md) {
			height: 283px;
		}

		#bg p,
		.centerInner,
		.mainTxt {
			font-size: 7.2rem !important;		

			// 横幅767pxまで
			@include mq(md) {
				font-size: 12.5vw !important;

				span {
					// 横幅767pxまで
					@include mq(md) {
						margin: -3%;
						width: 16.5%;
					}
				}

				// スマホ、タブレットで横にした時のフォントサイズ調整
				&.landChange {
					line-height: 1.7;

					// フォント切り替え要素
					span {
						margin: -39px !important;
						width: 24% !important;

						// 「W」「I」の文字調整
						&:nth-child(1),
						&:nth-child(10),
						&:nth-child(17) {
							margin: -39px !important;
							width: 24% !important;
						}
					}
				}
			}
		}

		// 下層ページで文字が長い場合に適用
		#bg p.mini,
		.centerInner.mini,
		.mainTxt.mini {
			font-size: 6rem;

			span {
				margin: -12px;
				width: 68px;

				// 横幅767pxまで
				@include mq(md) {
					margin: -9%;
					width: 24%;
				}
			}

			&.landChange {
				span {
					margin: -12px !important;
					width: 7.88% !important;

					// 横幅767pxまで
					@include mq(md) {
						margin: -12px !important;
						width: 10.88% !important;
					}
				}
			}

			// 横幅767pxまで
			@include mq(md) {
				font-size: 9vw !important;
			}
		}

		// フォント切り替え要素
		span {
			margin: -14px;
			width: 80px;

			// 横幅767pxまで
			@include mq(md) {
				margin: -9%;
				width: 25%;
			}

			// 「W」「I」の文字調整
			&:nth-child(1),
			&:nth-child(10),
			&:nth-child(17) {
				margin: -14px;
				width: 80px;

				// 横幅767pxまで
				@include mq(md) {
					margin: -9%;
					width: 25%;
				}
			}
		}

		h1 {
			margin: 180px 0 0;

			// 横幅767pxまで
			@include mq(md) {
				font-size: 4vw;
				margin: 23.555% 0 0;
			}
		}
	}
}