@charset "utf-8";

/* btnBace使用例：@extend %btnBace;
-------------------------------------------------------------*/
%btnBace {
	margin: 0 auto;
	width: 280px;
	
	// 横幅767pxまで
	@include mq(md) {
		width: 100%;
	}

	a,
	button,
	input {
		letter-spacing: .05em;
		width: 100%;
		line-height: 57px;
		height: 57px;
		display: block;
		text-align: center;
		overflow: visible;
		position: relative;
		color: $basicColor;
		font-size: 1.6rem;
		font-weight: 700;
		box-sizing: border-box;
		background: $basicWhiteColor;
		transition: all .5s ease;
		box-shadow: 0 0 0 2px $basicColor inset;

		// 横幅767pxまで
		@include mq(md) {
			margin: 0 auto;
			width: 280px;
			max-width: 280px;
			box-shadow: 0 0 0 1px $basicColor inset;
		}

		&.modan:hover {
			cursor: pointer;
			box-shadow: 0 0 0 2px $mainColor inset;

			// 横幅767pxまで
			@include mq(md) {
				box-shadow: 0 0 0 1px $mainColor inset;
			}
		}

		&:after {
			position: absolute;
			content: '';
			margin: auto 0;
		}

		// 右の矢印が短いスタイル
		&.short {
			&:after {
				position: absolute;
				content: '';
				margin: auto 0;
				top: 23px;
				background: url(/images/common/icon_short_btn_arrow.png) no-repeat;
				background-size: 36px 7px;
				width: 36px;
				height: 7px;
				right: -11px;
				transition: all .5s ease;
			}
			&:hover:after {
				right: -30px;
				transition-timing-function: cubic-bezier(0.52, 1.64, 0.37, 0.66);
			}
		}

		// 右の矢印が長いスタイル
		&.long {
			&:after {
				position: absolute;
				content: '';
				margin: auto 0;
				top: 23px;
				background: url(/images/common/icon_long_btn_arrow.png) no-repeat;
				background-size: 58px 7px;
				width: 58px;
				height: 7px;
				right: -11px;
				transition: all .5s ease;
			}
			&:hover:after {
				right: -30px;
				transition-timing-function: cubic-bezier(0.52, 1.64, 0.37, 0.66);
			}
		}

		// 戻るボタン
		&.backBtn {
			&:hover {
				box-shadow: 0 0 0 2px $basicColor inset !important;

				// 横幅767pxまで
				@include mq(md) {
					box-shadow: 0 0 0 1px $basicColor inset !important;
				}
			}
			&:before {
				background: $basicColor;
			}
			&:after {
				background: url(/images/common/icon_long_btn_arrow_g_l.png) no-repeat;
				background-size: 58px 7px;
				width: 58px;
				height: 7px;
				left: -11px;
			}
			&:hover:before {
				background: $basicColor;
			}
			&:hover:after {
				left: -30px;
				transition-timing-function: cubic-bezier(0.52, 1.64, 0.37, 0.66);
			}

			// SPのホバー機能をなくす
			&.spNotHover {
				&:hover:after {
					// 横幅767pxまで
					@include mq(md) {
						left: -11px;
					}
				}
			}
		}

		// ダウンロードマーク
		&.download {
			position: relative;
			&:after {
				z-index: 2;
				content: '';
				position: absolute;
				margin: auto 0;
				left: 22px;
				top: 16px;
				width: 24px;
				height: 24px;
				background-size: 24px 24px;
				transition: all .5s ease;
				background: url(/images/common/icon_download.svg) no-repeat;
			}
		}
		&.download.modan:hover:after {
			width: 24px;
			height: 24px;
			background-size: 24px 24px;
			transition: all .5s ease;
			background: url(/images/common/icon_download_w.svg) no-repeat;
		}

		// SPのホバー機能をなくす
		&.spNotHover {
			&:before {
				display: none;
			}
			&:hover {
				color: $basicColor !important;
				box-shadow: 0 0 0 1px $basicColor inset !important;
			}
			&:hover:after {
				// 横幅767pxまで
				@include mq(md) {
					right: -11px;
				}
			}
		}
	}
}

/* ホバーアニメーション
-------------------------------------------------------------*/
// 背景が左から右へ切り替わる
.btnRightToAnimation {
	transform: perspective(1px) translateZ(0);
	transition: color .5s;

	&:before {
		content: '';
		position: absolute;
		z-index: -1;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		background: $mainColor;
		transform: scaleX(0);
		transform-origin: 0 50%;
		transition: transform .5s ease;
	}
	&:hover {
		color: $basicWhiteColor !important;
	}
	&:hover:before {
		transform: scaleX(1);
		transition-timing-function: cubic-bezier(0.52, 1.64, 0.37, 0.66);
	}
}

// 背景が右から左へ切り替わる
.btnLeftToAnimation {
	transform: perspective(1px) translateZ(0);
	transition: color .5s;

	&:before {
		content: '';
		position: absolute;
		z-index: -1;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		background: $mainColor;
		transform: scaleX(0);
		transform-origin: 100% 0;
		transition: transform .5s ease;
	}
	&:hover {
		color: $basicWhiteColor !important;
	}
	&:hover:before {
		transform: scaleX(1);
		transition-timing-function: cubic-bezier(0.52, 1.64, 0.37, 0.66);
	}
}

// 中央から全体に色が切り替わる
.btnOutVerticalAnimation {
	transform: perspective(1px) translateZ(0);
	box-shadow: 0 0 1px rgba(0, 0, 0, 0);
	position: relative;
	transition: color .3s;

	&:before {
		content: '';
		position: absolute;
		z-index: -1;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
		background: $mainColor;
		transform: scaleY(0);
		transform-origin: 50%;
		transition: transform .3s ease-out;
	}
	&:hover {
		color: $basicWhiteColor !important;
	}
	&:hover:before {
		transform: scaleY(1);
	}
}