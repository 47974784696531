@charset "utf-8";

/* ==========================================================================

印刷時の定義

========================================================================== */
@media print {
	.noPrint {
		display: none !important;
	}
	body {
		width: 100%;
		font-size: 14pt;
		-webkit-print-color-adjust: exact;
	}
}