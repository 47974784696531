@charset "utf-8";

/* ==========================================================================

フッター

========================================================================== */

.footer {
	width: 100%;
	overflow: hidden;
	background: $basicWhiteColor;

	.inner {
		@include innerWidth;
		padding: 0 20px;

		.topFooter {
			@include clearfix;
			padding: 91px 0 0;

			// 横幅767pxまで
			@include mq(md) {
				padding: 0 0 0;
			}

			.ttlBox {
				position: relative;
				float: left;
				margin-right: 7.55%;
				padding: 14px 5.952% 13px 6.428%;
				transition: all .5s ease;
				border-right: 2px solid $borderColor;

				// 横幅768px~1200pxまで
				@include mq(md_jlg) {
					float: none;
					padding: 14px 0 60px;
					text-align: center;
					margin-right: 0;
					border-right: none;

					&:before {
						background: $borderColor;
						width: 107px;
						height: 2px;
						position: absolute;
						top: 128px;
						bottom: 0;
						right: 0;
						left: 0;
						margin: 0 auto;
						content: '';
						z-index: 2;
					}
				}

				// 横幅767pxまで
				@include mq(md) {
					padding: 0 0 70px;
					float: none;
					text-align: center;
					margin-right: 0;
					border-right: none;

					&:before {
						background: $borderColor;
						width: 107px;
						height: 1px;
						position: absolute;
						top: 110px;
						bottom: 0;
						right: 0;
						left: 0;
						margin: 0 auto;
						content: '';
						z-index: 2;
					}
				}			

				h2 {
					color: $blueColor;
					font-family: $bebasneue-bold-font;
					font-size: 4.8rem;
					line-height: 1;
				}

				p {
					font-size: 1.6rem;
					padding: 7px 0 0;
					letter-spacing: .09em;
				}
			}

			.infoBox {
				@include clearfix;
				display: flex;
				align-content: space-between;
				transition: all .5s ease;

				// 横幅768px~1200pxまで
				@include mq(md_jlg) {
					padding: 0;
					width: 100%;
				}
				// 横幅767pxまで
				@include mq(md) {
					display: block;
					width: 100%;
					padding: 0;
				}

				.left {
					margin: 0 15.25% 0 0;

					// 横幅768px~1200pxまで
					@include mq(md_jlg) {
						margin: 0;
						width: 50%;
					}
					// 横幅767pxまで
					@include mq(md) {
						margin: 0 0 30px;
					}
				}

				.right {
					width: 41.99%;

					// 横幅768px~1200pxまで
					@include mq(md_jlg) {
						width: 50%;
					}
				}

				.left,
				.right {
					@include clearfix;
					white-space: nowrap;
					text-align: center;
					box-sizing: border-box;

					// 横幅767pxまで
					@include mq(md) {
						width: 100%;
						white-space: inherit;
					}

					h3 {
						font-size: 1.8rem;
						font-weight: 700;
						color: $blueColor;
						letter-spacing: .05em;
					}

					.tel {
						margin: 12px 0 3px;
						line-height: 1;
						letter-spacing: .05em;
						font-size: 3.6rem;
						font-family: $bebasneue-bold-font;

						a {
							line-height: 1;
							padding: 0 0 0 32px;
							color: $basicColor;
							position: relative;
							letter-spacing: .03em;

							&:before {
								position: absolute;
								content: '';
								z-index: 2;
								left: -2px;
								top: 1px;
								margin: auto 0;
								width: 26px;
								height: 26px;
								background: url(/images/common/icon_tel.svg) no-repeat;
								background-size: 26px 26px;
							}

							// Windowsの場合
							&.windowsActive {
								&:before {top: 6px;}
							}
						}
					}

					.businessHours {
						font-size: 1.4rem;
						letter-spacing: .05em;
					}
				}

				// お問い合わせはこちらボタン
				.btnBox {
					@extend %btnBace;
					width: 100%;
					margin: 13px auto 0;

					// 横幅768px~1200pxまで
					@include mq(md_jlg) {
						width: 335px;
					}

					a {
						padding: 0 0 0 11.2%;

						// 横幅767pxまで
						@include mq(md) {
							font-size: 1.4rem;
							padding: 0 0 0 35px;
						}
						&.modan:hover {
							box-shadow: 0 0 0 2px $blueColor inset;

							// 横幅767pxまで
							@include mq(md) {
								box-shadow: 0 0 0 1px $blueColor inset;
							}
						}
						&:before {
							background: $blueColor;
						}

						&:after {
							left: 7.88%;
							top: 21px;
							width: 23px;
							height: 17px;
							background: url(/images/common/icon_mail.svg) no-repeat;
							background-size: 23px 17px;
							transition: all .5s ease;

							// 横幅768px~1200pxまで
							@include mq(md_jlg) {
								left: 33px;
							}

							// 横幅767pxまで
							@include mq(md) {
								left: 24px;
							}
						}
						&.modan:hover:after {
							width: 23px;
							height: 17px;
							background: url(/images/common/icon_mail_w.svg) no-repeat;
							background-size: 23px 17px;
							transition: all .5s ease;
						}

						// SPのホバー機能をなくす
						&.spNotHover {
							&:hover {
								box-shadow: 0 0 0 2px $blueColor inset;

								// 横幅767pxまで
								@include mq(md) {
									box-shadow: 0 0 0 1px $blueColor inset;
								}
							}
							&:hover:after {
								background: url(/images/common/icon_mail.svg) no-repeat;
							}
						}
					}
				}
			}
		}

		.underFooter {
			@include clearfix;
			position: relative;
			padding: 40px 0 94px;
			border-top: 1px solid $thinBorderColor;
			margin: 148px 0 0;

			// 横幅767pxまで
			@include mq(md) {
				padding: 105px 0 58px;
				margin: 130px 0 0;
			}

			.backToTop {
				top: -60px;
				bottom: 0;
				left: 0;
				right: 0;
				margin: 0 auto;
				width: 68px;
				height: 110px;
				z-index: 12;
				overflow: hidden;
				position: absolute;

				a.hover {
					.cls,
					.gototopTxt {
						fill: $mainColor;
					}
					.cls,
					.arrows {
						stroke: $mainColor;
					}
				}

				a {
					transition: all .5s ease;
					display: block;

					.cls,
					.gototopTxt {
						transition: all .5s ease;
						fill: #525863;
					}

					.cls,
					.arrows {
						transition: all .5s ease;
						stroke: #525863;
					}

					.cls {
						transition: all .5s ease;
						stroke-width: 2px;
					}

					.gototopTxt {
						transition: all .5s ease;
						font-size: 1.8rem;
						text-anchor: middle;
						font-family: BebasNeueBold;
						letter-spacing: .08em;
					}

					.arrows {
						fill: none;
						stroke-width: 4px;
						fill-rule: evenodd;
					}		
				}
			}

			.copy {
				float: left;
				line-height: 1;
				margin: 0 0 0 45px;

				// 横幅767pxまで
				@include mq(md) {
					float: none;
					text-align: center;
					margin: 0;
				}

				small {
					font-size: 1.3rem;
				}
			}

			.footerList {
				float: right;
				margin: 0 47px 0 0;

				// 横幅767pxまで
				@include mq(md) {
					float: none;
					text-align: center;
					margin: 0 0 48px;
				}

				li {
					line-height: 1;
					display: inline-block;
					vertical-align: top;
					font-size: 1.4rem;
					margin: 0 0 0 30px;

					// 横幅767pxまで
					@include mq(md) {
						margin: 0;
					}

					a {
						color: $basicColor;
						transition: all .5s ease;
						&:hover {
							color: $mainColor;
						}
					}
				}
			}

			// SNSリンクボタンエリア
			.snsArea {
				display: none;
				margin: 58px 0 48px;

				// 横幅767pxまで
				@include mq(md) {
					display: block;
				}

				.snsTtl {
					white-space: nowrap;
					margin: 0 auto 26px;
					width: 40px;
					font-size: 1.4rem;
				}

				ul {
					display: flex;
					justify-content: center;

					li {
						margin-right: 5.4945%;

						&:last-of-type {
							margin-right: 0;
						}
					}

					a {
						svg {
							fill: $basicColor;
							transition: all .5s ease;
						}

						&:hover {
							svg {
								fill: $mainColor;
							}
						}
					}
				}
			}
		}
	}
}