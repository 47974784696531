@charset "utf-8";

/* ==========================================================================

SERVICE

========================================================================== */

.works {
	// 制作内容
	section.worksArea {
		padding-top: 20px;
		.inner {
			@include innerWidth;
			padding: 0 20px 80px;

			// 横幅767pxまで
			@include mq(md) {
				padding: 0 0 120px;
			}
            
            .worksLeadTxt{
                font-size: 1.8rem;
                font-weight: 500;
                line-height: 1.78;
                letter-spacing: 0.9px;
                text-align: center;
                color: #333333;
                margin-bottom: 80px;
                
                // 横幅767pxまで
                @include mq(md) {
                    padding: 0 20px 0;
                }
            }
            
            .worksNav{
                display: flex;
                justify-content: space-between;
                align-content: center;
                padding: 0 30px 20px;
                margin-bottom: 45px;
                border-bottom: solid 1px #dddddd;
                
                // 横幅767pxまで
                @include mq(md) {
                    padding: 0 20px 0;
                    margin-bottom: 90px;
                    border-bottom: none;
                    flex-wrap: wrap;
                    justify-content: center;
                }
            }
            
            .worksNavMenu{
                display: flex;
                
                @include mq(md) {
                    justify-content: space-evenly;
                    padding-bottom: 40px;
                    margin-bottom: 40px;
                    border-bottom: solid 1px #dddddd;
                    width: 100%;
                }
            }
            
            .worksNavList{
                margin-right: 30px;
                
                @include mq(md) {
                    margin-right: 0;
                }
                
                a{
                    font-size: 2.2rem;
                    font-family: $bebasneue-regular-font;
                    line-height: 1.45;
                    letter-spacing: 1.76px;
                    color: #888888;
                    transition: all .5s ease;
                    
                    &:hover{
                        opacity: 0.7;
                    }
                }
                
                &.active{
                    a{
                        font-size: 2.6rem;
                        font-weight: bold;
                        line-height: 1.23;
                        letter-spacing: 2.08px;
                        color: #01b2d8;
                        &::after{
                            content: "";
                            display: block;
                            width: 5px;
                            height: 5px;
                            margin: 3px auto 0;
                            background-color: #01b2d8;
                            border-radius: 50px;
                        }
                    }
                }
            }
            
            .download{
                max-width: 225px;
                width: 100%;
                
                @include mq(md) {
                    max-width: 100%;
                }
                
                a{
                    position: relative;
                    display: flex;
                    width: 100%;
                    font-size: 1.4rem;
                    font-weight: 500;
                    line-height: 2;
                    text-align: center;
                    vertical-align: middle;
                    color: #ffffff;
                    background-color: #525863;
                    padding: 8px 15px 7px;
                    transform: perspective(1px) translateZ(0);
                    box-shadow: 0 0 1px rgba(0, 0, 0, 0);
                    transition: color .3s;
                    justify-content: center;
                    
                    @include mq(md) {
                        max-width: 100%;
                        padding: 10px 0;
                    }
                    
                    &::before{
                        z-index: 2;
                        content: '';
                        display: block;
                        margin: auto 15px auto 0;
                        width: 20px;
                        height: 20px;
                        background-size: 20px 20px;
                        transition: all .5s ease;
                        background: url(/images/common/icon_download_w.svg) no-repeat;
                    }
                    
                    &::after {
                        content: '';
                        position: absolute;
                        z-index: -1;
                        top: 0;
                        left: 0;
                        right: 0;
                        bottom: 0;
                        background: #00b2d8;
                        transform: scaleY(0);
                        transform-origin: 50%;
                        transition: transform .3s ease-out;
                    }
                    
                    &:hover{
                        &::after {
                            transform: scaleY(1);
                        }
                    }
                }
            }
            
            .moreBtn{
                text-align: center;
                width: 100%;
                
                @include mq(md) {
                    padding: 0 20px;
                }
                
                &:hover{
                    opacity: 0.7;
                }
                
                span{
                    display: block;
                    font-family: $bebasneue-regular-font;
                    font-size: 2.2rem;
                    font-weight: bold;
                    line-height: 1.09;
                    letter-spacing: 1.76px;
                    color: #525863;
                    padding: 22px 0 17px;
                    border-top: solid 1px #525863;
                    border-bottom: solid 1px #525863;
                    cursor: pointer;

                    &.close{
                        &::after{
                            transform: rotate(135deg);
                            margin-top: 3px;
                        }
                        &:hover{
                            &::after{
                                transform: translateY(-3px) rotate(135deg);
                            }
                        }
                    }
                    
                    &::after{
                        content: "";
                        display: inline-block;
                        width: 8px;
                        height: 8px;
                        border-left: solid 2px #515864;
                        border-bottom: solid 2px #515864;
                        transform: rotate(-45deg);
                        vertical-align: middle;
                        margin-top: -3px;
                        margin-left: 15px;
                        transition: transform .3s ease-out;
                    }
                    
                    &:hover{
                        &::after{
                            transform: translateY(3px) rotate(-45deg);
                        }
                    }
                }
            }
            
            .worksContentsBox{
                padding: 0 30px;
                display: flex;
                flex-wrap: wrap;
                
                @include mq(md) {
                    padding: 0;
                }

                &.worksContentsAccordionBox{
                    display: none;
                }
                
                >.flex{
                    display: flex;
                    flex-wrap: wrap;
                }

                .worksBox{
                    max-width: 30%;
                    width: 100%;
                    margin: 0 0 75px 40px;
                    flex: 1 1 25.333%;
                    
                    @include mq(md) {
                        max-width: 100%;
                        flex: auto;
                        margin-left: 0;
                        
                        &:nth-of-type(2n){
                            margin: 0 0 20%;
                            .worksImgBox{
                                margin-right: 40px;
                            }
                        }
                        
                        &:nth-of-type(2n+1){
                            margin: 0 0 20%;
                            .worksImgBox{
                                margin-left: 40px;
                            }
                        }
                    }
                    
                    &:nth-of-type(6n+1){
                        margin-left: 0;
                    }
                    
                    a{
                        &:hover {
                            img{
                                transform: scale(1.1);

                                // 横幅767pxまで
                                @include mq(md) {
                                    transform: scale(1);
                                }
                            }
                        }
                    }
                    
                    .worksImgBox{
                        overflow: hidden;
                        box-shadow: 2px 5px 10px 0 rgba(51, 51, 51, 0.16);
                        position: relative;
                        
                        &:before{
                          content: "";
                          display: block;
                          padding-top: 100%;
                        }
                        
                        img{
                            position: absolute;
                            width: 100%;
                            height: 100%;
                            top: 0;
                            right: 0;
                            bottom: 0;
                            left: 0;
                            margin: auto;
                            object-fit: cover;
                            transition: all .3s ease;
                        }
                    }
                    
                    .worksTxtBox{
                        @include mq(md) {
                            padding: 0 40px;
                        }
                    }
                    
                    .worksCategory{
                        font-size: 1.2rem;
                        font-weight: 400;
                        line-height: 1.5;
                        color: #aaaaaa;
                        margin-top: 20px;
                        margin-bottom: 2px;
                        
                        @include mq(md) {
                            margin-bottom: 5px;
                        }
                    }
                    
                    .worksTit{
                        font-size: 1.5rem;
                        font-weight: 500;
                        line-height: 1.43;
                        color: #333333;
                        margin-bottom: 4px;
                        min-height: 21px;
                        
                        @include mq(md) {
                            font-weight: 600;
                            margin-bottom: 6px;
                        }
                    }
                    
                    .worksTag{
                        font-size: 1.2rem;
                        font-weight: 300;
                        line-height: 1.8;
                        color: #11a3c3;
                    }
                    
                }
            }
            
        }
        
        
        // 制作実績 記事ページ
        
        .worksArticleBox{     
            margin-bottom: 80px;
            
            .worksArticleTit{
                font-size: 2.4rem;
                font-weight: bold;
                line-height: 1.67;
                letter-spacing: 1.32px;
                text-align: center;
                color: #555555;
                margin-bottom: 60px;
                
                // 横幅767pxまで
                @include mq(md) {
                    font-size: 1.8rem;
                    margin-bottom: 40px;
                }
                
                &::after{
                    content: "";
                    display: block;
                    width: 35px;
                    height: 2px;
                    background-color: #01b2d8;
                    margin: 10px auto 0;
                    
                    @include mq(md) {
                        margin: 10px auto 0;
                    }
                }
            }
            
            .worksArticleLead{
                font-size: 2.8rem;
                font-weight: 400;
                line-height: 2.14;
                letter-spacing: 1.57px;
                text-align: center;
                color: #333333;
                margin-bottom: 90px;
                
                // 横幅767pxまで
                @include mq(md) {
                    font-size: 1.9rem;
                    margin-bottom: 60px;
                }
            }
            
            .worksArticleKv{
                overflow: hidden;
                position: relative;
                margin-bottom: 70px;
                
                @include mq(md) {
                    margin-bottom: 40px;
                }
                
                &:before {
                    content: "";
                    display: block;
                    padding-top: 41.4%;
                    
                    // 横幅767pxまで
                    @include mq(md) {
                        padding-top: 61.8%;
                    }
                }

                img {
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    top: 0;
                    right: 0;
                    bottom: 0;
                    left: 0;
                    margin: auto;
                    object-fit: cover;
                    
                    &.spOnly{
                        @include mq(md) {
                            display: block;
                        }
                    }
                }
            }
            
            .worksArticleDateArea{
                max-width: 1020px;
                width: 100%;
                padding: 0 20px;
                margin: 0 auto 100px;
                box-sizing: border-box;
                
                @include mq(md) {
                    margin-bottom: 60px;
                }
                
                .client{
                    font-size: 2rem;
                    font-weight: 500;
                    line-height: 1.8;
                    color: #333333;
                    border-bottom: solid 1px #01b2d8;
                    padding: 0 10px 15px;
                    margin-bottom: 30px;
                    
                    @include mq(md) {
                        font-size: 1.6rem;
                        padding: 0 0 10px;
                        margin-bottom: 15px;
                    }
                }
                
                .flexLayout{
                    display: flex;
                    padding: 0 10px;
                    
                    @include mq(md) {
                        flex-wrap: wrap;
                        padding: 0;
                    }
                    
                    .leftColumn{
                        margin-right: 5%;
                        width: 41%;
                        
                        @include mq(md) {
                            width: 100%;
                            margin-right: 0;
                            margin-bottom: 35px;
                        }
                    }
                    .rightColumn{
                        width: 59%;
                        
                        @include mq(md) {
                            width: 100%;
                        }
                    }
                }
                
                .dateList{
                    display: flex;
                    flex-wrap: wrap;
                    margin-bottom: 30px;
                    
                    @include mq(md) {
                        width: 100%;
                        margin-bottom: 25px;
                    }
                }
                
                .dateName{
                    font-size: 1.3rem;
                    font-weight: 300;
                    line-height: 1.6;
                    color: #333333;
                    width: 25%;
                    padding: 0 0 5px;
                    
                    @include mq(md) {
                        width: 30%;
                        margin-right: 2%;
                    }
                }
                .dateCon{
                    font-size: 1.3rem;
                    font-weight: 300;
                    line-height: 1.6;
                    color: #333333;
                    width: 75%;
                    padding: 0 0 5px;
                    
                    @include mq(md) {
                        width: 68%;
                    }
                    
                    a{
                        color: #01b2d8;
                        text-decoration: underline;
                        background-image: url(/images/works/link_icon.svg);
                        background-repeat: no-repeat;
                        background-position: right center;
                        padding: 0 12px 0 0;
                        word-break: break-all;
                    }
                }
                
                .freeArea{
                    display: flex;
                    flex-wrap: wrap;
                    
                    .freeTxt{
                        font-size: 1.5rem;
                        font-weight: 400;
                        line-height: 2;
                        text-align: center;
                        color: #333333;
                        padding: 7px 15px;
                        margin-right: 10px;
                        margin-bottom: 5px;
                        background-color: #f4f4f4;
                    }
                }
                
                .explanationTxt{
                    font-size: 1.6rem;
                    font-weight: 400;
                    line-height: 2;
                    letter-spacing: 0.8px;
                    color: #555555;
                } 
            }
            
            .column{
                padding: 80px 0 100px;
                &:nth-of-type(2n+1){
                    background-color: #f8f8f8;
                }
                &:nth-of-type(2n){
                    background-color: #fff;
                }
                
                @include mq(md) {
                    padding: 50px 0 60px;
                }
            }
            
            .worksArticleGallery{
                padding-bottom: 0;
                
                .oneColumn{
                    max-width: 1064px;
                    padding: 0 20px;
                    margin: 0 auto;
                    
                    .imgBox{
                        margin-bottom: 50px;
                        
                        &:last-of-type{
                            margin-bottom: 100px;
                        }
                        
                        @include mq(md) {
                            margin-bottom: 30px;
                            
                            &:last-of-type{
                                margin-bottom: 60px;
                            }
                        }
                    }
                }
                
                .twoColumn{
                    max-width: 1064px;
                    padding: 0 20px;
                    margin: 0 auto;
                    display: flex;
                    flex-wrap: wrap;
                    justify-content: center;
                    
                    @include mq(md) {
                        flex-wrap: wrap;
                    }
                    
                    .imgBox{
                        width: 48%;
                        margin: 0 auto 50px;
                        
                        @include mq(md) {
                            width: 100%;
                            margin: 0 0 30px;
                            
                            &:last-of-type{
                                margin-bottom: 60px;
                            }
                        }
                        
                        &:last-of-type{
                            margin-bottom: 100px;
                        }
                        
                        &:nth-of-type(2n+1){
                            margin-right: 1%;
                            
                            @include mq(md) {
                                margin-right: 0;
                            }
                        }
                        &:nth-of-type(2n){
                            margin-left: 1%;
                            
                            @include mq(md) {
                                margin-left: 0;
                            }
                        }
                    }
                }
                
                .slideColumn{
                    max-width: 1100px;
                    padding: 0 30px;
                    margin: 0 auto;
                    position: relative;
                    opacity: 0;
                    transition: opacity .25s ease;
                    box-sizing: border-box;
                    
                    @include mq(md) {
                        padding: 0;
                        margin: 0 auto;
                    }

                    &.slick-initialized {
                        opacity: 1;
                    }
                    
                    .slide{
                        margin-bottom: 100px;
                        
                        @include mq(md) {
                            margin-bottom: 60px;
                        }
                    }
                    
                    .slide-arrow{
                        position: absolute;
                        top: 42%;
                        transform: translateY(-42%);
                        width: 32px;
                        height: 32px;
                        border-right: solid 2px #515864;
                        border-bottom: solid 2px #515864;
                        z-index: 10;
                        
                        @include mq(md) {
                            width: 22px;
                            height: 22px;
                        }
                        
                        &:hover{
                            cursor: pointer;
                        }
                        
                        &.prev-arrow{
                            left: 10px;
                            transform: rotate(135deg);
                            
                            @include mq(md) {
                                left: 40px;
                            }
                        }
                        &.next-arrow{
                            right: 10px;
                            transform: rotate(-45deg);
                            
                            @include mq(md) {
                                right: 40px;
                            }
                        }
                    }
                }
            }
            
            .worksArticleProduction{
                .productionTit{
                    font-family: $bebasneue-regular-font;
                    font-size: 3.6rem;
                    font-weight: bold;
                    line-height: 1.11;
                    letter-spacing: 2.88px;
                    text-align: center;
                    color: #555555;
                    margin-bottom: 30px;

                    &::after {
                        content: "";
                        display: block;
                        width: 35px;
                        height: 2px;
                        background-color: #01b2d8;
                        margin: 10px auto 0;
                    }
                }
                
                .flexLayout{
                    display: flex;
                    justify-content: center;
                    padding: 0 20px;
                    
                    @include mq(md) {
                        flex-wrap: wrap;
                    }

                    p{
                        max-width: 360px;
                        width: 100%;
                        &:first-of-type{
                            margin-right: 50px;
                        }
                        
                        @include mq(md) {
                            max-width: 100%;
                            &:first-of-type{
                                margin-right: 0;
                            }
                        }
                    }
                    
                    ul{
                        max-width: 360px;
                        width: 100%;
                        &:first-of-type{
                            margin-right: 50px;
                        }
                        
                        @include mq(md) {
                            max-width: 100%;
                            &:first-of-type{
                                margin-right: 0;
                            }
                        }
                        
                        li{
                            font-size: 1.5rem;
                            font-weight: 400;
                            line-height: 2;
                            color: #333333;
                            position: relative;
                            padding-left: 15px;
                            margin-bottom: 3px;
                            
                            &::before{
                                content: "・";
                                display: inline-block;
                                position: absolute;
                                top: 0;
                                left: 0;
                            }
                        }
                    }
                }
            }
            
            .worksArticleOther{
                .otherTit{
                    font-family: $bebasneue-regular-font;
                    font-size: 3.6rem;
                    font-weight: bold;
                    line-height: 1.11;
                    letter-spacing: 2.88px;
                    text-align: center;
                    color: #555555;
                    margin-bottom: 50px;

                    &::after {
                        content: "";
                        display: block;
                        width: 35px;
                        height: 2px;
                        background-color: #01b2d8;
                        margin: 10px auto 0;
                    }
                }
                
                .backBtn{
                    letter-spacing: .05em;
                    width: 300px;
                    text-align: center;
                    position: relative;
                    color: #525863;
                    font-size: 1.6rem;
                    font-weight: 500;
                    box-sizing: border-box;
                    background: #fff;
                    transition: all .5s ease;
                    margin: 60px auto 0;
                    
                    a{
                        display: block;
                        width: 100%;
                        color: #5a5a5a;
                        padding: 13px 0;
                        box-shadow: 0 0 0 1px #707070 inset;
                        
                        &::before{
                            background: #00b2d8;
                        }
                        
                        &::after{
                            position: absolute;
                            content: '';
                            margin: auto 0;
                            top: 20px;
                            background: url(/images/common/icon_long_btn_arrow.png) no-repeat;
                            background-size: 58px 7px;
                            width: 58px;
                            height: 7px;
                            left: -16px;
                            transition: all .5s ease;
                            transform: scale(-1, 1);
                        }
                        
                        &:hover{
                            box-shadow: 0 0 0 1px #00b2d8 inset;
                            
                            &::after{
                                left: -30px;
                                transition-timing-function: cubic-bezier(.52,1.64,.37,.66);
                            }
                        }
                    }
                }
                
                .worksContentsBox {
                    max-width: 980px;
                    margin: 0 auto;
                    padding: 0 30px;
                    display: flex;
                    flex-wrap: wrap;

                    @include mq(md) {
                        padding: 0 20px;
                        justify-content: space-between;
                    }

                    .worksBox {
                        max-width: 287px;
                        width: 100%;
                        margin: 0 0 0 40px;
                        flex: 1 1 25.333%;
                        
                        &:first-of-type{
                            margin-left: 0;
                        }

                        @include mq(md) {
                            max-width: 46%;
                            margin-left: 0;
                            
                            &:nth-of-type(3){
                                display: none !important;
                            }
                        }

                        a {
                            &:hover {
                                img {
                                    transform: scale(1.1);

                                    // 横幅767pxまで
                                    @include mq(md) {
                                        transform: scale(1);
                                    }
                                }
                            }
                        }

                        .worksImgBox {
                            overflow: hidden;
                            box-shadow: 2px 5px 10px 0 rgba(51, 51, 51, 0.16);
                            position: relative;

                            &:before {
                                content: "";
                                display: block;
                                padding-top: 100%;
                            }

                            img {
                                position: absolute;
                                width: 100%;
                                height: 100%;
                                top: 0;
                                right: 0;
                                bottom: 0;
                                left: 0;
                                margin: auto;
                                object-fit: cover;
                                transition: all .3s ease;
                            }
                        }

                        .worksTxtBox {
                            @include mq(md) {
                                padding: 0;
                            }
                        }

                        .worksCategory {
                            font-size: 1.2rem;
                            font-weight: 400;
                            line-height: 1.5;
                            color: #aaaaaa;
                            margin-top: 20px;
                            margin-bottom: 2px;

                            @include mq(md) {
                                margin-bottom: 5px;
                            }
                        }

                        .worksTit {
                            font-size: 1.5rem;
                            font-weight: 500;
                            line-height: 1.43;
                            color: #333333;
                            margin-bottom: 4px;

                            @include mq(md) {
                                font-weight: 600;
                                margin-bottom: 6px;
                            }
                        }

                        .worksTag {
                            font-size: 1.2rem;
                            font-weight: 300;
                            line-height: 1.8;
                            color: #11a3c3;
                        }

                    }
                }
            }
            
        }
        
	}
}