@charset "utf-8";

/* ==========================================================================

MEMBER

========================================================================== */

.member {
	// メンバー紹介
	section.memberMainArea {
		.inner {
			@include innerWidth;
			position: relative;
			padding: 80px 20px;

			// 横幅767pxまで
			@include mq(md) {
				padding: 40px 20px;
			}
		}
	}
}