@charset "utf-8";

/* ==========================================================================

SERVICE

========================================================================== */

.service {
	// 制作内容
	section.serviceArea {
		padding-top: 20px;
		.inner {
			@include innerWidth;
			padding: 0 20px 80px;

			// 横幅767pxまで
			@include mq(md) {
				padding: 0 20px 40px;
			}

			li {
				float: left;
				width: 48.11320%;
				text-align: center;
				overflow: hidden;
				border-radius: 4px;
				margin: 0 3.666% 3.666% 0;

				&:nth-child(2n) {
					margin-right: 0;
				}

				&:nth-child(3),
				&:nth-child(4) {
					margin-bottom: 0;
				}

				// 横幅768px〜1200pxまで
				@include mq(md_jlg) {
					float: none;
					width: 100%;
					margin: 0 0 30px;
					height: auto !important;

					&:nth-child(3) {
						margin: 0 0 30px;
					}
				}

				// 横幅767pxまで
				@include mq(md) {
					float: none;
					width: 100%;
					margin: 0 0 30px;
					height: auto !important;

					&:nth-child(3) {
						margin: 0 0 30px;
					}
				}

				h3 {
					display: inline-block;
					letter-spacing: .15em;
					margin: 30px 0;
					font-weight: 600;

					// 横幅767pxまで
					@include mq(md) {
						margin: 20px 0;
					}

					span {
						margin: 10px 0 0;
						line-height: 1;
						display: block;
						font-size: 3rem;
						letter-spacing: .1em;
						font-family: $bebasneue-regular-font;
						transition: opacity 1s .3s, transform 1s .3s cubic-bezier(.215, .61, .355, 1);
					}
				}

				figure {
					padding: 20px;
					position: relative;
					text-align: center;
					transform: perspective(1px) translateZ(0);
					transition-property: color;
					transition-duration: .3s;

					&:before {
						content: '';
						position: absolute;
						z-index: -1;
						top: 0;
						left: 0;
						right: 0;
						bottom: 0;
						background: #fafafa;
						transform: scale(0);
						transition-property: transform;
						transition-duration: .3s;
						transition-timing-function: ease-out;
					}

					// スクロールして見えてきたらアニメーションスタート
					&.animeStart {
						&:before {
							transform: scale(1);
						}
					}

					svg {
						width: 75.45%;
						height: 220px;
						opacity: 0;

						// 横幅767pxまで
						@include mq(md) {
							width: 80%;
							height: auto;
						}
					}

					// ディレクションSVG
					#directionSvg {
						.directionOffset01{stop-color:#7F7F80;stop-opacity:.25;}
						.directionOffset02{stop-color:#7F7F80;stop-opacity:.12;}
						.directionOffset03{stop-color:#7F7F80;stop-opacity:.1;}
						.directionOffset04{stop-color:#B2B2B2;stop-opacity:.25;}
						.directionOffset05{stop-color:#B2B2B2;stop-opacity:.1;}
						.directionOffset06{stop-color:#B2B2B2;stop-opacity:.2;}

						// スクロールして見えてきたらアニメーションスタート
						&.animeStart {
							opacity: 1;
							.direction0{animation:show 2s both; animation-delay:1s; fill:#DFDFDF; position: relative;}
							.direction1{animation:show 3s both; animation-delay:.5s; fill:url(#direction1);}
							.direction2{animation:show .6s both; animation-delay:.5s; fill:#FFFFFF;}
							.direction3{animation:show 2s both; animation-delay:1s;fill:url(#direction2);}
							.direction4{animation:show 3s both; animation-delay:1.8s;fill:#18A6C9;}
							.direction5{animation:show 3s both; animation-delay:1s; fill:#BCBCBC;}
							.direction6{animation:show 2s both; animation-delay:1.8s; fill:#7ec6d8;}
							.direction7{animation:show 2s both; animation-delay:1s; fill:#18A6C9;}
						}
					}

					// デザインSVG
					#designSvg {
						.designOffset01{stop-color:#7F7F80;stop-opacity:.25;}
						.designOffset02{stop-color:#7F7F80;stop-opacity:.12;}
						.designOffset03{stop-color:#7F7F80;stop-opacity:.1;}

						// スクロールして見えてきたらアニメーションスタート
						&.animeStart {
							opacity: 1;
							.design0{animation:show 2s both; animation-delay:.5s; fill:url(#design1);}
							.design1{animation:show 2s both; animation-delay:1s; fill:url(#design2);}
							.design2{animation:show 2s both; animation-delay:1s; fill:#FFFFFF;}
							.design4{animation:show 2s both; animation-delay:.5s; fill:#D64287;}
							.design8{animation:show 2s both; animation-delay:.5s; fill:#B43377;}
							.design5{animation:show 2s both; animation-delay:2s; fill:url(#design3);}
							.design6{animation:show 2s both; animation-delay:2s; fill:url(#design4);}
							.design7{animation:show 2s both; animation-delay:2s; fill:url(#design5);}
							.design9{opacity:.2;fill:none;enable-background:new;}
							.design10{animation:show 2s both; animation-delay:2s; fill:#D64287;}
							.design3{animation:show 1s both; animation-delay:2.5s; fill:#9E9E9F;}
						}
					}

					// プログラミングSVG
					#programmingSvg {
						.programmingOffset01{stop-color:#7F7F80;stop-opacity:.25;}
						.programmingOffset02{stop-color:#7F7F80;stop-opacity:.12;}
						.programmingOffset03{stop-color:#7F7F80;stop-opacity:.1;}

						// スクロールして見えてきたらアニメーションスタート
						&.animeStart {
							opacity: 1;
							.programming0{animation:show 1s both; animation-delay:.5s; fill:url(#programming1);}
							.programming1{animation:show 1s both; animation-delay:.5s; fill:#FFFFFF;}
							.programming6{animation:show 1s both; animation-delay:2s; fill:#F4A814;}
							.programming7{animation:show 1s both; animation-delay:1s; fill:url(#programming4);}
							.programming8{animation:show 1s both; animation-delay:2s; fill:url(#programming5);}
							.programming11{animation:show 1s both; animation-delay:1s; fill:#F4A814;}
							.programming2{animation:show 1s both; animation-delay:1.8s; fill:#B4B4B5;}
							.programming3{animation:show 1s both; animation-delay:1.8s; fill:url(#programming2);}
							.programming4{animation:show 1s both; animation-delay:1.8s; fill:#F4F4F4;}
							.programming5{animation:show 1s both; animation-delay:1.3s; fill:url(#programming3);}
							.programming9{animation:show 1s both; animation-delay:1.3s; fill:#FFFFFF;}
							.programming10{animation:show 1s both; animation-delay:1.3s; fill:#F4A814;}
							.programming12{animation:show 1s both; animation-delay:1.3s; fill:#F4F4F4;}
							.programming13{animation:show 1.2s both; animation-delay:2s; fill:#FFFFFF;}
							.programming14{opacity:.5;}
						}
					}

					// システム / サーバーSVG
					#systemServerSvg {
						.systemServer3{opacity:.7;}
						.systemServer4{opacity:.6;}
						.systemServer5{opacity:.5;}
						.systemServer6{opacity:.4;}
						.systemServer7{opacity:.3;}
						.systemServer16{opacity:.2;fill:#FFFFFF;enable-background:new;}
						.systemServerOffset01{stop-color:#7F7F80;stop-opacity:.25;}
						.systemServerOffset02{stop-color:#7F7F80;stop-opacity:.12;}
						.systemServerOffset03{stop-color:#7F7F80;stop-opacity:.1;}

						// スクロールして見えてきたらアニメーションスタート
						&.animeStart {
							opacity: 1;
							.systemServer0{animation:show .8s both; animation-delay:.5s; fill:url(#systemServer1);}
							.systemServer1{animation:show .8s both; animation-delay:.5s; fill:#FFFFFF;}
							.systemServer15{animation:show .8s both; animation-delay:.5s; fill:url(#systemServer6);}
							.systemServer2{animation:show 1s both; animation-delay:1s; fill:#85CABC;}
							.systemServer8{animation:show 1s both; animation-delay:1s; fill:#0DAC67;}
							.systemServer9{animation:show 1s both; animation-delay:1s; fill:url(#systemServer2);}
							.systemServer10{animation:show 1s both; animation-delay:1s; fill:url(#systemServer3);}
							.systemServer11{animation:show 1s both; animation-delay:1s; fill:url(#systemServer4);}
							.systemServer18{animation:show .8s both; animation-delay:1s; fill:#F4F4F4;}
							.systemServer17{animation:show 2s both; animation-delay:2.2s; fill:#0DAC67;}
							.systemServer12{animation:show .8s both; animation-delay:1.5s; fill:#B4B4B5;}
							.systemServer13{animation:show .8s both; animation-delay:1.5s; fill:url(#systemServer5);}
							.systemServer14{animation:show .8s both; animation-delay:1.5s; fill:#F4F4F4;}
						}
					}
				}

				p {
					text-align: left;
					font-size: 1.6rem;
					line-height: 1.7;
					padding: 30px 0;

					// 横幅767pxまで
					@include mq(md) {
						padding: 20px 0;
					}
				}

				// ディレクション
				&.direction {
					h3 {
						color: $blueColor;
					}
				}
				// デザイン
				&.design {
					h3 {
						color: $redColor;
					}
				}
				// プログラミング
				&.programming {
					h3 {
						color: $orengeColor;
					}
				}
				// システム / サーバー
				&.systemServer {
					h3 {
						color: $greenColor;
					}
				}
			}

			.btnBox {
				@extend %btnBace;
				clear: both;
				margin: 0 auto;
				padding: 80px 0 0;

				// 横幅767pxまで
				@include mq(md) {
					width: 280px;
					padding: 40px 0 0;
				}
			}
		}
	}

	// 得意分野
	section.specialtyArea {
		background: $bgColor;

		.inner {
			@include innerWidth;
			padding: 0 20px 60px;

			// 横幅767pxまで
			@include mq(md) {
				padding: 0 20px 30px;
			}
		}
	}
}