@charset "utf-8";

/* ==========================================================================

CONTACT

========================================================================== */

.contact {
	section.contactArea {
		.inner {
			@include innerWidth;
			padding: 0 20px 80px;

			// 横幅767pxまで
			@include mq(md) {
				padding: 0 20px 40px;
			}

			// リードテキスト
			.readTxt {
				@include typography;
				width: 80%;
				margin: 0 auto 50px;
				font-size: 1.4rem;
				line-height: 1.7;

				// 横幅768px〜1200pxまで
				@include mq(md_jlg) {
					width: 100%;
				}

				// 横幅767pxまで
				@include mq(md) {
					width: 100%;
					margin: 0 0 25px;
					line-height: 1.7;
				}
			}

			// 必須項目のスタイル
			.required {
				color: $keyColor;
				text-align: center;
				display: inline-block;
				line-height: 1;
			}

			// テーブルスタイル
			table {
				tr {
					border: none;
				}
				th,td {
					border: none;

					// 必須項目のスタイル
					.required {
						font-size: 1.2rem;
					}

					&:first-of-type {
						border: none;

						// 横幅767pxまで
						@include mq(md) {
							border: none;
						}
					}

					input {
						&.widthS {
							width: 50%;

							// 横幅767pxまで
							@include mq(md) {
								width: 100%;
							}
						}
						&.widthL {
							width: 80%;

							// 横幅767pxまで
							@include mq(md) {
								width: 100%;
							}
						}
					}
				}
				th {
					width: 28%;
					padding: 30px 10px 0 0;

					// 横幅767pxまで
					@include mq(md) {
						width: 100%;
						padding: 15px 0 4px;
					}
				}
				td {
					padding: 20px 0;

					// 横幅767pxまで
					@include mq(md) {
						padding: 0 0 7px;
					}
				}

				// 確認画面用
				&.confirmTable {
					width: 80%;
					margin: 80px auto;

					// 横幅768px〜1200pxまで
					@include mq(md_jlg) {
						width: 100%;
						margin: 80px auto;
					}

					// 横幅767pxまで
					@include mq(md) {
						width: 100%;
						margin: 30px auto;
					}

					th {
						padding: 20px 0;
						border-bottom: 1px solid #f0f0f0;

						// 横幅767pxまで
						@include mq(md) {
							width: 100%;
							font-weight: 700;
							padding: 10px;
							background: $bgColor;
							border: none;
						}
					}

					td {
						border-bottom: 1px solid #f0f0f0;
						// 横幅767pxまで
						@include mq(md) {
							width: 100%;
							padding: 10px;
							border: none;
						}
					}
				}
			}

			// 文字制限
			.characterLimitTxt {
				text-align: right;
				font-size: 1.2rem;
				width: 80%;
				padding: 3px 0 0;
				line-height: 1;

				// 横幅767pxまで
				@include mq(md) {
					width: 100%;
				}
			}

			// エラースタイル
			.errorTxt {
				color: $keyColor;
				font-size: 1.3rem;
				margin: 5px 0 0;

			}
			.inputBox.error {
				input,
				textarea {
					background: $keyColor;
				}
			}

			// アクティブボタンスタイル
			.btnBox {
				@extend %btnBace;
				margin: 0 auto;

				// 横幅767pxまで
				@include mq(md) {
					width: 280px;
				}

				button {
					cursor: pointer;
				}
			}

			// 2ボタンのスタイル
			.twoSingBox {
				width: 100%;
				display: flex;
				justify-content: center;

				// 横幅767pxまで
				@include mq(md) {
					width: 100%;
					display: block;
					padding: 20px 0 0;
				}

				li.btnBox {
					margin: 0 37px 0 0;

					// 横幅767pxまで
					@include mq(md) {
						width: 280px;
						margin: 0 auto 20px;
					}

					&:last-of-type {
						margin: 0;

						// 横幅767pxまで
						@include mq(md) {
							margin: 0 auto;
						}
					}
				}
			}

			// 不正なアクセス時のタイトルスタイル
			.errorTtl {
				font-size: 2rem;
				text-align: center;
				margin: 0 0 100px;
				line-height: 1;

				// 横幅767pxまで
				@include mq(md) {
					margin: 0 0 60px;
				}	
			}

			// 完了画面
			.completeBox {
				font-size: 1.6rem;
				text-align: center;
				margin: 0 0 80px;
				line-height: 2;

				// 横幅767pxまで
				@include mq(md) {
					line-height: 1.7;
					text-align: left;
					margin: 0 0 40px;
				}
			}
		}	
	}
}