@charset "utf-8";

// ブレークポイント処理
// 使用例：@include mq(md) {}
@mixin mq($breakpoint: md) {
	@media #{map-get($breakpoints, $breakpoint)} {
		@content;
	}
}

// placeholderの色変更
// 使用例：@include placeholder-color(#999);
@mixin placeholder-color($color) {
	&:-moz-placeholder {
		color:$color;
	}
	&::-webkit-input-placeholder {
		color:$color;
	}
}

// clearfix
// 使用例：@include clearfix;
@mixin clearfix {
	&:after {
		content: "";
		display: table;
		clear: both;
	}
}

// ホバー時の下線部
// 使用例：@include underBar(#fff);
@mixin underBar($color) {
	color: $color;
	position: relative;
	display: inline-block;// 指定しないとIE11でおかしな挙動になる

	&:before {
		z-index: 2;
		position: absolute;
		top: 1.2em;
		left: 0;
		content: "";
		display: inline-block;
		width: 0;
		height: 1px;
		background: $color;
		transition: all .5s ease;
	}

	&:hover:before {
		width: 100%;
	}
}

// タイポグラフィ
// 使用例：@include typography;
@mixin typography {
	line-height: 1.6;
	font-size: 1.6rem;
	letter-spacing: .05em;

	// 横幅767pxまで
	@include mq(md) {
		line-height: 1.4;
	}
}

// 基本幅
// 使用例：@include innerWidth;
@mixin innerWidth {
	@include clearfix;
	max-width: $basicWidth;
	margin: 0 auto;

	// 横幅767pxまで
	@include mq(md) {
		max-width: initial;
	}
}

// gradient（グラデーション）
// 使用例： div {@include gradient(#ff00ff, #ff00cc, vertical);}
@mixin gradient($start-color, $end-color, $orientation) {
	background: $start-color;
	@if $orientation == vertical {
		// vertical
		background: -moz-linear-gradient(top,  $start-color 0%, $end-color 100%);
		background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,$start-color), color-stop(100%,$end-color));
		background: -webkit-linear-gradient(top,  $start-color 0%,$end-color 100%);
		background: -o-linear-gradient(top,  $start-color 0%,$end-color 100%);
		background: -ms-linear-gradient(top,  $start-color 0%,$end-color 100%);
		background: linear-gradient(to bottom,  $start-color 0%,$end-color 100%);
		filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='$start-color', endColorstr='$end-color',GradientType=0 );
	} @else if $orientation == horizontal {
		// horizontal
		background: -moz-linear-gradient(left,  $start-color 0%, $end-color 100%);
		background: -webkit-gradient(linear, left top, right top, color-stop(0%,$start-color), color-stop(100%,$end-color));
		background: -webkit-linear-gradient(left,  $start-color 0%,$end-color 100%);
		background: -o-linear-gradient(left,  $start-color 0%,$end-color 100%);
		background: -ms-linear-gradient(left,  $start-color 0%,$end-color 100%);
		background: linear-gradient(to right,  $start-color 0%,$end-color 100%);
		filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='$start-color', endColorstr='$end-color',GradientType=1 );
	} @else {
		// radial
		background: -moz-radial-gradient(center, ellipse cover,  $start-color 0%, $end-color 100%);
		background: -webkit-gradient(radial, center center, 0px, center center, 100%, color-stop(0%,$start-color), color-stop(100%,$end-color));
		background: -webkit-radial-gradient(center, ellipse cover,  $start-color 0%,$end-color 100%);
		background: -o-radial-gradient(center, ellipse cover,  $start-color 0%,$end-color 100%);
		background: -ms-radial-gradient(center, ellipse cover,  $start-color 0%,$end-color 100%);
		background: radial-gradient(ellipse at center,  $start-color 0%,$end-color 100%);
		filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='$start-color', endColorstr='$end-color',GradientType=1 );
	}
}
