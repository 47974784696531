@charset "utf-8";

/* ==========================================================================

ABOUT US

========================================================================== */

.aboutUs {
	// 会社理念
	section.ideaArea {
		padding-top: 20px; //調整
		.inner {
			@include innerWidth;
			padding: 0 20px 80px;

			// 横幅767pxまで
			@include mq(md) {
				padding: 0 20px 40px;
			}

			h3 {
				text-align: center;
				font-size: 2.5rem;
				line-height: 2.3;
				padding: 60px 0 120px;
				letter-spacing: .2em;

				// 横幅767pxまで
				@include mq(md) {
					padding: 30px 0 80px;
					line-height: 1.8;
					font-size: 2rem;
				}
			}

			h4 {
				text-align: center;
				font-size: 3rem;
				line-height: 1;
				letter-spacing: .1em;
				font-family: $bebasneue-bold-font;
			}

			p {
				text-align: center;
				font-size: 1.6rem;
				line-height: 2.6;
				padding: 35px 0 120px;

				// 横幅767pxまで
				@include mq(md) {
					line-height: 2.4;
					text-align: left;
					padding: 25px 0 80px;
				}

				&:last-of-type {
					padding-bottom: 0;
				}
			}
		}
	}

	// 会社情報
	section.infoArea {
		background: $bgColor;
		.inner {
			@include innerWidth;
			position: relative;
			padding: 80px 20px;

			// 横幅767pxまで
			@include mq(md) {
				padding: 40px 20px;
			}

			table {
				width: 90%;
				margin: 70px auto 0;
				font-size: 1.5rem;

				// 横幅767pxまで
				@include mq(md) {
					width: 100%;
					margin: 35px 0 0;
				}

				tr {
					border-bottom: 1px solid $bgColor;
					&:last-of-type {
						border-bottom: none;
					}
				}

				th {
					padding: 20px;

					// 横幅767pxまで
					@include mq(md) {
						width: 100%;
						font-size: 1.4rem;
						font-weight: 700;
						padding: 15px 20px 0 20px;
					}
				}

				td {
					padding: 20px;

					// 横幅767pxまで
					@include mq(md) {
						font-size: 1.4rem;
						padding: 5px 20px 15px 20px;
					}
				}
			}
		}
	}

	// 主要取引先
	section.mainClientArea {
		.inner {
			@include innerWidth;
			position: relative;
			padding: 80px 20px;

			// 横幅767pxまで
			@include mq(md) {
				padding: 40px 20px;
			}
		}
	}

	// グーグルマップ
	section.googleMapArea {
		margin: 0 0 60px;
		background: $bgColor;
		position: relative;
		padding-top: 35%;

		// 横幅767pxまで
		@include mq(md) {
			margin: 0 0 30px;
			padding-top: 100%;
		}

		// Google Mapを開くボタン
		.btnBox {
			@extend %btnBace;
			position: absolute;
			top: 10px;
			left: 10px;
			z-index: 2;
			width: 160px;
			border-radius: 2px;
			box-shadow: rgba(0, 0, 0, .3) 0px 1px 4px -1px;

			// 横幅767pxまで
			@include mq(md) {
				width: 160px;
			}

			a {
				padding: 0 0 0 31px;
				height: 42px;
				line-height: 42px;
				font-size: 1.2rem;
				box-shadow: none;
				border-radius: 2px;

				// 横幅767pxまで
				@include mq(md) {
					width: 160px;
					box-shadow: none;
				}
				&.modan:hover {
					box-shadow: none;
					// 横幅767pxまで
					@include mq(md) {
						box-shadow: none;
					}
				}
				&:after {
					left: 10px;
					top: 9px;
					width: 23px;
					height: 23px;
					background: url(/images/about_us/icon_map.svg) no-repeat;
					background-size: 23px 23px;
				}
				&.modan:hover:after {
					content: '';
					width: 23px;
					height: 23px;
					background: url(/images/about_us/icon_map_w.svg) no-repeat;
					background-size: 23px 23px;
				}

				// SPのホバー機能をなくす
				&.spNotHover {
					&:hover {
						box-shadow: none !important;
					}
					&:hover:after {
						content: '';
						width: 23px !important;
						height: 23px !important;
						background: url(/images/about_us/icon_map.svg) no-repeat !important;
						background-size: 23px 23px !important;
					}
				}
			}
		}

		#gMap {
			position: absolute;
			width: 100%;
			height: 100%;
			top: 0;
		}
	}
}