@charset "utf-8";

/* ==========================================================================

ヘッダー(下層用)

========================================================================== */

.header {
	position: absolute;
	z-index: 2;
	top: 0;
	left: 0;
	width: 100%;
	background: transparent;

	.inner {
		padding: 0 20px;

		// 横幅767pxまで
		@include mq(md) {
			padding: 8px 0;
		}

		.logo {
			display: inline-block;
			margin: 81px 0 0 -40px;
			transform: rotate(90deg);

			// 横幅767pxまで
			@include mq(md) {
				margin: 43px 0 0 -10px;
			}

			a {
				width: 120px;
				display: block;

				// 横幅767pxまで
				@include mq(md) {
					width: 80px;
					margin: 0 auto;
				}
			}
		}

		// トップメニュー
		#topMenu {
			float: right;
			text-align: right;
			margin: 26px 0 0;

			// 横幅767pxまで
			@include mq(md) {
				display: none;
			}

			li {
				margin: 0 0 18px;
				font-size: 1.8rem;
				line-height: 1;
				letter-spacing: .06em;
				font-family: $bebasneue-regular-font;

				&:last-of-type {
					margin-bottom: 0;
				}

				a {
					color: $basicWhiteColor;

					&.pcHover {
						@include underBar($basicWhiteColor);
					}
				}

				&.contactSub {
					margin: 30px 0 0;
				}
			}

			// 下層ページ用クラス
			// その他のページが増えてきたら以下のスタイルを使用する
			/*
			&.under {
				a {
					color: #363a42 !important;
					@include underBar(#363a42);

					&:before,
					&:hover:before {
						background: #363a42 !important;
					}
				}
			}
			*/
		}
	}
}

// SNSリンクボタンエリア
.snsLink {
	position: fixed;
	z-index: 101;
	bottom: 50px;
	left: 34px;
	width: 100%;
	width: auto;
	background: transparent;

	// 横幅767pxまで
	@include mq(md) {
		display: none;
	}

	// 下層ページ用クラス
	&.under {
		z-index: 101;
	}

	.snsTtl {
		float: left;
		color: #bfbfbf;
		position: relative;
		margin: 0 0 11px -4px;

		&:after {
			position: absolute;
			top: 0;
			left: 38px;
			bottom: 0;
			right: 0;
			margin: 0;
			z-index: 2;
			content: '';
			height: 8px;
			width: 54px;
			background: url(/images/common/img_top_arrow.svg) no-repeat;
			background-size: 54px 8px;
		}
	}

	ul {
		clear: both;
		padding: 61px 0 0;

		li {
			margin: 18px 0 0;
		}

		a {
			svg {
				fill: #bfbfbf;
				transition: all .5s ease;
			}

			&:hover {
				svg {
					fill: $basicColor;
				}
			}
		}	
	}
}


/* ==========================================================================

ハンバーガーボタン

========================================================================== */
.btnHunArea {
	width: 100%;
	position: fixed;
	top: 0;
	right: 0;
	z-index: 1002;
	display: none;
	padding: 0 20px;
	background: transparent;

	// 横幅767pxまで
	@include mq(md) {
		padding: 8px 0;
		display: block !important;
	}

	// ハンバーガーボタン
	.mainMenu {
		top: 20px;
		right: 20px;
		position: absolute;

		// 横幅767pxまで
		@include mq(md) {
			top: 27px;
			right: 20px;
		}

		a {
			width: 46px;
			height: 34px;
			display: block;
			margin: 0 0 0 -46px;

			span {
				top: 50%;
				right: 50%;
				width: 33px;
				height: 6px;
				display: block;
				position: absolute;
				text-indent: -9999px;
				background: $basicColor;
				transition: all .5s ease;
				margin: -3px 0 0 0;

				&:before {
					top: 50%;
					right: 0;
					width: 46px;
					height: 6px;
					content: '';
					display: block;
					position: absolute;
					background: $basicColor;
					transition: all .5s ease;
					margin-top: -17px;
				}

				&:after {
					top: 50%;
					right: 0;
					width: 24px;
					height: 6px;
					content: '';
					display: block;
					position: absolute;
					background: $basicColor;
					transition: all .5s ease;
					margin-top: 11px;
				}

				// クロスアイコン（閉じるボタン）
				&.iconClose {
					background: transparent;

					&:before,
					&:after {
						width: 46px;
						margin-top: -1px;
						background: $basicWhiteColor;
					}

					&:before {
						transform: rotate(-45deg);
					}

					&:after {
						transform: rotate(-135deg);
					}
				}
			}

			&.hover {
				span {
					background: #789807;

					&:before,
					&:after {background: #789807;}

					// クロスアイコン（閉じるボタン）
					&.iconClose {
						background: transparent !important;
					}
				}
			}
		}
	}
}


/* ==========================================================================

ドロワー

========================================================================== */
#drawer {
	display: none;
	position: fixed;
	top: 0;
	left: 0;
	z-index: 1001;
	width: 100%;
	height: 100%;
	background: $mainColor;

	// ハンバーガーアニメーション開く時
	@keyframes navActive {// mediatypeの内部で@keyframesを定義するとNG
		0% { clip-path:polygon(100% 0, 100% 0%, 100% 100%, 100% 100%); }
		50% { clip-path:polygon(50% 0, 100% 0, 100% 100%, 0 100%); }
		100% { clip-path:polygon(0 0, 100% 0, 100% 100%, 0 100%); }
	}

	// ハンバーガーアニメーション閉じる時
	@keyframes navPassive {// mediatypeの内部で@keyframesを定義するとNG
		0% { clip-path:polygon(0 0, 100% 0, 100% 100%, 0 100%) }
		50% { clip-path:polygon(50% 0, 100% 0, 100% 100%, 0 100%) }
		100% { clip-path:polygon(100% 0, 100% 0, 100% 100%, 100% 100%) }
	}

	// 開いた時のアニメーション
	&.active {
		display: block;
		animation: navActive .5s ease 1 both; // js側のだし分けの速度と同じよう設定する（重要）
	}

	// 閉じる時のアニメーション
	&.passive {
		display: block;
		animation: navPassive .5s ease-out 1 both; // js側のだし分けの速度と同じよう設定する（重要）
	}

	#drawerInner {
		top: -500px;
		position: relative;
		overflow-y: auto;
		display: flex;
		justify-content: center;
		align-items: center;

		li {
			line-height: 1;
			text-align: center;
			margin: 0 0 27px;
			font-size: 2.4rem;
			letter-spacing: .06em;
			font-family: $bebasneue-regular-font;

			// 横幅767pxまで
			@include mq(md) {
				font-size: 2.8rem;
			}

			a {
				color: $basicWhiteColor;

				&.pcHover {
					@include underBar($basicWhiteColor);
				}
			}

			&.contactSub {
				margin: 45px 0 0;
			}
		}
	}
}