@charset "utf-8";

/* ==========================================================================

トップページ

========================================================================== */
.index {

	// マウスアイコン
	.mouse {
		position: absolute;
		z-index: 15;
		bottom: 28px;
		left: 0;
		right: 0;
		margin: 0 auto;
		width: 37px;
		height: 72px;
		animation: mouseMove 3s ease infinite;

		.mouseMain,
		.mousetxt {
			transition: all .5s ease;
			fill: #00b2d8;
		}
		.mouseCircle {
			fill: #fff;
		}
		.mousetxt {
			font-size: 1.4rem;
			text-anchor: middle;
			font-family: BebasNeueBold;
		}

		// 横向き処理
		&.landChange {
			width: 20px;
			bottom: -21px;
		}

		// マウスアニメーション
		@keyframes mouseMove {// mediatypeの内部で@keyframesを定義するとNG
			0%,10%,100%,25%,5%,50%{transform: translateY(0)}
			20%,30%{transform: translateY(10px)}
		}
	}

	// WHO WE ARE?
	section.whoWeAreArea {
		visibility: hidden;
		position: relative;
		z-index: 5;

		&:before { // 図形（左側）
			position: absolute;
			top: 146px;
			left: -53px;
			content: '';
			width: 452px;
			height: 327px;
			transform: skew(18deg);
			background: $mainColor;
			transition: all .5s ease;
			opacity: .13;

			// 横幅426px〜767pxまで
			@include mq(md_maxmd) {
				width: 444px;
			}

			// 横幅767pxまで
			@include mq(md) {
				top: 60px;
				left: -53px;
				width: 199px;
			}
		}

		.inner {
			@include innerWidth;
			position: relative;
			padding: 0 20px;

			// 横幅767pxまで
			@include mq(md) {
				padding: 500px 20px 0;
			}

			.ttl {
				visibility: hidden;
				position: relative;
				top: 0;
				left: 25.25%;

				// 横幅767pxまで
				@include mq(md) {
					top: -234px;
					left: 76%;
				}

				&:before { // 背景図形
					position: absolute;
					top: -81px;
					left: 12px;
					content: '';
					width: 26px;
					height: 310px;
					z-index: 2;
					transform: skew(-4deg);
					background: $mainColor;
					opacity: .3;

					// 横幅767pxまで
					@include mq(md) {
						top: -89px;
						left: 13px;
						height: 353px;
						width: 30px;
						transform: skew(-4deg);
					}
				}

				h2 {
					white-space: nowrap;
					position: absolute;
					top: 22px;
					left: -121px;
					z-index: 3;
					font-size: 7.2rem;
					letter-spacing: -.02em;
					transform: rotate(90deg);
					font-family: $bebasneue-regular-font;
				}
			}

			.iclipBox {
				@include clearfix;
				position: relative;
				margin: 0 0 50px 37.539%;

				// 横幅767pxまで
				@include mq(md) {
					width: 100%;
					margin: 0;
				}

				.rightBox {
					width: 465px;
					box-sizing: border-box;
					position: relative;

					// 横幅767pxまで
					@include mq(md) {
						width: 100%;
					}

					h3 {
						width: 205px;
						display: block;
						margin: 0 0 40px 49px;
						transition: all .5s ease;
						-webkit-backface-visibility: hidden;
						backface-visibility: hidden;
						overflow: hidden;

						// 横幅767pxまで
						@include mq(md) {
							width: 120px;
							margin: 0 0 32px;
						}
					}

					@keyframes birdRot1 {// mediatypeの内部で@keyframesを定義するとNG
						0%{transform: rotate(9deg);}
						50%{transform: rotate(-9deg);}
						100%{transform: rotate(9deg);}
					}
					@keyframes birdRot2 {// mediatypeの内部で@keyframesを定義するとNG
						0%{transform: rotate(4deg);}
						50%{transform: rotate(-4deg);}
						100%{transform: rotate(4deg);}
					}

					ul {
						position: relative;
						li {
							&:nth-child(1) {
								width: 17px;
								height: 38px;
								position: absolute;
								top: -26px;
								right: 148px;
								animation: birdRot1 5s ease 0s infinite;
							}
							&:nth-child(2) {
								width: 14px;
								height: 41px;
								position: absolute;
								top: -28px;
								right: 131px;
								animation: birdRot1 5s ease 0s infinite;
							}
							&:nth-child(3) {
								width: 20px;
								height: 37px;
								position: absolute;
								top: -27px;
								right: 108px;
								animation: birdRot2 5s ease 0s infinite;
							}
							&:nth-child(4) {
								width: 15px;
								height: 40px;
								position: absolute;
								top: -28px;
								right: 59px;
								animation: birdRot1 3s ease 0s infinite;
							}
							&:nth-child(5) {
								width: 15px;
								height: 42px;
								position: absolute;
								top: -29px;
								right: 20px;
								animation: birdRot2 2s ease 0s infinite;
							}
						}
					}

					p {
						width: 465px;
						border-top: 3px solid $mainColor;
						@include typography;
						padding: 23px 0 0 48px;
						line-height: 3.1;
						box-sizing: border-box;

						// 横幅767pxまで
						@include mq(md) {
							width: 100%;
							border-top: 2px solid $mainColor;
							padding: 28px 0 0;
							line-height: 2.2;
						}
					}
				}
			}
		}
	}

	// 実績紹介 WORKS
	section.worksArea {
		visibility: hidden;
		position: relative;
		transition: all .5s ease;
		z-index: 4;
		margin: 400px 0 0;

		// 横幅767pxまで
		@include mq(md) {
			margin: 0;
		}
        
        &:before { // 図形（右側）
			position: absolute;
			top: 31px;
            right: calc(50% - 640px);
			content: '';
			width: 459px;
			height: 129px;
			background-image: url(/images/works/top_pickup_back.svg);
            background-repeat: no-repeat;
			transition: all .5s ease;

			// 横幅767px~1120pxまで
			@include mq(md_maxlg) {
				top: -1.5%;
				right: 0;
				width: 35%;
                background-size: contain;
                background-position: bottom;
			}

			// 横幅767pxまで
			@include mq(md) {
				top: 1.5%;
				right: 0;
				width: 65%;
                background-size: contain;
                background-position: bottom;
			}
		}

		&:after { // 図形（右側）
			position: absolute;
			top: 35%;
			right: -53px;
			content: '';
			width: 735px;
			height: 327px;
			transform: skew(-18deg);
			background: $blueColor;
			transition: all .5s ease;
			opacity: .13;
            z-index: -1;

			// 横幅767px~1120pxまで
			@include mq(md_maxlg) {
				top: 33%;
				right: -53px;
				width: 367px;
			}

			// 横幅767pxまで
			@include mq(md) {
				top: 26.5%;
				right: -53px;
				width: 199px;
			}
		}

		.inner {
			@include innerWidth;
			@include clearfix;
			position: relative;
			padding: 0 20px 82px;

			// 横幅767px~1120pxまで
			@include mq(md_maxlg) {
				padding: 0 20px 82px;
			}

			// 横幅767pxまで
			@include mq(md) {
				padding: 0 20px 31px;
			}

			.ttl {
				visibility: hidden;
				float: left;
				width: 125px;
				margin: 0 0 0 11.2%;
				padding: 20px 0 0;
				text-align: center;
				position: relative;
				color: $blueColor;
				border-top: 4px solid $blueColor;

				// 横幅767pxまで
				@include mq(md) {
					float: none;
					width: 125px;
					margin: 0;
				}

				p {
					line-height: 1;
					letter-spacing: .5em;
					font-size: 1.2rem;
					white-space: nowrap;
				}

				h2 {
					white-space: nowrap;
					padding: 0;
					line-height: 1;
					margin: 64px 0 0 -21px;
					font-size: 7.2rem;
					letter-spacing: -.02em;
					transform: rotate(90deg);
					font-family: $bebasneue-regular-font;

					// Windowsの場合
					&.windowsActive {
						margin: 64px 0 0 -9px;
					}
				}

				// ボタンスタイル
				.btnBox {
					@extend %btnBace;
					width: 53px;
					height: 191px;
					margin: 80px auto 0;

					// 制作実績一覧ボタン用スタイル
					&.mainBtn {
						a {
							width: 53px;
							height: 191px;
							line-height: inherit;
							position: relative;
							writing-mode: vertical-rl; //縦書き

							&.modan:hover {
								box-shadow: 0 0 0 2px $blueColor inset;
								// 横幅767pxまで
								@include mq(md) {
									box-shadow: 0 0 0 1px $blueColor inset;
								}
							}

							&:after {
								top: 0;
								bottom: -148px;
							}

							span {
								top: 0;
								left: 0;
								right: 0;
								bottom: 0;
								margin: auto 0;
								display: flex;
								justify-content: center;
								align-items: center;
								position: absolute;
							}
						}
					}
				}
			}

			.worksPickUpList {
				@include clearfix;
				float: left;
				max-width: 67.1%;
                width: 100%;
				margin: 68px 0 175px 2.91%;
                display: flex;
                
                // 横幅767px~1120pxまで
                @include mq(md_maxlg) {
                    margin: 68px 0 120px 2.91%;
                }

				// 横幅767pxまで
				@include mq(md) {
					float: none;
                    max-width: 100%;
					width: 100%;
					margin: 0 0 2.91%;
                    flex-wrap: wrap;
				}
                
                .worksPickUpBox{
                    max-width: 372px;
                    width: 100%;
                    
                    @include mq(md) {
                        max-width: 100%;
                    }
                    
                    &:nth-of-type(2){
                        margin-left: 6.7rem;
                        margin-top: 65px;
                        
                        @include mq(md) {
                            margin-left: 0;
                        }
                    }
                    
                    .worksPickUpParentCategory{
                        font-family: $bebasneue-regular-font;
                        font-size: 2.8rem;
                        line-height: 0.86;
                        letter-spacing: 1.4px;
                        color: #01b2d8;
                        margin-left: 3px;
                        &::before{
                            content: "";
                            display: inline-block;
                            width: 15px;
                            height: 2px;
                            background-color: #01b2d8;
                            vertical-align: middle;
                            margin-right: 6px;
                        }
                        
                        @include mq(md) {
                            font-size: 3.8rem;
                            &::before{
                                width: 25px;
                            }
                        }
                    }
                    
                    a{
                        &:hover {
                            img{
                                transform: scale(1.1);

                                // 横幅767pxまで
                                @include mq(md) {
                                    transform: scale(1);
                                }
                            }
                        }
                    }
                    
                    .pickUpImgBox{
                        width: 100%;
                        overflow: hidden;
                        position: relative;
                        box-shadow: 2px 5px 10px 0 rgba(51, 51, 51, 0.16);
                        
                        &:before{
                          content: "";
                          display: block;
                          padding-top: 100%;
                        }
                        
                        img{
                            position: absolute;
                            width: 100%;
                            height: 100%;
                            top: 0;
                            right: 0;
                            bottom: 0;
                            left: 0;
                            margin: auto;
                            object-fit: cover;
                            transition: all .3s ease;
                        }
                    }
                    
                    .pickUpTxtBox{
                        box-sizing: border-box;
                        position: absolute;
                        left: 0;
                        bottom: 0;
                        width: 100%;
                        background-color: rgba(255, 255, 255, 0.9);
                        padding: 10px 20px;
                    }
                    
                    .woksPickUpCategory{
                        font-size: 1.2rem;
                        font-weight: 400;
                        line-height: 1.5;
                        color: #aaaaaa;
                        margin-bottom: 2px;
                    }
                    
                    .woksPickUpTit{
                        font-size: 1.6rem;
                        font-weight: 500;
                        line-height: 1.5;
                        letter-spacing: 0.8px;
                        color: #333333;
                        margin-bottom: 9px;
                        
                        // 横幅767pxまで
                        @include mq(md) {
                            font-weight: 600;
                        }
                    }
                    
                    .worksPickUpTag{
                        font-size: 1.2rem;
                        font-weight: 300;
                        line-height: 1.8;
                        color: #11a3c3;
                        border-top: solid 1px #11a3c3;
                        padding-top: 5px;
                    }
                }
			}
            
            .worksTopList{
                max-width: 76%;
                width: 100%;
				margin: 68px auto 2.91%;
                display: flex;
                flex-wrap: wrap;
                
                // 横幅767px~1120pxまで
                @include mq(md_maxlg) {
                    max-width: 80%;
                    margin: 68px auto 2.91% 13%;
                }

				// 横幅767pxまで
				@include mq(md) {
					float: none;
                    max-width: 100%;
					width: 100%;
					margin: 25% 0 0 ;
                    flex-wrap: wrap;
				}
                
                .worksBox{
                    max-width: 30%;
                    width: 100%;
                    margin: 0 0 75px 40px;
                    flex: 1 1 25.333%;
                    
                    @include mq(md) {
                        max-width: 100%;
                        flex: auto;
                        
                        &:nth-of-type(2n){
                            margin: 0 40px 20% 0;
                        }
                        
                        &:nth-of-type(2n+1){
                            margin: 0 0 20% 40px;
                        }
                    }
                    
                    &:nth-of-type(6n+1){
                        margin-left: 0;
                    }
                    
                    a{
                        &:hover {
                            img{
                                transform: scale(1.1);

                                // 横幅767pxまで
                                @include mq(md) {
                                    transform: scale(1);
                                }
                            }
                        }
                    }
                    
                    .worksImgBox{
                        overflow: hidden;
                        box-shadow: 2px 5px 10px 0 rgba(51, 51, 51, 0.16);
                        position: relative;
                        
                        &:before{
                          content: "";
                          display: block;
                          padding-top: 100%;
                        }
                        
                        img{
                            position: absolute;
                            width: 100%;
                            height: 100%;
                            top: 0;
                            right: 0;
                            bottom: 0;
                            left: 0;
                            margin: auto;
                            object-fit: cover;
                            transition: all .3s ease;
                        }
                    }
                    
                    .worksCategory{
                        font-size: 1.2rem;
                        font-weight: 400;
                        line-height: 1.5;
                        color: #aaaaaa;
                        margin-top: 20px;
                        margin-bottom: 2px;
                        
                        @include mq(md) {
                            margin-bottom: 5px;
                        }
                    }
                    
                    .worksTit{
                        font-size: 1.5rem;
                        font-weight: 500;
                        line-height: 1.43;
                        color: #333333;
                        margin-bottom: 4px;
                        
                        @include mq(md) {
                            font-weight: 600;
                            margin-bottom: 6px;
                        }
                    }
                    
                    .worksTag{
                        font-size: 1.2rem;
                        font-weight: 300;
                        line-height: 1.8;
                        color: #11a3c3;
                    }
                    
                }
            }

			// ボタンスタイル
			.btnBox {
				clear: both;
				@extend %btnBace;
				margin: 0 auto;
                

				// 横幅767pxまで
				@include mq(md) {
					width: 280px;
				}

				a {
					&.modan:hover {
						box-shadow: 0 0 0 2px $blueColor inset;
						// 横幅767pxまで
						@include mq(md) {
							box-shadow: 0 0 0 1px $blueColor inset;
						}
					}
					&:before {
						background: $blueColor;
					}
				}
			}
		}
	}

	// 会社概要 ABOUT US
	section.aboutUsArea {
		visibility: hidden;
		position: relative;
		transition: all .5s ease;
		z-index: 7;
		margin-bottom: 345px;

		// 横幅767px〜1200pxまで
		@include mq(md_jlg) {
			margin: 0;
		}

		// 横幅426px〜767pxまで
		@include mq(md_maxmd) {
			margin: 0;
		}

		// 横幅767pxまで
		@include mq(md) {
			margin: 50px 0 0;
		}

		&:before { // 図形（左側）
			position: absolute;
			top: 0;
			left: -53px;
			content: '';
			width: 479px;
			height: 327px;
			transform: skew(18deg);
			background: $redColor;
			transition: all .5s ease;
			opacity: .1;

			// 横幅767pxまで
			@include mq(md) {
				top: 0;
				left: -53px;
				width: 199px;
			}
		}

		.inner {
			@include innerWidth;
			position: relative;
			padding: 0 20px;

			// 横幅767pxまで
			@include mq(md) {
				margin: 0 auto;
				padding: 0 20px;
			}

			.contents {
				width: 559px;
				position: relative;
				margin: 0 0 0 40px;

				// 横幅767px〜1200pxまで
				@include mq(md_jlg) {
					margin: 0 auto;
				}

				// 横幅767pxまで
				@include mq(md) {
					width: 100%;
					margin: 0;
				}
			}

			.ttl {
				visibility: hidden;
				width: 147px;
				margin: 0 0 0 30px;
				padding: 20px 0 0;
				text-align: center;
				color: $redColor;
				position: relative;
				border-top: 4px solid $redColor;

				// 横幅767pxまで
				@include mq(md) {
					float: right;
					margin: 0 0 2px;

					// Windowsの場合
					&.windowsActive {
						margin: 0 0 10px;
					}
				}

				p {
					line-height: 1;
					letter-spacing: .5em;
					font-size: 1.2rem;
					white-space: nowrap;
				}

				h2 {
					padding: 0;
					line-height: 1;
					white-space: nowrap;
					margin: 64px 0 0 -21px;
					font-size: 7.2rem;
					letter-spacing: -.02em;
					transform: rotate(90deg);
					font-family: $bebasneue-regular-font;

					// Windowsの場合
					&.windowsActive {
						margin: 64px 0 0 -9px;
					}
				}
			}

			figure {
				transition: all .5s ease;
				position: relative;
				overflow: hidden;
				margin: -21px 0 0 91px;

				// 横幅767pxまで
				@include mq(md) {
					clear: both;
					margin: 0 0 0 0;

					img {
						display: block;
						width: 100%;
					}
				}

				.txt {
					color: $basicWhiteColor;
					position: absolute;
					top: -38px;
					left: -95px;
					line-height: 1;
					white-space: nowrap;
					font-size: 7.2rem;
					letter-spacing: -.02em;
					transform: rotate(90deg);
					font-family: $bebasneue-regular-font;

					// 横幅767pxまで
					@include mq(md) {
						position: static;
						float: right;
						margin: -59px -14px -72px 0;
					}

					// Windowsの場合
					&.windowsActive {
						top: -31px;
						left: -89px;

						// 横幅767pxまで
						@include mq(md) {
							margin: -59px -20px -72px 0;
						}
					}	
				}
			}

			.exTxt {
				@include typography;
				padding: 31px 0 50px;
				line-height: 2.3;
				display: flex;
				justify-content: flex-end;
				box-sizing: border-box;

				// 横幅767pxまで
				@include mq(md) {
					width: 100%;
					line-height: 2.3;
					padding: 17px 0 40px;
					display: block;
				}
			}

			// ボタンスタイル
			.btnBox {
				@extend %btnBace;
				width: 236px;
				font-size: 1.2rem;
				margin: 0 auto;

				// 横幅767pxまで
				@include mq(md) {
					width: 236px;
				}

				a {
	 				// 横幅767pxまで
					@include mq(md) {
						width: 236px;
						max-width: 236px;
					}
					&.modan:hover {
						box-shadow: 0 0 0 2px $redColor inset;
						// 横幅767pxまで
						@include mq(md) {
							box-shadow: 0 0 0 1px $redColor inset;
						}
					}
					&:before {
						background: $redColor;
					}
				}
			}
		}
	}

	// サービス SERVICE
	section.serviceArea {
		@include clearfix;
		position: absolute;
		transition: all .5s ease;
		z-index: 6;
		right: 0%;
		left: 0%;

		// 横幅767px〜1200pxまで
		@include mq(md_jlg) {
			margin: 120px 0 0;
			position: relative;
		}

		// 横幅426px〜767pxまで
		@include mq(md_maxmd) {
			margin: -190px 0 0;
			position: relative;
		}

		// 横幅767pxまで
		@include mq(md) {
			position: relative;
			margin: 0;
		}

		&:before { // 図形（右側）
			position: absolute;
			top: -566px;
			right: -53px;
			content: '';
			width: 452px;
			height: 327px;
			transform: skew(-18deg);
			background: $orengeColor;
			transition: all .5s ease;
			opacity: .29;

			// 横幅767px〜1200pxまで
			@include mq(md_jlg) {
				top: 72px;
				width: 735px;
			}

			// 横幅426px〜767pxまで
			@include mq(md_maxmd) {
				top: 289px;
				width: 444px;
			}

			// 横幅767pxまで
			@include mq(md) {
				top: 201px;
				width: 253px;
			}
		}

		.inner {
			@include innerWidth;
			@include clearfix;
			position: relative;
			margin: 0 auto;
			padding: 0 20px;

			// 横幅767px〜1200pxまで
			@include mq(md_jlg) {
				margin: 0;
				float: none;
			}

			// 横幅426px〜767pxまで
			@include mq(md_maxmd) {
				padding: 0 20px 100px;
				float: none;
			}
			
			// 横幅767pxまで
			@include mq(md) {
				padding: 0 20px;
				float: none;
			}

			.contents {
				width: 602px;
				float: right;
				position: relative;
				margin: -450px 0 0;

				// 横幅767px〜1200pxまで
				@include mq(md_jlg) {
					float: none;
					margin: 0 auto;
				}

				// 横幅426px〜767pxまで
				@include mq(md_maxmd) {
					float: none;
					margin: 0 auto 130px;
				}

				// 横幅767pxまで
				@include mq(md) {
					float: none;
					width: 100%;
					margin: 50px 0 -70px;
				}
			}

			.ttl {
				visibility: hidden;
				width: 147px;
				margin: 0 0 0 30px;
				padding: 20px 0 0;
				text-align: center;
				color: $orengeColor;
				border-top: 4px solid $orengeColor;

				// 横幅767pxまで
				@include mq(md) {
					margin: 0;
				}

				p {
					line-height: 1;
					letter-spacing: .5em;
					font-size: 1.2rem;
					white-space: nowrap;
				}

				h2 {
					padding: 0;
					line-height: 1;
					white-space: nowrap;
					margin: 64px 0 0 -21px;
					font-size: 7.2rem;
					letter-spacing: -.02em;
					transform: rotate(90deg);
					font-family: $bebasneue-regular-font;

					// Windowsの場合
					&.windowsActive {
						margin: 64px 0 0 -9px;
					}
				}
			}

			ul {
				transition: all .5s ease;
				margin: -71px 0 0 108px;	

				// 横幅426px〜767pxまで
				@include mq(md_maxmd) {
					margin: -92px 21% 0 0;
				}

				// 横幅767pxまで
				@include mq(md) {
					margin: 107px 89px 0 0;
					float: right;
				}

				li {
					height: 100px;
					margin: 0 0 20px;
					display: flex;
					justify-content: center;
					align-items: center;
					position: relative;

					// 横幅767pxまで
					@include mq(md) {
						height: auto;
					}

					&:last-of-type {
						margin-bottom: 0;
					}

					&:before { // 背景ボーダー
						position: absolute;
						content: '';
						bottom: -20px;
						width: 2px;
						height: 20px;
						background: $orengeColor;
						transition: all .5s ease;
					}	

					img {
						display: block;
					}

					&:nth-child(1) {
						width: 288px;
						margin-left: 31px;

						// 横幅767pxまで
						@include mq(md) {
							width: 230px;
						}

						&:before {
							left: 71px;
						}

						img {
							width: 288px;

							// 横幅767pxまで
							@include mq(md) {
								width: 100%;
							}
						}
					}
					&:nth-child(2) {
						width: 292px;
						margin-left: 59px;

						// 横幅767pxまで
						@include mq(md) {
							width: 231px;
						}

						&:before {
							left: 43px;
						}

						img {
							width: 292px;

							// 横幅767pxまで
							@include mq(md) {
								width: 100%;
							}
						}
					}
					&:nth-child(3) {
						width: 323px;

						// 横幅767pxまで
						@include mq(md) {
							width: 258px;
							margin-left: 14px;
						}

						&:before {
							left: 102px;

							// 横幅767pxまで
							@include mq(md) {
								left: 88px;
							}
						}

						img {
							width: 323px;

							// 横幅767pxまで
							@include mq(md) {
								width: 100%;
							}
						}
					}
					&:nth-child(4) {
						width: 355px;
						margin-left: 31px;

						// 横幅767pxまで
						@include mq(md) {
							width: 283px;
							margin-left: 0;
						}

						&:before {
							display: none;
						}

						img {
							width: 355px;

							// 横幅767pxまで
							@include mq(md) {
								width: 100%;
							}
						}
					}
				}
			}

			.exTxt {
				@include typography;
				clear: both;
				padding: 26px 21px 22px 0;
				line-height: 2.3;
				display: flex;
				justify-content: flex-end;
				box-sizing: border-box;

				// 横幅767pxまで
				@include mq(md) {
					width: 100%;
					line-height: 2.3;
					padding: 17px 0 40px;
				}
			}

			// ボタンスタイル
			.btnBox {
				@extend %btnBace;
				width: 236px;
				font-size: 1.2rem;
				margin: 0 0 0 110px;

				// 横幅767px〜1200pxまで
				@include mq(md_jlg) {
					margin: 0 auto;
				}

				// 横幅767pxまで
				@include mq(md) {
					width: 236px;
					margin: 0 auto;
				}

				a {
	 				// 横幅767pxまで
					@include mq(md) {
						width: 236px;
						max-width: 236px;
					}
					&.modan:hover {
						box-shadow: 0 0 0 2px $orengeColor inset;
						// 横幅767pxまで
						@include mq(md) {
							box-shadow: 0 0 0 1px $orengeColor inset;
						}
					}
					&:before {
						background: $orengeColor;
					}
				}
			}
		}
	}

	// スタッフ紹介 MEMBER
	section.memberArea {
		visibility: hidden;
		clear: both;
		position: relative;
		background: $basicWhiteColor;
		z-index: 1;

		&:before { // 図形（右側）
			position: absolute;
			top: 0;
			right: -53px;
			content: '';
			width: 452px;
			height: 327px;
			transform: skew(-18deg);
			background: $mainColor;
			transition: all .5s ease;
			opacity: .15;

			// 横幅767px〜1200pxまで
			@include mq(md_jlg) {
			}

			// 横幅426px〜767pxまで
			@include mq(md_maxmd) {
			}

			// 横幅767pxまで
			@include mq(md) {
				top: 170px;
				width: 253px;
			}
		}

		.inner {
			@include innerWidth;
			position: relative;
			padding: 0 20px;

			// 横幅767pxまで
			@include mq(md) {
				padding: 0 20px;
			}

			.ttl {
				width: 147px;
				margin: 0 0 0 30px;
				padding: 20px 0 0;
				text-align: center;
				color: $mainColor;
				position: relative;
				border-top: 4px solid $mainColor;

				// 横幅767pxまで
				@include mq(md) {
					margin: 0;
				}

				p {
					line-height: 1;
					letter-spacing: .5em;
					font-size: 1.2rem;
					white-space: nowrap;
				}

				h2 {
					padding: 0;
					line-height: 1;
					white-space: nowrap;
					margin: 64px 0 0 -21px;
					font-size: 7.2rem;
					letter-spacing: -.02em;
					transform: rotate(90deg);
					font-family: $bebasneue-regular-font;

					// Windowsの場合
					&.windowsActive {
						margin: 64px 0 0 -9px;
					}
				}
			}

			// ボタンスタイル
			.btnBox {
				@extend %btnBace;
				width: 236px;
				font-size: 1.2rem;
				margin: 0;
				float: right;

				// 横幅767pxまで
				@include mq(md) {
					width: 236px;
					margin: 0 auto;
				}

				a {
	 				// 横幅767pxまで
					@include mq(md) {
						width: 236px;
						max-width: 236px;
					}
					&.modan:hover {
						box-shadow: 0 0 0 2px $orengeColor inset;
						// 横幅767pxまで
						@include mq(md) {
							box-shadow: 0 0 0 1px $orengeColor inset;
						}
					}
					&:before {
						background: $orengeColor;
					}
				}
			}
		}
	}

	// WANT TO WORK WITH US?
	.recruitArea {
		clear: both;
		position: relative;
		z-index: 10;
		
		// 横幅767pxまで
		@include mq(md) {
			margin: 0 0 79px;
		}

		.topInner {
			padding: 0 20px;
			@include innerWidth;
			overflow: hidden;
			height: 37px;
			position: relative;
			z-index: 10;
			width: 100%;

			// 横幅767pxまで
			@include mq(md) {
				height: 21px;

				// Windowsの場合
				&.windowsActive {
					height: 37px;
				}
			}

			.txt {
				transition: all .5s ease;
				position: absolute;
				top: -5px;
				left: 154px;
				line-height: 1;
				white-space: nowrap;
				font-size: 13rem;
				letter-spacing: -.01em;
				font-family: $bebasneue-regular-font;

				// 横幅767px〜1200pxまで
				@include mq(md_jlg) {
					left: 54px;
				}

 				// 横幅767pxまで
				@include mq(md) {
					font-size: 6.5rem;
					top: 0;
					left: 20px;
				}

				// Windowsの場合
				&.windowsActive {
					top: -18px;

	 				// 横幅767pxまで
					@include mq(md) {
						top: 6px;
					}
				}
			}
		}

		.photoBox {
			//overflow: hidden;
			height: 398px;
			background: url(/images/index/bg_recruit_pc.jpg) center center no-repeat;
			background-size: cover;

			// 横幅767pxまで
			@include mq(md) {
				height: 199px;
				background: url(/images/index/bg_recruit_sp.jpg) center top no-repeat;
				background-size: cover;
			}

			.inner {
				@include innerWidth;
				position: relative;
				padding: 0 20px;

				h2 {
					float: left;
					transition: all .5s ease;
					margin: -43px 0 0 134px;
					line-height: 1;
					color: $basicWhiteColor;
					white-space: nowrap;
					font-size: 13rem;
					letter-spacing: -.01em;
					font-family: $bebasneue-regular-font;

					// 横幅767px〜1200pxまで
					@include mq(md_jlg) {
						margin: -37px 0 0 34px;
					}

					// 横幅767pxまで
					@include mq(md) {
						font-size: 6.5rem;
						margin: -22px 0 0;
					}

					// Windowsの場合
					&.windowsActive {
						margin-top: -55px;

						// 横幅767pxまで
						@include mq(md) {
							margin-top: -34px;
						}
					}
				}

				.right {
					float: right;
					transition: all .5s ease;
					margin: 114px 133px 0 0;

					// 横幅767px〜1200pxまで
					@include mq(md_jlg) {
						margin: 114px 34px 0 0;
					}

					// 横幅767pxまで
					@include mq(md) {
						display: none;
					}			

					p {
						@include typography;
						color: $basicWhiteColor;
						font-size: 1.8rem;
						padding: 0 0 32px;
						line-height: 2.1;
						text-align: right;
						white-space: nowrap;
						font-weight: 700;

						// 横幅767px〜1200pxまで
						@include mq(md_jlg) {
							font-size: 1.6rem;
						}
					}

					// ボタンスタイル
					.btnBox {
						@extend %btnBace;
						width: 280px;
						margin: 0;
						float: right;

						// 横幅767pxまで
						@include mq(md) {
							margin: 0 auto;
						}

						a {
							position: relative;
							color: $basicWhiteColor;
							background: rgba(43,65,98,.25);
							box-shadow: 0 0 0 2px $basicWhiteColor inset;

			 				// 横幅767pxまで
							@include mq(md) {
								width: 236px;
								max-width: 236px;
							}
							&.modan:hover {
								box-shadow: 0 0 0 2px $basicColor inset;

								// 横幅767pxまで
								@include mq(md) {
									box-shadow: 0 0 0 1px $basicColor inset;
								}
							}
							&:before {
								background: $basicColor;
							}
							&:after {
								position: absolute;
								content: '';
								margin: auto 0;
								top: 23px;
								background: url(/images/common/icon_long_btn_arrow_w.png) no-repeat;
								background-size: 58px 7px;
								width: 58px;
								height: 7px;
								right: -11px;
								transition: all .5s ease;
							}
							&:hover:after {
								right: -30px;
								transition-timing-function: cubic-bezier(0.52, 1.64, 0.37, 0.66);
							}
						}
					}
				}
			}
		}

		.underExBox {
			display: none;
			padding: 0 20px;
			margin: 15px 0 0;
			
			p {
				@include typography;
				font-size: 1.5rem;
				font-weight: 700;
				margin: 0 0 32px;
				text-align: right;
				color: #363a42;
				line-height: 2 !important;

				// 横幅767pxまで
				@include mq(md) {
					font-size: 1.4rem;
				}
			}

			// ボタンスタイル
			.btnBox {
				@extend %btnBace;
				margin: 0 auto;
				width: 280px !important;

				a {
					color: #363a42;
					box-shadow: 0 0 0 1px #363a42 inset;
					&.modan:hover {
						box-shadow: 0 0 0 1px $basicColor inset;
						// 横幅767pxまで
						@include mq(md) {
							box-shadow: 0 0 0 1px $basicColor inset;
						}
					}
					&:before {
						background: $basicColor;
					}
					&:after {
						background: url(/images/common/icon_long_btn_arrow_g.png) no-repeat !important;
						background-size: 58px 7px !important;
						width: 58px !important;
						height: 7px !important;
						right: -11px !important;
						top: 23px;
						transition: all .5s ease !important;
					}
					&:hover:after {
						right: -30px !important;
						transition-timing-function: cubic-bezier(0.52, 1.64, 0.37, 0.66) !important;
					}

					// SPのホバー機能をなくす
					&.spNotHover {
						&:before {
							display: none;
						}
						&:hover:after {
							// 横幅767pxまで
							@include mq(md) {
								right: -11px !important;
							}
						}
					}
				}
			}

			// 横幅767pxまで
			@include mq(md) {
				display: block;
			}
		}
	}
}